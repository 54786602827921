import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Spinner, ProgressBar } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import { useSocket } from "../../../hooks/useSocket";

import Grid from "../../../components/Grid";

import { hasNoCountryLimitations } from "../../../utils/functionsUtils";
import Swal from "sweetalert2";

const OrdersWithoutLabel = ({
    activeTab,
    reduxGetClients,
    shippers,
    reduxGetLogisticsOperators,
    logisticsOperators,
    reduxGetBranches,
    branches,
    reduxFetchShipmentsLabelNotGenerated,
    shipmentsLabelNotGenerated,
    reduxGeneratePendingGuides,
    requestingGenerateShippingLabels,
    generateShippingLabelsResult,
    reduxEmptyShippingLabelsGrid,
    reduxResetShippingLabelsBuffer,
}) => {
    const {
        handleSubmit: searchSubmit,
        control: searchControl,
        getValues,
    } = useForm({});

    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const token = window.localStorage.getItem("token");
    const countryUser = userData?.idCountry;
    const socket = useSocket(token, 'shippingLabels');

    const viewAllCountriesData = hasNoCountryLimitations(userData);

    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [search, setSearch] = useState('');
    
    const [progress, setProgress] = useState({});
    const [showProgressBar, setShowProgressBar] = useState(false);

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "4.5vw", overflowY: "auto" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" })
    };

    useEffect(() => {
        if (activeTab !== 'tab2') return;

        reduxGetClients();
        reduxGetLogisticsOperators();
        reduxGetBranches({
            page: 1,
            offset: 1000,
            search: '',
            isActive: true,
        });
        handleSearch();
    }, [activeTab, socket])

    useEffect(() => {
        if(!socket) return;

        socket.on('generate-labels-progress', (data) => {
            setProgress(data);
        })

        return () => {
            socket.off('generate-labels-progress');
        }
    }, [token, socket])

    useEffect(() => {
        if(generateShippingLabelsResult !== null && generateShippingLabelsResult.total > 0 ) {
            Swal.fire({
                title: `${generateShippingLabelsResult.message}`,
                text: `Total de órdenes en proceso: ${generateShippingLabelsResult.total}`,
                icon: "info",
            });
            setShowProgressBar(true);
            reduxEmptyShippingLabelsGrid();
            reduxResetShippingLabelsBuffer();
        }
    }, [generateShippingLabelsResult])

    useEffect(() => {
        if(progress.percentage === 100) {
            handleSearch();
            setProgress({});
            setShowProgressBar(false);
            Swal.fire({
                title: `${progress.message}`,
                text: `${progress.successCount} de ${progress.totalOrders} guías generadas.`,
                icon: "info",
            });
        }
    }, [progress])

    const handleSearch = () => {
        const { shipper, operator, hub } = getValues();

        let filters = {
            page: currentPage,
            offset: offset,
            search: search,
            ...(shipper && { shipper: shipper.value }),
            ...(operator && { operator: operator.value }),
            ...(hub && { hub: hub.value }),
        };

        reduxFetchShipmentsLabelNotGenerated(filters);
    }

    const handleGenerateLabels = () => {
        const { shipper, operator, hub } = getValues();
        let filters = {
            search: search,
            ...(shipper && { shipper: shipper.value }),
            ...(operator && { operator: operator.value }),
            ...(hub && { hub: hub.value }),
        };

        reduxGeneratePendingGuides(filters);
    }

    const columns = [
        {
            title: '# Dirección',
            render: (rowData) => {
                return <span>{rowData.idAddress}</span>;
            },
            field: 'TMA.idAddress',
            searchable: true,
        },
        {
            title: 'Id Externo',
            render: (rowData) => {
                return <span>{rowData.externalId || ''}</span>;
            },
            field: 'TMA.externalId',
            searchable: true,
        },
        {
            title: 'Operador',
            render: (rowData) => {
                return <span>{rowData.operator.description}</span>;
            },
        },
        {
            title: 'Hub',
            render: (rowData) => {
                return <span>{rowData.hub.branchName}</span>;
            },
        },
        {
            title: 'Remitente',
            render: (rowData) => {
                return <span>{rowData.company.description}</span>;
            },
        },
        {
            title: 'Dirección Destinatario',
            render: (rowData) => {
                return <span>{rowData.address}</span>;
            },
            field: 'TMA.address',
            searchable: true,
        },
        {
            title: 'Destinatario',
            render: (rowData) => {
                return (
                    <span>
                        {rowData.firstName} {rowData.lastName}
                    </span>
                );
            },
        },
        {
            title: 'Departamento',
            render: (rowData) => {
                return <span>{rowData.city && rowData.city.department ? rowData.city.department.description : ''}</span>;
            },
            field: 'TMD.description',
            searchable: true,
        },
        {
            title: 'Ciudad',
            render: (rowData) => {
                return <span>{rowData.city ? rowData.city.description : ''}</span>;
            },
            field: 'TMC.description',
            searchable: true,
        }
    ];

    return (
        <div className="card shadow mb-4">
            <div className="card-body">
                <form onSubmit={searchSubmit(handleSearch)}>
                    <div className="d-flex">
                        <div className='form-group col-3 pl-0'>
                            <label
                                htmlFor='shipper'
                                className='form-label'>
                                Remitente
                            </label>
                            <Controller
                                control={searchControl}
                                name="shipper"
                                render={({ field }) => (
                                    <>
                                        <Select
                                            {...field}
                                            isClearable
                                            styles={customStyles}
                                            options={
                                                shippers && Object.keys(shippers).length > 0
                                                    ? shippers.items
                                                        .filter(e => e.isActive === true && (viewAllCountriesData || e.country == countryUser && countryUser))
                                                        .map((ele, key) => ({
                                                            value: ele.idCompany,
                                                            label: ele.description,
                                                        }))
                                                    : []
                                            }
                                        />
                                    </>
                                )}
                            />
                        </div>  
                        <div className='form-group col-3'>
                            <label
                                htmlFor='idBranch'
                                className='form-label'>
                                Operador
                            </label>
                            <Controller
                                control={searchControl}
                                name='operator'
                                render={({ field }) => (
                                    <>
                                        <Select
                                            {...field}
                                            isClearable
                                            styles={customStyles}
                                            options={
                                                logisticsOperators && Object.keys(logisticsOperators).length > 0
                                                    ? logisticsOperators.items
                                                        .filter(e => e.isActive === true && (viewAllCountriesData || e.country == countryUser && countryUser))
                                                        .map((ele, key) => ({
                                                            value: ele.idCompany,
                                                            label: ele.description,
                                                        }))
                                                    : []
                                            }
                                        />
                                    </>
                                )}
                            />
                        </div>
                        <div className='form-group col-3'>
                            <label
                                htmlFor='hub'
                                className='form-label'>
                                HUB
                            </label>
                            <Controller
                                control={searchControl}
                                name='hub'
                                render={({ field }) => (
                                    <>
                                        <Select
                                            {...field}
                                            isClearable
                                            styles={customStyles}
                                            options={
                                                branches &&
                                                    Object.keys(branches).length > 0
                                                    ? branches.items
                                                        .filter((f) => f.isActive == true && (viewAllCountriesData || f.idCountry == countryUser && countryUser))
                                                        .map((ele) => ({
                                                            value: ele.idBranch,
                                                            label: ele.branchName,
                                                        }))
                                                    : []
                                            }
                                        />
                                    </>
                                )}
                            />
                        </div>
                        <div className='form-group col-auto align-content-end'>
                            <Button
                                variant='primary'
                                type='submit'
                                id='btnSearch'>
                                Buscar
                            </Button>
                        </div>
                        {
                            shipmentsLabelNotGenerated && shipmentsLabelNotGenerated.total > 0 &&
                            (
                                <div className='form-group col-auto align-content-end'>
                                    <Button
                                        onClick={() => { handleGenerateLabels() }}
                                        variant='secondary'
                                        type='button'
                                        >
                                        {
                                            requestingGenerateShippingLabels ? (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <>
                                                    Generar Guías
                                                </>
                                            )
                                        }
                                    </Button>
                                </div>
                            )
                        }
                    </div>
                </form>
                {
                    showProgressBar &&
                    (
                        <ProgressBar animated={true} min={0} now={progress?.percentage} size={'sm'} className='mb-2' title='Generación de etiquetas en progreso' />
                    )
                }
                <Grid
                    cols={columns}
                    data={
                        shipmentsLabelNotGenerated && Object.keys(shipmentsLabelNotGenerated).length > 0
                            ? shipmentsLabelNotGenerated.items
                            : []
                    }
                    page={
                        shipmentsLabelNotGenerated && Object.keys(shipmentsLabelNotGenerated).length > 0
                            ? Number(shipmentsLabelNotGenerated.page)
                            : 1
                    }
                    pages={
                        shipmentsLabelNotGenerated && Object.keys(shipmentsLabelNotGenerated).length > 0
                            ? Number(shipmentsLabelNotGenerated.totalPages)
                            : 0
                    }
                    total={
                        shipmentsLabelNotGenerated && shipmentsLabelNotGenerated.hasOwnProperty("total") ? shipmentsLabelNotGenerated.total : 0
                    }
                    offset={offset}
                    defaultValue={search}
                    onChangePage={(page) => {
                        setCurrentPage(page);
                        const { shipper, operator, hub } = getValues();

                        let filters = {
                            page: page,
                            offset: offset,
                            search: search,
                            ...(shipper && { shipper: shipper.value }),
                            ...(operator && { operator: operator.value }),
                            ...(hub && { hub: hub.value }),
                        };

                        reduxFetchShipmentsLabelNotGenerated(filters);
                    }}
                    onChangeRange={(value) => {
                        setOffset(value);
                        const { shipper, operator, hub } = getValues();

                        let filters = {
                            page: 1,
                            offset: value,
                            search: search,
                            ...(shipper && { shipper: shipper.value }),
                            ...(operator && { operator: operator.value }),
                            ...(hub && { hub: hub.value }),
                        };

                        reduxFetchShipmentsLabelNotGenerated(filters);
                    }}
                    onChangeSearch={(value) => {
                        setSearch(value);
                        const { shipper, operator, hub } = getValues();

                        let filters = {
                            page: 1,
                            offset: offset,
                            search: value,
                            ...(shipper && { shipper: shipper.value }),
                            ...(operator && { operator: operator.value }),
                            ...(hub && { hub: hub.value }),
                        };

                        reduxFetchShipmentsLabelNotGenerated(filters);
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        shippers: state.companyState.clients,
        logisticsOperators: state.companyState.logisticsOperators,
        branches: state.branchState.branches,
        shipmentsLabelNotGenerated: state.addressState.shipmentsLabelNotGenerated,
        requestingGenerateShippingLabels: state.addressState.requestingGenerateShippingLabels,
        generateShippingLabelsResult: state.addressState.generateShippingLabelsResult,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetClients: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
                value: payload,
            }),
        reduxGetLogisticsOperators: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST',
                value: payload,
            }),
        reduxGetBranches: (payload) =>
            dispatch({
                type: 'FETCH_BRANCHES_REQUEST',
                value: payload,
            }),
        reduxFetchShipmentsLabelNotGenerated: (payload) =>
            dispatch({
                type: 'FETCH_SHIPMENTS_LABEL_NO_GENERATED_REQUEST',
                value: payload,
            }),
        reduxGeneratePendingGuides: (payload) =>
            dispatch({
                type: 'GENERATE_SHIPPING_LABELS_REQUEST',
                value: payload,
            }),
        reduxEmptyShippingLabelsGrid: () =>
            dispatch({
                type: 'EMPTY_GENERATE_SHIPPING_LABELS_GRID',
            }),
        reduxResetShippingLabelsBuffer: () =>
            dispatch({
                type: 'RESET_GENERATE_SHIPPING_LABELS_BUFFER',
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersWithoutLabel);