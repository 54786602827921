import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../api/request";
import { assignOperatorActions } from '../reducers/assignOperatorReducer';

function* uploadMassiveAssignmentFile(payload) {
    try {
        yield put({
            type: assignOperatorActions.UPLOAD_MASSIVE_ASSIGNMENT_FILE_PROCESSING,
        });

        yield put({
            type: "SHOW_LOADING",
        });

        const requestURL = `${process.env.REACT_APP_ASSIGN_URL}/api/v1/assignOperator/uploadMassiveAssignment`;

        const formData = new FormData();
        formData.append("file", payload.value.file);
        formData.append("idOperator", payload.value.operator);
        formData.append("idHub", payload.value.hub);

        const headers = {
            method: "POST",
            headers: new Headers({
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            }),
            body: formData,
          };

        const response = yield call(request, requestURL, headers);

        yield put({
            type: assignOperatorActions.UPLOAD_MASSIVE_ASSIGNMENT_FILE_SUCCESS,
            value: response,
        });

        yield put({
            type: "HIDE_LOADING",
        });

    } catch (error) {
        console.log(error);
        yield put({
            type: assignOperatorActions.UPLOAD_MASSIVE_ASSIGNMENT_FILE_ERROR,
        });

        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Upload Failed",
                message: "Upload Failed",
            },
        });
    }
}

export function* watchAssignOperator() {
    yield takeLatest("UPLOAD_MASSIVE_ASSIGNMENT_FILE", uploadMassiveAssignmentFile);
}
