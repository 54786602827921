import React, { forwardRef, useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment-timezone';
import { Button, OverlayTrigger, Tooltip, Modal, Tab, Tabs } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime, IANAZone } from "luxon";

import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import Grid from '../../components/Grid';

import formatDateWithCustomFormat, { FormatsDate } from '../../utils/formatDate';
import { isNullOrUndefined, exportCSV } from '../../utils/functionsUtils';

import { PermissionContext } from '../../hooks/PermissionContext';
import { RecentsAssignaments } from './recentsAssignaments';
import { CompanyTypesEnum } from '../../utils/enumsUtil';
import GroupedSelect from '../../components/GroupedSelect';
import OrdersWithoutLabel from './OrdersWithoutLabel';

const Swal = require('sweetalert2');

const AssignOperator = ({
  reduxGetAddressesWithoutOperator,
  addressesWithoutOperator,
  reduxGetClients,
  clients,
  reduxResetAddressOperatorForm,
  reduxGetBranches,
  branches,
  reduxUploadMassiveAssignmentFile,
  successUploadMassiveAssignment,
  massiveAssignmentResult,
  reduxFetchHubOperators,
  hubOperators,
  reduxResetHubOperators,
}) => {

  const { hasPermission } = useContext(PermissionContext);

  const {
    reset,
  } = useForm();

  const {
    control: controlMassiveAssigment,
    handleSubmit: handleSubmitMassiveAssignment,
    setValue: setValueMassiveAssignment,
    formState: { errors: errorsMassiveAssignmentModal },
    watch: watchMassiveAssignmentField,
    reset: resetMassiveAssignmentModal,
  } = useForm({
    defaultValues: {
      hub: null,
      operator: null,
      file: null,
    },
  });

  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    reset: resetSearch,
    setValue: setValueSearch,
    getValues: getValuesSearch,
  } = useForm({
    defaultValues: {
      from: null,
      to: null,
    },
  });
  const {
    setValue: setValueAssign,
  } = useForm();

  const fileName = watchMassiveAssignmentField('file', '');
  const hubWatcher = watchMassiveAssignmentField('hub', null);
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const countryUser = userData?.idCountry;

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState('');
  const [startDateFrom, setStartDateFrom] = useState(null);
  const [startDateTo, setStartDateTo] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [showAssignmentModal, setShowAssignmentModal] = useState(false);
  const [activeTab, setActiveTab] = useState('tab1');
  const [renderParentListProcessFlow, setRenderParentListProcessFlow] = useState(false);
  const [hubOperatorsOptions, setHubOperatorsOptions] = useState([]);

  const customStyles = {
    menu: (base) => ({ ...base, zIndex: 9999 }),
    valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto", }),
    control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" }) // Ajusta el valor según tus necesidades
  };

  const DatePickerCustomFrom = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      className='form-control'
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      autoComplete='Off'
    />
  ));

  useEffect(() => {
    reduxGetClients();
    reduxGetAddressesWithoutOperator({
      page: 1,
      offset: offset,
      search: '',
    });
    reduxResetAddressOperatorForm();
    reset();
    resetSearch();
    reduxGetBranches({
      page: 1,
      offset: 1000,
      search: '',
      isActive: true,
    });
    successUploadMassiveAssignment = false;
    massiveAssignmentResult = null;
  }, []);

  useEffect(() => {
    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
    };
    if (startDateFrom && startDateTo) {
      filters = {
        ...filters,
        from: formatDateWithCustomFormat(startDateFrom, { format: FormatsDate.FULL24 }),
        to: formatDateWithCustomFormat(startDateTo, { format: 'YYYY-MM-DD 23:59:59' }),
      };
    }

    if (deliveryDate) {
      filters = {
        ...filters,
        deliveryDate: moment(deliveryDate).tz('America/Bogota').format('YYYY-MM-DD'),
      };
    }

    if (getValuesSearch('idClient') !== '' && getValuesSearch('idClient') !== undefined) {
      filters = {
        ...filters,
        idClient: getValuesSearch('idClient'),
      };
    }
    reduxGetAddressesWithoutOperator(filters);
  }, [currentPage]);

  useEffect(() => {
    if (successUploadMassiveAssignment) {
      if (massiveAssignmentResult.failuredAssignments.length > 0) {
        const fileName = DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd') + '-failuredAssignments.csv';
        exportCSV(massiveAssignmentResult.failuredAssignments, fileName);
      }

      Swal.fire(
        'Proceso exitoso!',
        `${massiveAssignmentResult.totalAssigned} de ${massiveAssignmentResult.total} Id Dirección asignados exitosamente.`,
        'success'
      );

      Swal.fire({
        title: massiveAssignmentResult?.inBackground ? 'Proceso exitoso y continuará en segundo plano' : 'Proceso exitoso',
        text: `${massiveAssignmentResult.totalAssigned} de ${massiveAssignmentResult.total} Id Dirección asignados exitosamente. ${(massiveAssignmentResult?.inBackground && 'Validar en la pestaña Estado de Asignaciones Pendientes para ver su progreso.') ?? ''}`,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok"
      }).then((result) => {
        if (result.isConfirmed) {
          if (massiveAssignmentResult.inBackground) {
            setActiveTab('tab3')
            setRenderParentListProcessFlow(true);
          }
        }
      });

      reduxGetAddressesWithoutOperator({
        page: 1,
        offset: offset,
        search: '',
      });
      reset();
      resetSearch();
      setValueAssign('idOperator', null);
    }
  }, [successUploadMassiveAssignment]);

  useEffect(() => {
    setValueMassiveAssignment("operator", null);
    if (isNullOrUndefined(hubWatcher)) return;

    reduxFetchHubOperators({
      idHub: hubWatcher.value
    });
  }, [hubWatcher])

  useEffect(() => {
    setHubOperatorsOptions(null);

    if (hubOperators.length === 0) return;
    const groupedOptions = [];

    if (hubOperators.some(e => e.companyType === CompanyTypesEnum.DSP))
      groupedOptions.push({
        label: "DSP",
        options: hubOperators.filter(e => e.companyType === CompanyTypesEnum.DSP)
          .map(e => ({ label: e.businessName, value: e.idCompany }))
      });

    groupedOptions.push({
      label: "Operador Tradicional",
      options: hubOperators.filter(e => e.companyType === CompanyTypesEnum.LOGISTICS_OPERATOR)
        .map(e => ({ label: e.businessName, value: e.idCompany }))
    });

    setHubOperatorsOptions(groupedOptions);
  }, [hubOperators])

  const DatePickerCustomTo = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      className='form-control'
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      autoComplete='Off'
    />
  ));

  const DatePickerCustomDeliveryDate = forwardRef(({ value, onClick, onChange, readOnly }, ref) => (
    <input
      disabled={readOnly}
      className='form-control'
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      autoComplete='Off'
    />
  ));

  const onSubmitSearch = (data) => {
    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
    };
    if (startDateFrom && startDateTo) {
      if (startDateTo < startDateFrom) {
        Swal.fire('Información', 'La fecha inicial debe ser  menor a la fecha final , intenta de nuevo', 'info');
        return;
      }
      filters = {
        ...filters,
        from: formatDateWithCustomFormat(startDateFrom, { format: FormatsDate.FULL24 }),
        to: formatDateWithCustomFormat(startDateTo, { format: 'YYYY-MM-DD 23:59:59' }),
      };
    }
    if (deliveryDate) {
      filters = {
        ...filters,
        deliveryDate: moment(deliveryDate).tz('America/Bogota').format('YYYY-MM-DD'),
      };
    }
    if (data.idClient != '' && data.idClient) {
      filters = {
        ...filters,
        idClient: data.idClient,
      };
    }
    if (data.idDepartment != '' && data.idDepartment != undefined) {
      filters = {
        ...filters,
        idDepartment: data.idDepartment,
      };
    }
    reduxGetAddressesWithoutOperator(filters);
  };

  const handledChangeFrom = (date) => {
    setStartDateFrom(date);
    setValueSearch('from', moment(date).tz('America/Bogota').format('MM-DD-YYYY'));
  };

  const handledChangeTo = (date) => {
    setStartDateTo(date);
    setValueSearch('to', moment(date).tz('America/Bogota').format('MM-DD-YYYY'));
  };

  const handledDeliveryDate = (date) => {
    setDeliveryDate(date);
    setValueSearch('deliveryDate', moment(date).tz('America/Bogota').format('MM-DD-YYYY'));
  };

  const requestFailuredAssignments = () => {
    const token = window.localStorage.getItem('token');

    const fileName = DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd') + '-failuredAssignments.csv';

    const body = {
      fileName,
      data: massiveAssignmentResult.failuredAssignments,
    }

    fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/download/csv`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const renderTooltip = (title) => <Tooltip id='button-tooltip'>{title}</Tooltip>;

  const handleShowMassiveAssignmentModal = () => {
    setShowAssignmentModal(true);
  };

  const handleCloseMassiveAssignmentModal = () => {
    resetMassiveAssignmentModal();
    reduxResetHubOperators();
    setShowAssignmentModal(false);
  };

  const onSubmitMassiveAssignment = (data) => {
    if (data.file && data.file.type !== 'text/csv')
      return Swal.fire(
        'Archivo errado',
        'El archivo no cumple con el formato establecido <br/> Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas\'',
        'error');

    const reader = new FileReader();
    reader.readAsText(data.file);

    reader.onload = () => {
      const csvData = reader.result;

      const haveMoreThanOneColumn = csvData.split('\n')[0].split(';').length > 0;

      if (!haveMoreThanOneColumn)
        return Swal.fire(
          'Archivo errado',
          'Señor usuario el archivo debe tener solamente una columna con la informacion del ID Address',
          'error');

      reduxUploadMassiveAssignmentFile({
        hub: data.hub.value,
        operator: data.operator.value,
        file: data.file,
      });

      resetMassiveAssignmentModal({
        hub: null,
        operator: null,
        file: null,
      });

      setShowAssignmentModal(false);
    }
  };

  const columns = [
    {
      title: '# Dirección',
      render: (rowData) => {
        return <span>{rowData.idAddress}</span>;
      },
      field: 'idAddress',
      searchable: true,
    },
    {
      title: 'Id Externo',
      render: (rowData) => {
        return <span>{rowData.order ? rowData.order.externalId : rowData.externalId ? rowData.externalId : ''}</span>;
      },
      field: 'externalId',
      searchable: true,
    },
    {
      title: 'Remitente',
      render: (rowData) => {
        return <span>{rowData.company.description}</span>;
      },
      field: 'company.description',
      searchable: false,
    },
    {
      title: 'Dirección Destinatario',
      render: (rowData) => {
        return <span>{rowData.address}</span>;
      },
      field: 'address',
      searchable: true,
      visible: hasPermission('feature:show-recipient-address')
    },
    {
      title: 'Destinatario',
      render: (rowData) => {
        return (
          <span>
            {rowData.firstName} {rowData.lastName}
          </span>
        );
      },
      field: 'firstName-lastName',
      searchable: true,
    },
    {
      title: 'Departamento',
      render: (rowData) => {
        return <span>{rowData.city && rowData.city.department ? rowData.city.department.description : ''}</span>;
      },
      field: 'department.description',
      searchable: true,
    },
    {
      title: 'Ciudad',
      render: (rowData) => {
        return <span>{rowData.city ? rowData.city.description : ''}</span>;
      },
      field: 'city.description',
      searchable: true,
    },
    {
      title: 'Fecha de creación',
      render: (rowData) => {
        return <span>{moment(rowData.createdAt).tz('America/Bogota').format('DD-MM-YYYY')}</span>;
      },
    },
    {
      title: 'Fecha de Entrega',
      render: (rowData) => {
        return (
          <span>
            {rowData.deliveryDate != null
              ? moment(rowData.deliveryDate.toString()).tz('America/Bogota').format('DD/MM/YYYY')
              : ''}
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <div id='wrapper'>
        <Sidebar />
        <div
          id='content-wrapper'
          className='d-flex flex-column'>
          <div id='content'>
            <Topbar />
            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center mb-1'>
                <h1 className='h3 mb-0 text-gray-800 mr-3'>Asignar operador</h1>
                <div>
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 50, hide: 50 }}
                    overlay={renderTooltip('Asignación masiva')}>
                    <button
                      className='btn btn-primary btn-circle'
                      type='button'
                      onClick={handleShowMassiveAssignmentModal}>
                      <i className='fas fa-upload fa-sm'></i>
                    </button>
                  </OverlayTrigger>
                </div>
              </div>
              <p className='mb-4'>Módulo para asignar direcciones a un operador específico</p>
              <Tabs
                id="controlled-tab-example"
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
              >
                <Tab eventKey="tab1" title="Listado de órdenes">
                  <div className='card shadow'>
                    <div className='card-body'>
                      <form onSubmit={handleSubmitSearch(onSubmitSearch)}>
                        <div className='d-flex flex-gap-1'>
                          <div className='form-group'>
                            <label
                              htmlFor='from'
                              className='form-label'>
                              Desde
                            </label>
                            <DatePicker
                              selected={startDateFrom}
                              onChange={(date) => handledChangeFrom(date)}
                              dateFormat='MM-dd-yyyy'
                              customInput={<DatePickerCustomFrom />}
                              maxDate={new Date()}
                            />
                          </div>
                            <div className='form-group'>
                              <label
                                htmlFor='to'
                                className='form-label'>
                                Hasta
                              </label>
                              <div>
                                <DatePicker
                                  selected={startDateTo}
                                  onChange={(date) => handledChangeTo(date)}
                                  dateFormat='MM-dd-yyyy'
                                  customInput={<DatePickerCustomTo />}
                                  maxDate={new Date()}
                                />
                              </div>
                            </div>
                          <div className='form-group w-25'>
                            <label
                              htmlFor='idClient'
                              className='form-label'>
                              Remitente
                            </label>
                            <Select
                              isClearable
                              isDisabled={!startDateFrom || !startDateTo}
                              {...registerSearch('idClient')}
                              id='idClient'
                              onChange={(value) => setValueSearch('idClient', value?.value || '')}
                              options={
                                clients && Object.keys(clients).length > 0
                                  ? clients.items
                                    .filter((f) => f.isActive)
                                    .map((e, key) => ({
                                      value: e.idCompany,
                                      label: e.description,
                                    }))
                                  : []
                              }
                            />
                          </div>
                            <div className='form-group'>
                              <label
                                htmlFor='to'
                                className='form-label'>
                                Fecha de entrega
                              </label>
                              <div>
                                <DatePicker
                                  readOnly={!startDateFrom || !startDateTo}
                                  selected={deliveryDate}
                                  onChange={(date) => handledDeliveryDate(date)}
                                  dateFormat='MM-dd-yyyy'
                                  customInput={<DatePickerCustomDeliveryDate />}
                                />
                              </div>
                          </div>
                          <div className='form-group align-content-end'>
                            <Button
                              variant='primary'
                              type='submit'
                              id='btnSearch'>
                              Buscar
                            </Button>
                          </div>
                        </div>
                      </form>
                      <Grid
                        cols={columns}
                        data={
                          addressesWithoutOperator && Object.keys(addressesWithoutOperator).length > 0
                            ? addressesWithoutOperator.items
                            : []
                        }
                        page={
                          addressesWithoutOperator && Object.keys(addressesWithoutOperator).length > 0
                            ? Number(addressesWithoutOperator.page)
                            : currentPage
                        }
                        total={
                          addressesWithoutOperator && addressesWithoutOperator.hasOwnProperty('total')
                            ? addressesWithoutOperator.total
                            : 0
                        }
                        pages={
                          addressesWithoutOperator && Object.keys(addressesWithoutOperator).length > 0
                            ? Number(addressesWithoutOperator.totalPages)
                            : 1
                        }
                        offset={offset}
                        onChangePage={(page) => setCurrentPage(page)}
                        onChangeRange={(value) => {
                          setOffset(value);
                          let filters = {
                            page: 1,
                            offset: value,
                            search: search,
                          };
                          if (startDateFrom && startDateTo) {
                            if (startDateTo < startDateFrom) {
                              Swal.fire(
                                'Información',
                                'La fecha inicial debe ser  menor a la fecha final , intenta de nuevo',
                                'info'
                              );
                              return;
                            }
                            filters = {
                              ...filters,
                              from: formatDateWithCustomFormat(startDateFrom, { format: FormatsDate.FULL24 }),
                              to: formatDateWithCustomFormat(startDateTo, { format: 'YYYY-MM-DD 23:59:59' }),
                            };
                          }
                          if (deliveryDate) {
                            filters = {
                              ...filters,
                              deliveryDate: moment(deliveryDate).tz('America/Bogota').format('YYYY-MM-DD'),
                            };
                          }
                          if (getValuesSearch('idClient') != '' && getValuesSearch('idClient') != undefined) {
                            filters = {
                              ...filters,
                              idClient: getValuesSearch('idClient'),
                            };
                          }
                          reduxGetAddressesWithoutOperator(filters);
                        }}
                        defaultValue={search}
                        onChangeSearch={(value) => {
                          setSearch(value);
                          let filters = {
                            page: 1,
                            offset: offset,
                            search: value,
                          };
                          if (startDateFrom && startDateTo) {
                            if (startDateTo < startDateFrom) {
                              Swal.fire(
                                'Información',
                                'La fecha inicial debe ser  menor a la fecha final , intenta de nuevo',
                                'info'
                              );
                              return;
                            }
                            filters = {
                              ...filters,
                              from: formatDateWithCustomFormat(startDateFrom, { format: FormatsDate.FULL24 }),
                              to: formatDateWithCustomFormat(startDateTo, { format: 'YYYY-MM-DD 23:59:59' }),
                            };
                          }
                          if (deliveryDate) {
                            filters = {
                              ...filters,
                              deliveryDate: moment(deliveryDate).tz('America/Bogota').format('YYYY-MM-DD'),
                            };
                          }
                          if (getValuesSearch('idClient') != '' && getValuesSearch('idClient') != undefined) {
                            filters = {
                              ...filters,
                              idClient: getValuesSearch('idClient'),
                            };
                          }
                          reduxGetAddressesWithoutOperator(filters);
                        }}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="tab2" title="Órdenes sin guía">
                  <OrdersWithoutLabel activeTab={activeTab} />
                </Tab>
                <Tab eventKey="tab3" title="Estado de Asignaciones Recientes" className='py-3'>
                  <RecentsAssignaments activeTab={activeTab} setRenderParentListProcessFlow={setRenderParentListProcessFlow} renderParentListProcessFlow={renderParentListProcessFlow} />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={showAssignmentModal}
          onHide={handleCloseMassiveAssignmentModal}
          onEscapeKeyDown={handleCloseMassiveAssignmentModal}
          backdrop='static'
          keyboard={true}>
          <form onSubmit={handleSubmitMassiveAssignment(onSubmitMassiveAssignment)}>
            <Modal.Header closeButton>
              <Modal.Title>Cargar archivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='row'>
                <div className='form-group col-md-12'>
                  <label
                    htmlFor='idBranch'
                    className='form-label'>
                    HUB
                  </label>
                  <Controller
                    control={controlMassiveAssigment}
                    name='hub'
                    rules={{
                      required: {
                        value: true,
                        message: 'Debe seleccionar un HUB válido.'
                      }
                    }}
                    render={({ field }) => (
                      <>
                        <Select
                          {...field}
                          isClearable
                          styles={customStyles}
                          options={
                            branches &&
                              Object.keys(branches).length > 0
                              ? branches.items
                                .filter((f) => f.isActive == true)
                                .map((ele) => ({
                                  value: ele.idBranch,
                                  label: ele.branchName,
                                }))
                              : []
                          }
                        />
                        {
                          errorsMassiveAssignmentModal.hub &&
                          <span className='error-message'>{errorsMassiveAssignmentModal.hub.message}</span>
                        }
                      </>
                    )}
                  />
                </div>
                <div className='form-group col-md-12'>
                  <label
                    htmlFor='idBranch'
                    className='form-label'>
                    Operador
                  </label>
                  <Controller
                    control={controlMassiveAssigment}
                    name='operator'
                    rules={{
                      required: {
                        value: true,
                        message: 'Debe seleccionar un operador valido.'
                      }
                    }}
                    render={({ field }) => (
                      <>
                        <GroupedSelect
                          groupedOptions={hubOperatorsOptions}
                          {...field}
                          styles={customStyles}
                          isDisabled={isNullOrUndefined(hubWatcher)}
                          isClearable
                        />
                        {
                          errorsMassiveAssignmentModal.operator &&
                          <span className='error-message'>{errorsMassiveAssignmentModal.operator.message}</span>
                        }
                      </>
                    )}
                  />
                </div>
                <div className='form-group col-md-12'>
                  <label htmlFor="file" className="form-label">
                    Archivo
                  </label>
                  <Controller
                    control={controlMassiveAssigment}
                    name='file'
                    rules={{
                      required: {
                        value: true,
                        message: 'Debe seleccionar un archivo.'
                      },
                      pattern: {
                        value: /.*\.csv/,
                        message: 'Debe ser un archivo csv'
                      }
                    }}
                    render={({ field }) => (
                      <>
                        <div className="custom-file">
                          <input
                            type='file'
                            accept='.csv'
                            onChange={(e) => {
                              const file = e.target.files[0];
                              field.onChange(file);
                            }}
                            className={`custom-file-input ${errorsMassiveAssignmentModal.file && 'is-invalid'}`}
                          />
                          <label
                            className='custom-file-label'
                            htmlFor='validatedCustomFile'>
                            {!isNullOrUndefined(fileName)
                              ? fileName.name
                              : 'Seleccionar archivo...'}
                          </label>
                          {
                            errorsMassiveAssignmentModal.file &&
                            <span className='error-message'>{errorsMassiveAssignmentModal.file.message}</span>
                          }
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={handleCloseMassiveAssignmentModal}>
                Cancelar
              </Button>
              <Button
                variant='primary'
                type='submit'>
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addressesWithoutOperator: state.addressState.addressesWithoutOperator,
    clients: state.companyState.clients,
    branches: state.branchState.branches,
    hubOperators: state.branchState.hubOperators,
    successUploadMassiveAssignment: state.assignOperatorState.successUploadMassiveAssignment,
    massiveAssignmentResult: state.assignOperatorState.massiveAssignmentResult,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetAddressesWithoutOperator: (payload) =>
      dispatch({
        type: 'FETCH_ADDRESSES_WITHOUT_OPERATOR_REQUEST',
        value: payload,
      }),
    reduxGetClients: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
        value: payload,
      }),
    reduxResetAddressOperatorForm: () =>
      dispatch({
        type: 'RESET_ADDRESS_FORM',
      }),
    reduxGetBranches: (payload) =>
      dispatch({
        type: 'FETCH_BRANCHES_REQUEST',
        value: payload,
      }),
    reduxUploadMassiveAssignmentFile: (payload) =>
      dispatch({
        type: 'UPLOAD_MASSIVE_ASSIGNMENT_FILE',
        value: payload,
      }),
    reduxFetchHubOperators: (payload) =>
      dispatch({
        type: 'FETCH_HUB_OPERATORS_REQUEST',
        value: payload,
      }),
    reduxResetHubOperators: () =>
      dispatch({
        type: 'RESET_HUB_OPERATORS',
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignOperator);
