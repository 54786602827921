import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Button, Spinner } from "react-bootstrap";

import GeoPermissionAlert from "../../components/GeoPermissionAlert";

const SignIn = ({
  reduxPostLogin,
  successfulLogIn,
  reduxResetLoginReducer
}) => {
  const history = useHistory();
  const [showGeoPermissionModal, setShowGeoPermissionModal] = useState(false);
  const [logInRequesting, setLogInRequesting] = useState(false);

  useEffect(() => {
    document.getElementById("body").className = "bg-default";
  }, []);
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleCloseModal = () => {
    setShowGeoPermissionModal(false);
  }

  const onSubmit = (data) => {
    if (navigator.geolocation) {
      setLogInRequesting(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;

          reduxPostLogin({ ...data, latitude: lat, longitude: lon });
          setLogInRequesting(false);
        },
        (_error) => {
          setShowGeoPermissionModal(true);
          setLogInRequesting(false);
        }
      )
        ;
    } else {
      // Geolocalización no soportada
      setShowGeoPermissionModal(true);
    }
  }

  useEffect(() => {
    if (successfulLogIn) {
      reset();
      reduxResetLoginReducer();
      history.push("/welcome");
    }
  }, [successfulLogIn]);

  return (
    <>
      <div className="container vh-100">
        <div className="row vh-100 justify-content-center align-items-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">
                          ¡Bienvenido de nuevo!
                        </h1>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                          <input
                            type="text"
                            className={`form-control form-control-user ${errors.username && "is-invalid"
                              }`}
                            placeholder="Correo electrónico"
                            {...register("username", { required: true })}
                          />
                          {errors.username && (
                            <span className="invalid-feedback">
                              El correo electrónico es requerido
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            placeholder="Contraseña"
                            className={`form-control form-control-user ${errors.password && "is-invalid"
                              }`}
                            {...register("password", { required: true })}
                          />
                          {errors.password && (
                            <span className="invalid-feedback">
                              La contraseña es requerida
                            </span>
                          )}
                        </div>
                        <Button
                          className='btn-user btn-block'
                          variant='primary'
                          type='submit'
                          disabled={logInRequesting}
                        >
                          {
                            logInRequesting ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              <>
                                Iniciar sesión
                              </>
                            )
                          }
                        </Button>
                      </form>
                      <hr />
                      <GeoPermissionAlert
                        handleCloseModal={handleCloseModal}
                        showGeoPermissionModal={showGeoPermissionModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    requestingLogIn: state.authState.requestingLogIn,
    successfulLogIn: state.authState.successfulLogIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxPostLogin: (payload) =>
      dispatch({
        type: "LOGIN_REQUEST",
        value: payload,
      }),
    reduxResetLoginReducer: () =>
      dispatch({
        type: "LOGIN_RESET",
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
