import React, { useState, useEffect, createContext } from "react";
import { definePermission as define } from "../redux/actions/actions";

const permissionsSelector = (state) => state.authState?.permissions ?? [];

const initialContext = {
    permissions: [],
    hasPermission: () => false,
    definePermission: () => {},
};

export const PermissionContext = createContext(initialContext);

export const PermissionProvider = ({ store, children }) => {
    const { dispatch, getState, subscribe } = store;
    const [permissions, setPermissions] = useState(() => permissionsSelector(getState()));

    useEffect(() => {
        const unsubscribe = subscribe(() => {
            const newPermissions = permissionsSelector(getState());
            setPermissions((prev) => (prev !== newPermissions ? newPermissions : prev));
        });
        return unsubscribe;
    }, [store]);

    const hasPermission = (allowedPermissions) => {
        if (!permissions.length) return false;

        if (typeof allowedPermissions === "string") {
            return permissions.includes(allowedPermissions);
        }

        return allowedPermissions.every((perm) => permissions.includes(perm));
    };

    const definePermission = (definePermissions) =>
        dispatch(define(definePermissions));

    return (
        <PermissionContext.Provider
            value={{ permissions, hasPermission, definePermission }}
        >
            {children}
        </PermissionContext.Provider>
    );
};
