import { call, put, takeLatest } from 'redux-saga/effects';
import request from '../../api/request';

function* fetchInvestData(action) {
  const { payload } = action;
  try {
    yield put({ type: 'SHOW_LOADING' });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/security/investData` +
      `?page=${payload.page}` +
      `&offset=${payload.offset}` +
      `${payload.search ? `&search=${encodeURIComponent(payload.search)}` : ''}` +
      `${payload.from ? `&from=${encodeURIComponent(payload.from)}` : ''}` +
      `${payload.to ? `&to=${encodeURIComponent(payload.to)}` : ''}` +
      `${payload.senderIds ? `&senderIds=${encodeURIComponent(payload.senderIds)}` : ''}` +
      `${payload.operatorIds ? `&operatorIds=${encodeURIComponent(payload.operatorIds)}` : ''}` +
      `${payload.idBranch ? `&idBranch=${encodeURIComponent(payload.idBranch)}` : ''}` +
      `${payload.idCountry ? `&idCountry=${encodeURIComponent(payload.idCountry)}` : ''}` +
      `${payload.idDepartment ? `&idDepartment=${encodeURIComponent(payload.idDepartment)}` : ''}` +
      `${payload.idCity ? `&idCity=${encodeURIComponent(payload.idCity)}` : ''}` +
      `${payload.state ? `&state=${encodeURIComponent(payload.state)}` : ''}` +
      `${payload.returnTrackingId ? `&returnTrackingId=${encodeURIComponent(payload.returnTrackingId)}` : ''}`;

    const headers = {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }),
    };

    const response = yield call(request, requestURL, headers);

    yield put({ type: 'HIDE_LOADING' });
    yield put({ type: 'FETCH_INVEST_DATA_SUCCESS', payload: response });
  } catch (error) {
    yield put({ type: 'HIDE_LOADING' });
    yield put({
      type: 'SHOW_ALERT',
      payload: {
        type: 'danger',
        title: 'Failed to load',
        message: 'Failed to load invest data',
      },
    });
    yield put({ type: 'FETCH_INVEST_DATA_ERROR' });
  }
}

async function fetchCsv(url, headers) {
  try {
    const response = await fetch(url, headers);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    // Convertir la respuesta a un Blob
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error fetching CSV:', error);
    throw error;
  }
}

function* downloadInvestDataCsv(action) {
  const { payload } = action;
  try {
    yield put({ type: 'SHOW_LOADING' });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/security/investDataCsv` +
      `?page=${payload.page}` +
      `&offset=${payload.offset}` +
      `${payload.search ? `&search=${encodeURIComponent(payload.search)}` : ''}` +
      `${payload.from ? `&from=${encodeURIComponent(payload.from)}` : ''}` +
      `${payload.to ? `&to=${encodeURIComponent(payload.to)}` : ''}` +
      `${payload.senderIds ? `&senderIds=${encodeURIComponent(payload.senderIds)}` : ''}` +
      `${payload.operatorIds ? `&operatorIds=${encodeURIComponent(payload.operatorIds)}` : ''}` +
      `${payload.idBranch ? `&idBranch=${encodeURIComponent(payload.idBranch)}` : ''}` +
      `${payload.idCountry ? `&idCountry=${encodeURIComponent(payload.idCountry)}` : ''}` +
      `${payload.idDepartment ? `&idDepartment=${encodeURIComponent(payload.idDepartment)}` : ''}` +
      `${payload.idCity ? `&idCity=${encodeURIComponent(payload.idCity)}` : ''}` +
      `${payload.state ? `&state=${encodeURIComponent(payload.state)}` : ''}` +
      `${payload.returnTrackingId ? `&returnTrackingId=${encodeURIComponent(payload.returnTrackingId)}` : ''}`;

    const headers = {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      }),
    };

    const blob = yield call(fetchCsv, requestURL, headers);

    const date = new Date();
    const fileName = `${date.getFullYear()}_${(date.getMonth() + 1).toString().padStart(2, '0')}_${date.getDate().toString().padStart(2, '0')}_invest.csv`;

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }

    yield put({ type: 'HIDE_LOADING' });
    yield put({ type: 'DOWNLOAD_INVEST_DATA_CSV_SUCCESS' });
  } catch (error) {
    console.error('Error downloading CSV:', error);
    yield put({ type: 'HIDE_LOADING' });
    yield put({
      type: 'SHOW_ALERT',
      payload: {
        type: 'danger',
        title: 'Failed to download',
        message: 'Failed to download invest data CSV',
      },
    });
    yield put({ type: 'DOWNLOAD_INVEST_DATA_CSV_ERROR' });
  }
}


export function* watchFetchInvestData() {
  yield takeLatest('FETCH_INVEST_DATA_REQUESTING', fetchInvestData);
  yield takeLatest('DOWNLOAD_INVEST_DATA_CSV_REQUESTING', downloadInvestDataCsv);
}

export default watchFetchInvestData;
