import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal, OverlayTrigger, Tooltip, Col, Row } from 'react-bootstrap';
import { Show } from '../../hooks/Show';
import Grid from '../../components/Grid';
import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import { getStatusBadgeByCode } from '../../utils/StatusUtils';
import { ConvertToMoney } from '../../utils/formattingUtils';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Timeline from '../../components/Timeline';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import { DateTime, IANAZone } from 'luxon';
import formatDateWithCustomFormat, { FormatsDate } from '../../utils/formatDate';
import { useAuth } from '../../hooks/useAuth';

const SecurityModule = ({
  investData,
  total,
  totalPages,
  reduxFetchInvestData,
  clients,
  logisticsOperators,
  branches,
  reduxGetLogisticsOperators,
  reduxGetBranches,
  reduxGetClients,
  reduxGetStatuses,
  addresses,
  statuses,
  dispatch,
  reduxPatchAddressState,
  reduxDownloadInvestData
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState('');
  const [startDateFrom, setStartDateFrom] = useState(null);
  const [startDateTo, setStartDateTo] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [showTimeline, setShowTimeline] = useState(false);
  const [idAddressSelected, setIdAddressSelected] = useState(null);
  const [lastAppliedFilter, setLastAppliedFilter] = useState(null);
  const [show, setShow] = useState(false);
  const [showModalDates, setShowModalDates] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const {
    control: downloadFormControl,
    formState: { errors: downloadFormErrors },
    handleSubmit: handleSubmitDownload,
    watch: downloadFormWatchValue,
    reset: resetDownloadForm,
    clearErrors: clearDownloadFormErrors,
  } = useForm({});
  const [buttonType, setButtonType] = useState(null);

  const methods = useForm({
    defaultValues: {
      indicativeRoad: '',
      roadNumber: '',
      appendixRoad: '',
      crossoverRoad: '',
      crossoverNumber: '',
      appendixCrossingNumber: '',
      orientationCrossingNumber: '',
      insideTypes: '',
      doorNumber: '',
      inside: '',
      city: '',
      neighborhood: '',
      country: '',
      department: '',
      branch: '',
    },
  });
  const {
    register,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = methods;

  const renderTooltip = (title) => <Tooltip id='button-tooltip'>{title}</Tooltip>;

  const onSubmitChangeState = async (data) => {
    const addressesChecked = getValues('addressesChecked');

    const filterAddresses = addresses.items
      .filter((a) => a.trackingId == null || a.trackingId == '' || a.idOperator != null)
      .map((ele) => ele.idAddress);

    const filterAddressesChecked = addressesChecked.filter((el) => filterAddresses.includes(Number(el)));

    if (data.stateChangeState) {
      reduxPatchAddressState({
        addressesChecked: typeof filterAddressesChecked == 'string' ? [filterAddressesChecked] : filterAddressesChecked,
        state: data.stateChangeState,
        txtNote: data.noteChangeState ? data.noteChangeState : undefined,
      });
    } else {
      Swal.fire('Información', 'Debe seleccionar el nuevo estado', 'info');
    }
  };

  useEffect(() => {
    handleSearch();
  }, [currentPage, offset]);

  const customStyles = {
    menu: (base) => ({ ...base, zIndex: 9999 }),
    valueContainer: (baseStyles) => ({...baseStyles, maxHeight:"3.5vw", overflowY: "auto" }),
    control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap"})
  };

  const handleSearch = (searchValue = search, resetPage = false) => {
    const normalizedSearch =
      typeof searchValue === 'string'
        ? searchValue
        : (searchValue && searchValue.value) || '';

    const filterTypeMatch = normalizedSearch.match(/^([^|]+)/);
    const filterType = filterTypeMatch ? filterTypeMatch[1] : null;

    const newPage = filterType && filterType !== lastAppliedFilter ? 1 : currentPage;

    const filters = {
      page: newPage,
      offset,
      ...(normalizedSearch.trim() !== '' ? { search: normalizedSearch } : {}),
      from: startDateFrom ? startDateFrom.toISOString() : null,
      to: startDateTo ? startDateTo.toISOString() : null,
      senderIds: selectedClient ? selectedClient.value : null,
      operatorIds: selectedOperator ? selectedOperator.value : null,
      idBranch: selectedBranch ? selectedBranch.value : null,
      state: selectedState ? selectedState.value : null, // Agregar el filtro por estado
    };

    setCurrentPage(newPage);
    reduxFetchInvestData(filters);

    setLastAppliedFilter(filterType);
  };

  const handleDownload = (searchValue = search, resetPage = false) => {
    const normalizedSearch =
      typeof searchValue === 'string'
        ? searchValue
        : (searchValue && searchValue.value) || '';

    const filterTypeMatch = normalizedSearch.match(/^([^|]+)/);
    const filterType = filterTypeMatch ? filterTypeMatch[1] : null;

    const newPage = filterType && filterType !== lastAppliedFilter ? 1 : currentPage;

    const filters = {
      page: '',
      offset:'',
      ...(normalizedSearch.trim() !== '' ? { search: normalizedSearch } : {}),
      from: startDateFrom ? startDateFrom.toISOString() : null,
      to: startDateTo ? startDateTo.toISOString() : null,
      senderIds: selectedClient ? selectedClient.value : null,
      operatorIds: selectedOperator ? selectedOperator.value : null,
      idBranch: selectedBranch ? selectedBranch.value : null,
      state: selectedState ? selectedState.value : null, // Agregar el filtro por estado
    };

    setCurrentPage(newPage);
    reduxDownloadInvestData(filters);

    setLastAppliedFilter(filterType);
  };

  const handlePageOrRangeChange = (page, range) => {
    if (page !== undefined) setCurrentPage(page);
    if (range !== undefined) setOffset(range);
  };

  useEffect(() => {
    reduxGetLogisticsOperators();
    reduxGetClients();
    reduxGetBranches({
      page: 1,
      offset: 1000,
      search: '',
      isActive: true,
    });
    reduxGetStatuses({
      page: 1,
      search: '',
      offset: 1000,
    });
  }, []);

  const rescheduled = (items) => {
    if (!items || items.length === 0) return '';
    const lastRescheduled = items.find((item) => item.idNewState === 1);
    return lastRescheduled
      ? new Date(lastRescheduled.date).toLocaleString()
      : '';
  };

  const handleOpenTimeline = (id) => {
    setIdAddressSelected(id);
    setShowTimeline(true);
  };

  const handleCloseTimeline = () => {
    setIdAddressSelected(null);
    setShowTimeline(false);
  };

  const columns = [
    {
      title: '#',
      render: (rowData) => <span>{rowData.idAddress}</span>,
      field: 'idAddress',
    },
    {
      title: 'ID externo',
      render: (rowData) => <span>{rowData.externalId}</span>,
      field: 'externalId',
      searchable: true,
    },
    {
      title: 'Guía',
      render: (rowData) => <span>{rowData.trackingId}</span>,
      field: 'trackingId',
      searchable: true,
    },
    {
      title: 'Estado',
      render: (rowData) => { return getStatusBadgeByCode(rowData.newState.code) },
      field: 'state',
      searchable: false,
    },
    {
      title: 'Guía de Retorno',
      render: (rowData) => <span>{rowData.returnTrackingId}</span>,
      field: 'address.returnTrackingId',
    },
    {
      title: 'Operador',
      render: (rowData) => <span>{rowData.operator?.description}</span>,
      field: 'operator.description',
    },
    {
      title: 'Destinatario',
      render: (rowData) => (
        <span>
          {rowData.firstName} {rowData.lastName}
        </span>
      ),
      field: 'firstName-lastName',
    },
    {
      title: 'Dirección Destinatario',
      render: (rowData) => <span>{rowData.addressReceiver}</span>,
      field: 'addressReceiver',
    },
    {
      title: 'Coordenadas',
      render: (rowData) => <span>{rowData.lat}, {rowData.lon}</span>,
      field: 'coordinates',
    },
    {
      title: 'Teléfono Destinatario',
      render: (rowData) => <span>{rowData.reference1}</span>,
      field: 'reference1',
    },
    {
      title: 'Observaciones',
      render: (rowData) => <span>{rowData.reference2}</span>,
      field: 'reference2',
    },
    {
      title: 'Valor a recaudar',
      render: (rowData) => <span>{ConvertToMoney(rowData.collection)}</span>,
      field: 'collection',
    },
    {
      title: 'Ciudad',
      render: (rowData) => (
        <span>{rowData.city ? rowData.city.description : ''}</span>
      ),
      field: 'city.description',
      searchable: true,
    },
    {
      title: 'Departamento',
      render: (rowData) => (
        <span>{rowData.city?.department?.description || ''}</span>
      ),
      field: 'city.department.description',
      searchable: true,
    },
    {
      title: 'Remitente',
      render: (rowData) => <span>{rowData.company?.description}</span>,
      field: 'company.description',
    },
    {
      title: 'Bodega',
      render: (rowData) => <span>{rowData.branch?.branchName}</span>,
      field: 'branch.branchName',
    },
    {
      title: 'Fecha de reprogramación',
      render: (rowData) => <span>{rescheduled(rowData.addressRecords)}</span>,
      field: 'rescheduledDate',
    },
    {
      title: 'Opciones',
      render: (rowData) =>
        rowData.isActive && rowData.deleted === 0 && (
          <>
            <OverlayTrigger
              placement='top'
              delay={{ show: 50, hide: 50 }}
              overlay={renderTooltip('Ver Timeline')}
            >
              <button
                title="Timeline"
                className="btn btn-primary btn-sm btn-circle mr-2"
                type="button"
                onClick={() => handleOpenTimeline(rowData.idAddress)}
              >
                <i className="fas fa-info fa-xs"></i>
              </button>
            </OverlayTrigger>
          </>
        ),
    },
  ];

  const handleChangeClient = (selectedOption) => {
    setSelectedClient(selectedOption);
  };

  const handleChangeOperator = (selectedOption) => {
    setSelectedOperator(selectedOption);
  };

  const handleChangeBranch = (selectedOption) => {
    setSelectedBranch(selectedOption);
  };

  const handleChangeFromDate = (date) => {
    setStartDateFrom(date);
  };

  const handleChangeToDate = (date) => {
    setStartDateTo(date);
  };

  const CustomInput = ({ value, onClick, className }) => (
    <div className='input-group'>
      <input
        className={`form-control ${className}`}
        value={value}
        onClick={onClick}
        placeholder='dd/mm/yyyy'
      />
      <div className='input-group-prepend'>
        <button
          type='button'
          className='input-group-text'
          onClick={onClick}
        >
          <i className='fas fa-solid fa-calendar'></i>
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Novedades</h1>
              </div>
              <p className="mb-4">Módulo de Órdenes que presentan Novedades</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Órdenes con novedad
                  </h6>
                </div>
                <div className="card-body">
                  <Row>
                    <Col md={2.5} className="mx-3">
                      <label>Desde</label>
                      <br />
                      <DatePicker
                        selected={startDateFrom}
                        onChange={handleChangeFromDate}
                        dateFormat="MM-dd-yyyy hh:mm a"
                        maxDate={new Date()}
                        showTimeSelect
                        peekNextMonth
                        className="form-control"
                        isClearable
                        timeIntervals={10}
                      />
                    </Col>
                    <Col md={2.5}>
                      <label>Hasta</label>
                      <br />
                      <DatePicker
                        selected={startDateTo}
                        onChange={handleChangeToDate}
                        dateFormat="MM-dd-yyyy hh:mm a"
                        maxDate={new Date()}
                        minDate={startDateFrom}
                        showTimeSelect
                        peekNextMonth
                        className="form-control"
                        isClearable
                        timeIntervals={10}
                      />
                    </Col>
                    <Col>
                      <label htmlFor="senderIds" className="form-label">
                        Remitente
                      </label>
                      <Select
                        id="senderIds"
                        onChange={handleChangeClient}
                        isClearable={true}
                        options={
                          clients && clients.items
                            ? clients.items
                                .filter((f) => f.isActive)
                                .map((e) => ({
                                  value: e.idCompany,
                                  label: e.description,
                                }))
                            : []
                        }
                      />
                    </Col>
                    <Col>
                      <label htmlFor="operatorIds" className="form-label">
                        Operador
                      </label>
                      <Select
                        id="operatorIds"
                        onChange={handleChangeOperator}
                        isClearable={true}
                        options={
                          logisticsOperators && logisticsOperators.items
                            ? logisticsOperators.items
                                .filter((f) => f.isActive)
                                .map((ele) => ({
                                  value: ele.idCompany,
                                  label: ele.description,
                                }))
                            : []
                        }
                      />
                    </Col>
                    <Col>
                        <label
                          htmlFor='state'
                          className='form-label'>
                          Estado
                        </label>
                        <Select
                          isClearable={true}
                          styles={customStyles}
                          {...register('state')}
                          id='state'
                          value={selectedState} // Usar selectedState como valor
                          onChange={(value) => {
                            setSelectedState(value); // Actualizar selectedState
                            setValue('state', value?.value || ''); // Actualizar el valor del formulario (si usas react-hook-form)
                          }}
                          options={
                            statuses && Object.keys(statuses).length > 0
                              ? statuses.items
                                  .filter((ele) => ele.code === 'NOENTR' || ele.code === 'INVEST') // Filtrar solo NOENTR e INVEST
                                  .map((ele) => ({
                                    value: ele.code,
                                    label: ele.name,
                                  }))
                              : []
                          }
                        />
                      </Col>
                    <Col>
                      <label htmlFor="branch" className="form-label">
                        Bodega
                      </label>
                      <Select
                        id="branch"
                        onChange={handleChangeBranch}
                        isClearable={true}
                        options={
                          branches && branches.items
                            ? branches.items
                                .filter((f) => f.isActive)
                                .map((ele) => ({
                                  value: ele.idBranch,
                                  label: ele.branchName,
                                }))
                            : []
                        }
                      />
                    </Col>
                    <Col
                      className="d-flex align-items-center"
                      style={{ marginTop: '32px', gap: '1px' }}
                    >
                      <Button
                        className="btn btn-primary btn-sm custom-button flex-fill"
                        onClick={handleSearch}
                        style={{ height: '37px' }}
                      >
                        Buscar
                      </Button>
                      <Button
                        className="btn btn-primary btn-sm custom-button d-flex align-items-center justify-content-center"
                        onClick={handleDownload}
                        style={{ height: '37px', gap: '5px' }}
                      >
                        <i className="fa fa-download me-2" aria-hidden="true"></i> Descargar
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <Grid
                    cols={columns}
                    data={investData || []}
                    total={total || 0}
                    page={currentPage}
                    pages={totalPages || 0}
                    offset={offset}
                    onChangePage={(page) => handlePageOrRangeChange(page, undefined)}
                    onChangeRange={(range) => handlePageOrRangeChange(undefined, range)}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      const newSearch =
                        value && value.target && value.target.value
                          ? value.target.value
                          : value;
                      setSearch(newSearch);
                      handleSearch(newSearch);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="xl"
        show={showTimeline}
        onHide={handleCloseTimeline}
        onEscapeKeyDown={handleCloseTimeline}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Timeline - Orden #{idAddressSelected}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Timeline idAddress={idAddressSelected} />
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showModalDates}
        onHide={() => setShowModalDates(false)}
        onEscapeKeyDown={() => setShowModalDates(false)}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {buttonType === 3 ? 'Descargar histórico' : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormProvider>
            <Form>
              <Row>
                <Form.Group as={Col}>
                  <div>Desde</div>
                  <Controller
                    control={downloadFormControl}
                    name='startDate'
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        required
                        selected={value}
                        onChange={onChange}
                        showTimeSelect={buttonType === 3}
                        customInput={<CustomInput className={`${downloadFormErrors.startDate && 'is-invalid'}`} />}
                        peekNextMonth
                        dateFormat={buttonType === 3 ? 'dd/MM/yyyy hh:mm aa' : 'dd/MM/yyyy'}
                        maxDate={new Date()}
                        timeIntervals={10}
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <div>Hasta</div>
                  <Controller
                    control={downloadFormControl}
                    name='endDate'
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        required
                        selected={value}
                        onChange={onChange}
                        showTimeSelect={buttonType === 3}
                        customInput={<CustomInput className={`${downloadFormErrors.endDate && 'is-invalid'}`} />}
                        peekNextMonth
                        dateFormat={buttonType === 3 ? 'dd/MM/yyyy hh:mm aa' : 'dd/MM/yyyy'}
                        maxDate={new Date()}
                        minDate={downloadFormWatchValue('startDate')}
                        timeIntervals={10}
                        filterTime={(time) => {
                          const dateStart = downloadFormWatchValue('startDate');
                          if (!dateStart) {
                            return true;
                          }
                          const selectedTime = dateStart.getTime();
                          const currentTime = time.getTime();
                          return currentTime >= selectedTime;
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </Row>
              <Modal.Footer>
                <button
                  className='btn btn-primary float-right'
                  type='submit'
                >
                  Descargar <i className='fas fa-solid fa-download'></i>
                </button>
              </Modal.Footer>
            </Form>
          </FormProvider>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  investData: state.investDataState.investData,
  total: state.investDataState.total,
  totalPages: state.investDataState.totalPages,
  clients: state.companyState.clients,
  logisticsOperators: state.companyState.logisticsOperators,
  branches: state.branchState.branches,
  statuses: state.statusesState.statuses,
  addresses: state.addressState.addresses,
});

const mapDispatchToProps = (dispatch) => ({
  reduxFetchInvestData: (payload) =>
    dispatch({
      type: 'FETCH_INVEST_DATA_REQUESTING',
      payload,
    }),
  reduxDownloadInvestData: (payload) =>
    dispatch({
      type:'DOWNLOAD_INVEST_DATA_CSV_REQUESTING',
      payload
     }),
  reduxGetLogisticsOperators: (payload) =>
    dispatch({
      type: 'FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST',
      value: payload,
    }),
  reduxGetBranches: (payload) =>
    dispatch({
      type: 'FETCH_BRANCHES_REQUEST',
      value: payload,
    }),
  reduxGetClients: (payload) =>
    dispatch({
      type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
      value: payload,
    }),
    reduxGetStatuses: (payload) =>
      dispatch({
        type: "FETCH_NEW_STATUS_REQUEST",
        value: payload,
      }),
      reduxPatchAddressState: (payload) =>
        dispatch({
          type: 'UPDATESTATE_ADDRESS_REQUEST',
          value: payload,
        }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityModule);