import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../api/request";

function* newAdjustment(payload) {
  try {
    yield put({
      type: "REQUESTING_NEW_ADJUSTMENT",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/adjustments`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
          type: "success",
          title: "Guardado exitoso",
          message: "La creación del ajuste se completó correctamente",
      },
    });

    yield put({
      type: "REQUEST_NEW_ADJUSTMENT_SUCCESS",
      value: response
    });

  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load adjustment",
      },
    });
    yield put({
      type: "REQUEST_NEW_ADJUSTMENT_ERROR",
    });
  }
}

function* downloadCertificate(payload) {
  try {
    yield put({
      type: "DOWNLOAD_ADJUSTMENT_CERTIFICATE_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/adjustments/${payload.value.idAdjustment}/certificate`;

    // Crear una promesa para manejar XMLHttpRequest
    const downloadFile = () => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', requestURL, true);
        xhr.setRequestHeader('Authorization', `Bearer ${window.localStorage.getItem("token")}`);
        xhr.responseType = 'blob';

        xhr.onload = function() {
          if (this.status === 200) {
            resolve(this.response);
          } else {
            reject(new Error('Failed to download file'));
          }
        };

        xhr.onerror = function() {
          reject(new Error('Network error'));
        };

        xhr.send();
      });
    };

    const blob = yield call(downloadFile);
    
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `certificado-egreso-${payload.value.idAdjustment}_${Date.now()}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

  yield put({
    type: "DOWNLOAD_ADJUSTMENT_CERTIFICATE_SUCCESS",
  });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Certificate downloading failed",
        message: "",
      },
    });
    yield put({
      type: "DOWNLOAD_ADJUSTMENT_CERTIFICATE_ERROR",
    });
  }
}

function* getAdjustmentRecordsById(payload) {
  try {
    yield put({
      type: "REQUESTING_ADJUSTMENT_RECORDS_BY_ID",
    });

    yield put({
      type: "SHOW_LOADING",
    });
    
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/adjustment/tracking/${payload.value}`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "REQUEST_ADJUSTMENT_RECORDS_BY_ID_SUCCESS",
      value: response
    });

    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
      
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed Timeline",
        message: "Error al cargar la información del ajuste",
      },
    });
    yield put({
      type: "REQUEST_ADJUSTMENT_RECORDS_BY_ID_ERROR",
    });
  }
}

function* findPendingAdjustments(payload) {

  try {
    const {page, offset, search} = payload.value;

    yield put({
      type: "REQUESTING_GET_PENDING_ADJUSTMENT",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/pendingAdjustment?page=${page}&offset=${offset}&search=${search}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "GET_PENDING_ADJUSTMENT_SUCCESS",
      value: response
    });
    
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load adjustment",
      },
    });
    yield put({
      type: "GET_PENDING_ADJUSTMENT_ERROR",
    });
  }
}

function* findHistoricalAdjustments(payload) {
  
  try {
    const {page, offset, search} = payload.value;

    yield put({
      type: "REQUESTING_GET_HISTORICAL_ADJUSTMENT",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/historicalAdjustment?page=${page}&offset=${offset}&search=${search}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "GET_HISTORICAL_ADJUSTMENT_SUCCESS",
      value: response
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load adjustment",
      },
    });
    yield put({
      type: "GET_HISTORICAL_ADJUSTMENT_ERROR",
    });
  }
}

function* adjustmentDecision(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "ADJUSTMENT_DECISION_SUCCESS",
    });

    // Si el ajuste que se creó fue por 'Peticion de cliente' descarga certificado
    if(payload.value.idAdjustmentSubType == 8)
      yield call(downloadCertificate, { value: { idAdjustment: payload.value.idAdjustment } });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/processAdjustments`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "ADJUSTMENT_DECISION_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Process adjustment approval failed",
        message: "Adjustment approval has failed",
      },
    });
    yield put({
      type: "ADJUSTMENT_DECISION_ERROR"
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

export function* watchAdjustment() {
  yield takeLatest("FETCH_ADD_NEW_ADJUSTMENT_REQUEST", newAdjustment);
  yield takeLatest("FETCH_GET_ADJUSTMENT_RECORDS_BY_ID", getAdjustmentRecordsById);
  yield takeLatest("FETCH_GET_PENDING_ADJUSTMENTS", findPendingAdjustments);
  yield takeLatest("FETCH_GET_HISTORICAL_ADJUSTMENTS", findHistoricalAdjustments);
  yield takeLatest("ADJUSTMENT_DECISION", adjustmentDecision);
  yield takeLatest("DOWNLOAD_ADJUSTMENT_CERTIFICATE_REQUEST", downloadCertificate);
}
