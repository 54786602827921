import { useEffect, useState } from 'react';
import io from "socket.io-client";

export const useSocket = (token, namespace) => {
    const SOCKET_SERVER_URL = `${process.env.REACT_APP_REPORT_URL}${namespace ? `/${namespace}` : ''}`;

    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (!token) return;

        const newSocket = io.connect(SOCKET_SERVER_URL, {
            auth: { token: `${token}` },
        });

        newSocket.on('connect', () => {
            console.log(`WebSocket ${namespace} conectado con nuevo token`);
            setSocket(newSocket);
            newSocket.emit('subscribe', token);
        });

        return () => {
            console.log(`Desconectando el WebSocket ${namespace}...`);
            newSocket.disconnect();
        };
    }, [token]);

    return socket;
};