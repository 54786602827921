import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../api/request";


function* postPdfTracking(payload) {

  try {
   
    yield put({
      type: "SHOW_LOADING",
    });
    // const requestURL = payload.value.url;
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/pdf-tracking/${payload.value.trackingId}`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      })
    };

    const response = yield call(request, requestURL, headers);
    

    yield put({
      type: "TRACKING_PDF_SUCCESS",
      value: response.base64Pdf,
    });
    yield put({
      type: "HIDE_LOADING",
    });
   
  } catch (error) {
    yield put({
      type: "TRACKING_PDF_SUCCESS",
      value: null,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed pdf",
        message: "Failed load PDF "+ error,
      },
    });
  }
}

function* postPdfGuideByTrackingOrId(payload) {

  try {
   
    yield put({
      type: "SHOW_LOADING",
    });
    
    const requestURL = `${process.env.REACT_APP_REPORT_URL}/api/v1/pdf-tracking/generateGuideByTrackingOrId/`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers); 

    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "TRACKING_PDF_SUCCESS",
      value: response.message
    });
   
  } catch (error) {
    yield put({
      type: "TRACKING_PDF_SUCCESS",
      value: null,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed pdf",
        message: "Guide or Id not found - " + error,
      },
    });
  }
}

export function* watchPdfTracking() {
  yield takeLatest("PDF_TRACKING_REQUEST", postPdfTracking);
  yield takeLatest("TRACKING_OR_EXTERNALID_PDF_SUCCESS", postPdfGuideByTrackingOrId);
}
