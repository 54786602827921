import axios from 'axios';
import { store } from '../redux/store';
import { addRefreshSubscriber, getIsRefreshing, setIsRefreshing } from './tokenRefreshManager';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

function handleAxiosResponse(response) {
  if (response.status >= 200 && response.status < 300) {
    // Si es 204
    if (response.status === 204 || response.status === 205) {
      return null;
    }
    return response.data;
  }

  throw new Error(response.statusText || `HTTP error: ${response.status}`);
}

async function handleAxiosError(error) {
  if (!error.response) throw error;

  const { status, data, config } = error.response;

  if (status === 401 ) {
    if (!getIsRefreshing()) {
      setIsRefreshing(true);
      store.dispatch({ type: 'REFRESH_TOKEN_REQUEST' });
    }
    return new Promise((resolve, reject) => {
      addRefreshSubscriber(async (newToken) => {
        if (newToken) {
          try {
            const retryResponse = await axiosInstance.request({
              ...error.config,
              headers: {
                ...error.config.headers,
                Authorization: `Bearer ${newToken}`,
              },
            });
            resolve(handleAxiosResponse(retryResponse));
          } catch (retryErr) {
            reject(retryErr);
          }
        } else {
          reject(new Error('No refresh token received'));
        }
      });
    });
  }

  if (status === 422) {
    const err = new Error('Unprocessable Entity');
    err.data = data;
    throw err;
  }

  throw error;
}

export default async function requestAxios(url, options) {
  try {
    const response = await axiosInstance({
      url,
      headers: options.headers,
      method: options.method,
      data: options.body,
    });
    return handleAxiosResponse(response);
  } catch (error) {
    return handleAxiosError(error);
  }
}