import React, { useState, useRef, useEffect, useContext } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Button, Form, Modal, OverlayTrigger, Tooltip, Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { DateTime, IANAZone } from 'luxon';

import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import Grid from '../../components/Grid';
import Timeline from '../../components/Timeline';

import { Show } from '../../hooks/Show';
import { useAuth } from '../../hooks/useAuth';
import { PermissionContext } from '../../hooks/PermissionContext';

import { ConvertToMoney } from '../../utils/formattingUtils';
import formatDateWithCustomFormat, { FormatsDate } from '../../utils/formatDate';
import { getStatusBadgeByCode } from '../../utils/StatusUtils';
import { HideByRole, isNullOrUndefined } from '../../utils/functionsUtils';
import { hasNoCountryLimitations } from '../../utils/functionsUtils';

const Swal = require('sweetalert2');

const Address = ({
  reduxGetClients,
  clients,
  reduxGetLogisticsOperators,
  logisticsOperators,
  reduxGetStatuses,
  statuses,
  reduxGetAddresses,
  addresses,
  countries,
  reduxGetCountries,
  reduxResetAddressForm,
  reduxUploadFile,
  fileUploaded,
  fileUploadedTrackingId,
  reduxResetAddressesEvents,
  reduxGetBranches,
  branches,
  reduxUpdateAddressFile,
  reduxDownloadInvalidAddresses,
  successfulUploadInvalidAddresses,
  fetchInvalidAddressesData,
  novelties,
  reduxGetNovelties,
}) => {
  const { hasPermission } = useContext(PermissionContext);

  const {
    register,
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({});

  const {
    register: registerUpload,
    handleSubmit: handleSubmitUpload,
    watch: watchUpload,
    formState: { errors: errorsUpload },
    reset: resetUpload,
    setValue: setValueUpload,
  } = useForm({});

  const {
    register: registerUpdateAddress,
    handleSubmit: handleSubmitUpdateAddress,
    watch: watchUpdateAddress,
    formState: { errors: errorsUploadUpdateAddress },
    reset: resetUpdateAddress,
  } = useForm({});

  const dispatch = useDispatch();

  const {
    control: downloadFormControl,
    formState: { errors: downloadFormErrors },
    handleSubmit: handleSubmitDownload,
    watch: downloadFormWatchValue,
    reset: resetDownloadForm,
    clearErrors: clearDownloadFormErrors,
  } = useForm({});

  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const countryUser = userData?.idCountry;
  const viewAllCountriesData = hasNoCountryLimitations();

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [showUpload, setShowUpload] = useState(false);
  const [showUpdateAddress, setShowUpdateAddress] = useState(false);

  const [fileName, setFileName] = useState(null);
  const [fileError, setFileError] = useState(null);

  const [fileNameUpdateAddress, setFileNameUpdateAddress] = useState(null);
  const [fileErrorUpdateAddress, setFileErrorUpdateAddress] = useState(null);

  const { session } = useAuth();

  const [showTimeline, setShowTimeline] = useState(false);
  const [idAddressSelected, setIdAddressSelected] = useState(null);
  const [showModalDates, setShowModalDates] = useState(false);
  const [startDateFrom, setStartDateFrom] = useState(null);
  const [startDateTo, setStartDateTo] = useState(null);
  const [buttonType, setButtonType] = useState(null);
  const [search, setSearch] = useState('');

  const indicativeRoad = useRef({});
  indicativeRoad.current = watch('indicativeRoad', '');

  const roadNumber = useRef({});
  roadNumber.current = watch('roadNumber', '');

  const appendixRoad = useRef({});
  appendixRoad.current = watch('appendixRoad', '');

  const crossoverRoad = useRef({});
  crossoverRoad.current = watch('crossoverRoad', '');

  const crossoverNumber = useRef({});
  crossoverNumber.current = watch('crossoverNumber', '');

  const appendixCrossingNumber = useRef({});
  appendixCrossingNumber.current = watch('appendixCrossingNumber', '');

  const orientationCrossingNumber = useRef({});
  orientationCrossingNumber.current = watch('orientationCrossingNumber', '');

  const insideTypes = useRef({});
  insideTypes.current = watch('insideTypes', '');

  const doorNumber = useRef({});
  doorNumber.current = watch('doorNumber', '');

  const inside = useRef({});
  inside.current = watch('inside', '');

  const file = useRef({});
  file.current = watchUpload('file', '');

  const fileUpdateAddress = useRef({});
  fileUpdateAddress.current = watchUpdateAddress('file', '');

  const state = useRef({});
  state.current = watch('state', '');

  const novelty = useRef({});
  novelty.current = watch('novelty', '');

  const client = useRef({});
  client.current = watch('senderIds', '');

  const operator2 = useRef({});
  operator2.current = watch('operatorIds', '');

  const branch = useRef({});
  branch.current = watch('branch', '');

  const customStyles = {
    menu: (base) => ({ ...base, zIndex: 9999 }),
    valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto" }),
    control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" })
  };

  const CustomInput = ({ value, onClick, className }) => (
    <div className='input-group'>
      <input
        className={`form-control ${className}`}
        value={value}
        onClick={onClick}
        placeholder='dd/mm/yyyy'
      />
      <div className='input-group-prepend'>
        <button
          type='button'
          className='input-group-text'
          onClick={onClick}>
          <i className='fas fa-solid fa-calendar'></i>
        </button>
      </div>
    </div>
  );

  const handleOpenTimeline = (id) => {
    setIdAddressSelected(id);
    setShowTimeline(true);
  };

  const handleCloseTimeline = () => {
    reduxResetAddressesEvents();
    setIdAddressSelected(null);
    setShowTimeline(false);
  };

  const handleCloseUpload = () => {
    setFileName(null);
    resetUpload();
    reduxResetAddressForm();
    setFileError(null);
    setShowUpload(false);
  };

  const handleCloseUpdateAddress = () => {
    setFileNameUpdateAddress(null);
    resetUpdateAddress();
    reduxResetAddressForm();
    setFileErrorUpdateAddress(null);
    setShowUpdateAddress(false);
  };

  const handleShowUpload = () => {
    setFileName(null);
    resetUpload();
    setFileError(null);
    setShowUpload(true);
  };

  const handleShowUploadUpdateAddress = () => {
    setFileNameUpdateAddress(null);
    resetUpdateAddress();
    setFileErrorUpdateAddress(null);
    setShowUpdateAddress(true);
  };

  const rescheduled = (items) => {
    let maxDate = null;
    items.forEach((item) => {
      if (item.date !== null && item.date !== '' && item.idNewState === 1) {
        const currentDate = new Date(item.date);
        if (maxDate === null || currentDate > maxDate) {
          maxDate = currentDate;
        }
      }
    });

    if (maxDate !== null) {
      const formattedDate = formatDate(maxDate);
      return formattedDate;
    } else {
      return '';
    }
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const columns = [
    {
      title: '#',
      render: (rowData) => {
        return <span>{rowData.idAddress}</span>;
      },
      field: 'idAddress',
      searchable: true,
    },
    {
      title: 'ID externo',
      render: (rowData) => {
        return <span>{rowData.externalId}</span>;
      },
      field: 'externalId',
      searchable: true,
    },
    {
      title: 'Guía',
      render: (rowData) => {
        return <span>{rowData.trackingId}</span>;
      },
      field: 'trackingId',
      searchable: true,
    },
    {
      title: 'Estado',
      render: (rowData) => { return getStatusBadgeByCode(rowData.newState.code) },
    },
    {
      title: 'Novedad',
      render: (rowData) => { return rowData.novelty?.description },
      visible: !hasPermission("feature:isClient")
    },
    {
      title: 'Guía de Retorno',
      render: (rowData) => {
        return <span>{rowData.returnTrackingId}</span>;
      },
      field: 'returnTrackingId',
      searchable: true,
    },
    ...(hasPermission('feature:show-operator-address')
      ? [
        {
          title: 'Operador',
          render: (rowData) => {
            return <span>{rowData.operator ? rowData.operator.description : ''}</span>;
          },
          field: 'operator',
          searchable: false,
        },
      ]
      : []),
    {
      title: 'Destinatario',
      render: (rowData) => {
        return (
          <span>
            {rowData.firstName} {rowData.lastName}
          </span>
        );
      },
      field: 'firstName-lastName',
      searchable: true,
    },
    ...(hasPermission('feature:show-recipient-address')
      ? [
        {
          title: 'Dirección Destinatario',
          render: (rowData) => {
            return <span>{rowData.address}</span>;
          },
          field: 'address',
          searchable: true,
        },
      ]
      : []),
    ...(hasPermission('feature:show-recipient-address')
      ? [
        {
          title: 'Coordenadas',
          render: (rowData) => {
            return <span>{rowData.coordinates}</span>;
          },
          field: 'coordinates',
          searchable: true,
        },
      ]
      : []),
    ...(hasPermission('feature:show-reference1-address')
      ? [
        {
          title: 'Teléfono Destinatario',
          render: (rowData) => {
            return (
              <span>{rowData.reference1}</span>
            );
          },
          field: 'reference1',
          searchable: true,
        },
      ]
      : []),
    {
      title: 'Observaciones',
      render: (rowData) => {
        return <span>{rowData.reference2}</span>;
      },
      field: 'reference2',
      searchable: true,
      visible: !HideByRole(session?.role.name)
    },
    {
      title: 'Valor a recaudar',
      render: (rowData) => {
        return <span>{ConvertToMoney(rowData.collection)}</span>;
      },
      field: 'collection',
      searchable: true,
    },
    {
      title: 'Ciudad',
      render: (rowData) => {
        return <span>{rowData.city ? rowData.city.description : ''}</span>;
      },
      field: 'city.description',
      searchable: true,
    },
    {
      title: 'Departamento',
      render: (rowData) => {
        return <span>{rowData.city && rowData.city.department ? rowData.city.department.description : ''}</span>;
      },
      field: 'city.department.description',
      searchable: true,
    },
    ...(hasPermission('feature:show-client-address')
      ? [
        {
          title: 'Remitente',
          render: (rowData) => {
            return <span>{rowData.company ? rowData.company.description : ''}</span>;
          },
          field: 'company.description',
          searchable: true,
        },
      ]
      : []),
    ...(hasPermission('feature:show-branch')
      ? [
        {
          title: 'Bodega',
          render: (rowData) => {
            return <span>{rowData.branch ? rowData.branch.branchName : ''}</span>;
          },
          field: 'branch.branchName',
          searchable: true,
        },
      ]
      : []),
    {
      title: 'Fecha de reprogramacion',
      render: (rowData) => {
        return <span>{rescheduled(rowData.addressRecords)}</span>;
      },
      field: 'rescheduledDate',
      searchable: true,
    },
    {
      title: 'Opciones',
      render: (rowData) => {
        return (
          <>
            {rowData.deleted === 0 && (
              <Show when='feature:see-timeline'>
                <button
                  title='Timeline'
                  className='btn btn-primary btn-sm  btn-circle mr-2'
                  type='button'
                  onClick={(e) => handleOpenTimeline(rowData.idAddress)}>
                  <i className='fas fa-info fa-xs'></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  const handleChangeMultiSelect = (type, value) => {
    if (type === 'Sender') {
      const newSenderIds = {
        ...getValues('senderIds'),
        value
      }
      setValue("senderIds", String(newSenderIds.value.map(item => item.value)));
    } else if (type === 'Operator') {
      const newOperatorIds = {
        ...getValues('operatorIds'),
        value
      }
      setValue("operatorIds", String(newOperatorIds.value.map(item => item.value)));
    }
  }

  const searchByFilter = () => {
    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
    };
    if (client.current) {
      filters = {
        ...filters,
        senderIds: client.current,
      };
    }
    if (operator2.current) {
      filters = {
        ...filters,
        operatorIds: operator2.current,
      };
    }
    if (state.current) {
      filters = {
        ...filters,
        state: state.current,
      };
    }
    if (novelty.current) {
      filters = {
        ...filters,
        novelty: novelty.current,
      };
    }
    if (branch.current) {
      filters = {
        ...filters,
        idBranch: branch.current,
      };
    }
    if (startDateFrom && startDateTo) {
      filters = {
        ...filters,
        from: formatDateWithCustomFormat(startDateFrom, {
          format: FormatsDate.FULL24,
        }),
        to: formatDateWithCustomFormat(startDateTo, {
          format: FormatsDate.FULL24,
        }),
      };
    }
    reduxGetAddresses(filters);
  }

  const getOperatorId = (data) => {
    return hasPermission('feature:isOperator') ? session.role.idCompany : data.idOperator;
  };

  const onSubmitUpload = (data) => {
    const file = data.file[0];
    if (file && file.type === 'text/csv') {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        const csvData = reader.result;

        const isCorrectDelimited = csvData.split('\n')[0].split(';').length > 1;

        if (!isCorrectDelimited) {
          return Swal.fire('Archivo errado', 'Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas\'', 'error');
        } else {
          reduxUploadFile({
            file: file,
            company:
              session && session.role.idCompany
                ? session.role.idCompany
                : data.idCompanyModal,
            idOperator: getOperatorId(data),
          });

          resetUpload();
          setFileName(null);
          reduxResetAddressForm();
          setFileError(null);
          setShowUpload(false);
        }
      };
    } else return Swal.fire('Archivo errado', 'El archivo no cumple con el formato establecido <br/> Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas\'', 'error');
  };

  const onSubmitUploadUpdateInvalidAddress = (data) => {
    const file = data.file[0];
    if (file && file.type === 'text/csv') {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        const csvData = reader.result;

        const isCorrectDelimited = csvData.split('\n')[0].split(';').length > 1;

        if (!isCorrectDelimited) {
          return Swal.fire('Archivo errado', 'Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas\'', 'error');
        } else {
          reduxUpdateAddressFile({
            file: file
          });

          resetUpdateAddress();
          setFileNameUpdateAddress(null);
          reduxResetAddressForm();
          setFileErrorUpdateAddress(null);
          setShowUpdateAddress(false);
        }
      };
    } else return Swal.fire('Archivo errado', 'El archivo no cumple con el formato establecido <br/> Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas\'', 'error');
  };

  const renderTooltip = (title) => <Tooltip id='button-tooltip'>{title}</Tooltip>;

  const handledChangeFrom = (date) => {
    setStartDateFrom(date);
    if (!date) {
      return setStartDateTo(date);
    }
    const toDate = new Date(date);
    toDate.setHours(23, 59, 59, 0);
    setStartDateTo(toDate);
  };

  const handledChangeTo = (date) => {
    setStartDateTo(date);
  };

  const openModalDates = (type) => {
    setShowModalDates(true);
    resetDownloadForm({
      endDate: null,
      startDate: null,
      senders: null,
      country: null,
      operator: null,
    });
    setButtonType(type);
    reduxGetCountries({
      page: 1,
      offset: 1000,
      search: '',
      isActive: true,
    });
  };

  const handleDownload = (data) => {
    Swal.fire(
      'Información',
      'El proceso puede tardar un poco... al finalizar se abrirá el Excel con la información.',
      'info'
    ).then((res) => {
      switch (buttonType) {
        case 1:
          handleShowDownload(data);
          dispatch({
            type: 'SHOW_LOADING',
          });
          break;
        case 2:
          handleShowDownloadAddressWithoutGuide(data);
          dispatch({
            type: 'SHOW_LOADING',
          });
          break;
        case 3:
          handleShowDownloadHistory(data);
          dispatch({
            type: 'SHOW_LOADING',
          });
          break;
        case 4:
          handleShowDownloadInvalidAddresses(data);
          dispatch({
            type: 'SHOW_LOADING',
          });
          break;
        default:
          break;
      }
    });

  };

  const handleShowDownload = (data) => {
    const token = window.localStorage.getItem('token');
    dispatch({
      type: 'HIDE_LOADING',
    });
    let _data = {
      idCompany: session.role.idCompany,
      role: session.role.idRole,
      start: formatDateWithCustomFormat(data.startDate, {
        format: 'YYYY-MM-DD 00:00:00',
      }),
      end: formatDateWithCustomFormat(data.endDate, {
        format: 'YYYY-MM-DD 23:59:59',
      }),
    };

    resetDownloadForm({
      endDate: null,
      startDate: null,
      senders: null,
      country: null,
      operator: null,
    });
    clearDownloadFormErrors();

    fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/addresses/download`, {
      method: 'POST',
      body: JSON.stringify(_data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          window.localStorage.removeItem('token');
          Swal.fire({
            icon: 'info',
            title: 'Token Expired',
            text: 'Your session has expired. Please log in again.',
          }).then(() => {
            window.location.href = '/';
          });
          return;
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd') + '-errors-addresses.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
        dispatch({
          type: 'HIDE_LOADING',
        });
      })
      .catch((err) => {
        dispatch({
          type: 'HIDE_LOADING',
        });
        console.log(err);
      });
  };

  const handleShowDownloadAddressWithoutGuide = (data) => {
    const token = window.localStorage.getItem('token');
    dispatch({
      type: 'HIDE_LOADING',
    });
    let _data = {
      idCompany: session.role.idCompany,
      start: formatDateWithCustomFormat(data.startDate, {
        format: 'YYYY-MM-DD 00:00:00',
      }),
      end: formatDateWithCustomFormat(data.endDate, {
        format: 'YYYY-MM-DD 23:59:59',
      }),
    };

    resetDownloadForm({
      endDate: null,
      startDate: null,
      senders: null,
      country: null,
      operator: null,
    });
    clearDownloadFormErrors();

    fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/addresses/downloadAddressWithoutGuide`, {
      method: 'POST',
      body: JSON.stringify(_data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          window.localStorage.removeItem('token');
          Swal.fire({
            icon: 'info',
            title: 'Token Expired',
            text: 'Your session has expired. Please log in again.',
          }).then(() => {
            window.location.href = '/';
          });
          return;
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd') + '-incomplete-addresses.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
        dispatch({
          type: 'HIDE_LOADING',
        });
      })
      .catch((err) => {
        dispatch({
          type: 'HIDE_LOADING',
        });
        console.log(err);
      });
  };

  const handleShowDownloadHistory = (data) => {
    const token = window.localStorage.getItem('token');
    dispatch({
      type: 'HIDE_LOADING',
    });
    let _data = {
      role: session.role.idRole,
      idUser: session.idUser,
      start: formatDateWithCustomFormat(data.startDate, {
        format: FormatsDate.FULL24,
      }),
      end: formatDateWithCustomFormat(data.endDate, { format: FormatsDate.FULL24 }),
      senders: data.senders && String(data.senders.map(item => item.value)),
      country: data.country && data.country.label,
      operator: data.operator && data.operator.value,
    };

    resetDownloadForm({
      endDate: null,
      startDate: null,
      senders: null,
      country: null,
      operator: null,
    });

    clearDownloadFormErrors();

    fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/addresses/downloadAllAddresses`, {
      method: 'POST',
      body: JSON.stringify(_data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          window.localStorage.removeItem('token');
          Swal.fire({
            icon: 'info',
            title: 'Token Expired',
            text: 'Your session has expired. Please log in again.',
          }).then(() => {
            window.location.href = '/';
          });
          return;
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd') + 'addresses.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
        dispatch({
          type: 'HIDE_LOADING',
        });
      })
      .catch((err) => {
        dispatch({
          type: 'HIDE_LOADING',
        });
        console.log(err);
      });
  };

  const handleShowDownloadInvalidAddresses = (data) => {
    let _data = {
      start: formatDateWithCustomFormat(data.startDate, {
        format: FormatsDate.FULL24,
      }),
      end: formatDateWithCustomFormat(data.endDate, { format: FormatsDate.FULL24 })
    };

    resetDownloadForm({
      endDate: null,
      startDate: null,
      senders: null,
      country: null,
      operator: null,
    });

    clearDownloadFormErrors();
    reduxDownloadInvalidAddresses(_data)
  };

  useEffect(() => {
    if (file.current != null && file.current.length > 0) {
      setFileName(file.current[0].name);
    }
  }, [file.current]);

  useEffect(() => {
    if (fileUpdateAddress.current != null && fileUpdateAddress.current.length > 0) {
      setFileNameUpdateAddress(fileUpdateAddress.current[0].name);
    }
  }, [fileUpdateAddress.current]);

  useEffect(() => {
    reduxGetAddresses({
      page: 1,
      offset: offset,
      search: '',
    });
    reduxGetClients();
    reduxGetLogisticsOperators();
    reduxGetStatuses({
      page: 1,
      search: '',
      offset: 1000,
    });
    reduxGetNovelties({
      page: 1,
      search: "",
      offset: 1000,
      status: "",
    });
    reduxResetAddressForm();
    reset();
    resetDownloadForm({
      endDate: null,
      startDate: null,
      senders: null,
      country: null,
      operator: null,
    });
    fileUploaded = null;
    reduxGetBranches({
      page: 1,
      offset: 1000,
      search: '',
      isActive: true,
    });
  }, []);

  useEffect(() => {
    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
    };
    if (client.current) {
      filters = {
        ...filters,
        senderIds: client.current,
      };
    }
    if (operator2.current) {
      filters = {
        ...filters,
        operatorIds: operator2.current,
      };
    }
    if (state.current) {
      filters = {
        ...filters,
        state: state.current,
      };
    }
    if (novelty.current) {
      filters = {
        ...filters,
        novelty: novelty.current,
      };
    }
    if (branch.current) {
      filters = {
        ...filters,
        idBranch: branch.current,
      };
    }
    if (startDateFrom && startDateTo) {
      filters = {
        ...filters,
        from: formatDateWithCustomFormat(startDateFrom, {
          format: FormatsDate.FULL24,
        }),
        to: formatDateWithCustomFormat(startDateTo, {
          format: FormatsDate.FULL24,
        }),
      };
    }
    reduxGetAddresses(filters);
  }, [currentPage]);

  useEffect(() => {
    if (fileUploaded) {
      reduxGetAddresses({
        page: currentPage,
        offset: offset,
        search: search,
      });

      if (fileUploaded.invalidItems.length > 0) {
        const token = window.localStorage.getItem("token");
        fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/addresses/invalidOrder`,
          {
            method: "POST",
            body: JSON.stringify(fileUploaded.invalidItems),
            headers: new Headers({
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            }),
          }
        )
          .then((response) => response.blob())
          .then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = `unconfirmed_invalids_${DateTime.now().toISODate()}.csv`;
            document.body.appendChild(a);
            a.click();
            a.remove();
          });
        console.warn(fileUploaded.invalidItems)
      }

      Swal.fire(
        'Proceso exitoso!',
        'Se procesaron ' + fileUploaded.processed + " de " + fileUploaded.total + ' registros del archivo cargado.',
        'success'
      );
    }
  }, [fileUploaded]);

  useEffect(() => {
    if (fileUploadedTrackingId) {
      reduxGetAddresses({
        page: currentPage,
        offset: offset,
        search: search,
      });
      Swal.fire(
        'Proceso exitoso!',
        'En total se procesaron ' + fileUploadedTrackingId.total + ' registros del archivo cargado.',
        'success'
      );
    }
  }, [fileUploadedTrackingId]);

  useEffect(() => {
    if (successfulUploadInvalidAddresses) {
      Swal.fire(
        'Proceso exitoso!',
        'Se procesaron ' + fetchInvalidAddressesData.processed + " de " + fetchInvalidAddressesData.total + ' registros del archivo cargado.',
        'success'
      );
    }
  }, [successfulUploadInvalidAddresses]);

  return (
    <div>
      <div id='wrapper'>
        <Sidebar />
        <div
          id='content-wrapper'
          className='d-flex flex-column'>
          <div id='content'>
            <Topbar />
            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center mb-1'>
                <h1 className='h3 mb-0 text-gray-800 mr-3'>Direcciones</h1>
                <div className='ml-2'>
                  <Show when='feature:upload-address'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Cargar direcciones')}>
                      <button
                        className='btn btn-primary btn-circle'
                        type='button'
                        onClick={handleShowUpload}>
                        <i className='fas fa-upload fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
                <div className='ml-2'>
                  <Show when='feature:download-address'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Descargar direcciones erróneas')}>
                      <button
                        className='btn btn-danger btn-circle'
                        type='button'
                        onClick={(e) => openModalDates(1)}>
                        <i className='fas fa-download fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
                <div className='ml-2'>
                  <Show when='feature:download-address-without-guide'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Descargar direcciones sin guía')}>
                      <button
                        className='btn btn-danger btn-circle'
                        type='button'
                        onClick={(e) => openModalDates(2)}>
                        <i className='fas fa-file-download fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
                <div className='ml-2'>
                  <Show when='feature:download-all-address'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Descargar histórico')}>
                      <button
                        className='btn btn-info btn-circle'
                        type='button'
                        onClick={(e) => openModalDates(3)}>
                        <i className='fas fa-file-download fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
                <div className='ml-2'>
                  <Show when='feature:update-upload-invalid-address'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Cargar direcciones inválidas')}>
                      <button
                        className='btn btn-warning btn-circle'
                        type='button'
                        onClick={handleShowUploadUpdateAddress}>
                        <i className='fas fa-upload fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
                <div className='ml-2'>
                  <Show when='feature:download-invalid-address'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Descargar direcciones inválidas')}>
                      <button
                        className='btn btn-warning btn-circle'
                        type='button'
                        onClick={(e) => openModalDates(4)}>
                        <i className='fas fa-file-download fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
              </div>
              <p className='mb-4'>Módulo de Administración de direcciones</p>
              <div className='card shadow mb-4'>
                <div className='card-header py-3'>
                  <h6 className='m-0 font-weight-bold text-primary'>Listado de Direcciones</h6>
                </div>
                <div className='card-body'>
                  <Row>
                    <Col md={2.5} className='mx-3'>
                      <label>Desde</label>
                      <br />
                      <DatePicker
                        selected={startDateFrom}
                        onChange={(date) => handledChangeFrom(date)}
                        dateFormat='MM-dd-yyyy hh:mm a'
                        maxDate={new Date()}
                        showTimeSelect
                        peekNextMonth
                        className='form-control'
                        isClearable
                        timeIntervals={10}
                      />
                    </Col>
                    <Col md={2.5} >
                      <label>Hasta</label>
                      <br />
                      <DatePicker
                        selected={startDateTo}
                        onChange={(date) => handledChangeTo(date)}
                        dateFormat='MM-dd-yyyy hh:mm a'
                        maxDate={new Date()}
                        minDate={startDateFrom}
                        showTimeSelect
                        peekNextMonth
                        className='form-control'
                        isClearable
                        timeIntervals={10}
                        filterTime={(time) => {
                          if (!startDateFrom) {
                            return true;
                          }
                          const selectedTime = startDateFrom.getTime();
                          const currentTime = time.getTime();
                          return currentTime >= selectedTime;
                        }}
                      />
                    </Col>
                    <Col>
                      <Show when='feature:show-client-address'>
                        <label
                          htmlFor='senderIds'
                          className='form-label'>
                          Remitente
                        </label>
                        <Select
                          {...register('senderIds')}
                          id='senderIds'
                          onChange={(value) => handleChangeMultiSelect('Sender', value)}
                          isClearable={true}
                          isDisabled={!startDateFrom || !startDateTo}
                          isMulti
                          styles={customStyles}
                          options={
                            clients && Object.keys(clients).length > 0
                              ? clients.items
                                .filter((f) => f.isActive && (viewAllCountriesData || f.country == countryUser))
                                .map((e, key) => ({
                                  value: e.idCompany,
                                  label: e.description,
                                }))
                              : []
                          }
                        />
                      </Show>
                    </Col>
                    <Col>
                      <Show when='feature:show-operator-address'>
                        <label
                          htmlFor='operatorIds'
                          className='form-label'>
                          Operador
                        </label>
                        <Select
                        {...register('operatorIds')}
                        id='operatorIds'
                        onChange={(value) => handleChangeMultiSelect('Operator', value)}
                        isClearable={true}
                        isMulti
                        isDisabled={!startDateFrom || !startDateTo}
                        styles={customStyles}
                        options={
                          logisticsOperators &&
                          Object.keys(logisticsOperators).length > 0 &&
                          logisticsOperators.items
                            .filter((f) => f.isActive === true && (viewAllCountriesData || f.country == countryUser))
                            .map((ele, key) => ({
                              value: ele.idCompany,
                              label: ele.description,
                            }))
                        }
                      />
                      </Show>
                    </Col>
                    <Col>
                      <label
                        htmlFor='state'
                        className='form-label'>
                        Estado
                      </label>
                      <Select
                        isClearable={true}
                        isDisabled={!startDateFrom || !startDateTo}
                        styles={customStyles}
                        {...register('state')}
                        id='state'
                        onChange={(value) => setValue('state', value?.value || '')}
                        options={
                          statuses && Object.keys(statuses).length > 0
                            ? [
                              ...statuses.items.map((ele) => ({
                                value: ele.code,
                                label: ele.name,
                              })),
                              { value: '-2', label: 'Eliminados' },
                            ]
                            : []
                        }
                      />
                    </Col>
                    <Show whenNot={["feature:isClient"]} >
                      <Col>
                        <label
                          htmlFor='novelty'
                          className='form-label'>
                          Novedad
                        </label>
                        <Select
                          isClearable={true}
                          isDisabled={!startDateFrom || !startDateTo}
                          styles={customStyles}
                          {...register('novelty')}
                          id='novelty'
                          onChange={(value) => setValue('novelty', value?.value || '')}
                          options={
                            novelties && Object.keys(novelties).length > 0
                              ? [
                                ...novelties.items.filter(e => e.Status.code == state.current)
                                  .map((ele) => ({
                                    value: ele.code,
                                    label: ele.description,
                                  })),
                              ]
                              : []
                          }
                        />
                      </Col>
                    </Show>
                    <Col>
                      <Show when='feature:show-branch'>
                      <label
                        htmlFor='branch'
                        className='form-label'>
                        Bodega
                      </label>
                      <Select
                        isClearable={true}
                        isDisabled={!startDateFrom || !startDateTo}
                        styles={customStyles}
                        {...register('branch')}
                        id='branch'
                        onChange={(value) => setValue('branch', value?.value || '')}
                        options={
                          branches && Object.keys(branches).length > 0
                            ? branches.items
                                .filter((b) => viewAllCountriesData || b.country.idCountry == countryUser)
                                .map((ele) => ({
                                  value: ele.idBranch,
                                  label: ele.branchName,
                                }))
                            : []
                        }
                      />
                      </Show>
                    </Col>
                    <Col>
                      <button className='btn btn-primary' onClick={searchByFilter} style={{ marginTop: "32px" }}>Buscar</button>
                    </Col>
                  </Row>
                  <br />
                  <Grid
                    className='stickyAddress'
                    cols={columns}
                    data={addresses && Object.keys(addresses).length > 0 ? addresses.items : []}
                    total={addresses && addresses.hasOwnProperty('total') ? addresses.total : 0}
                    page={addresses && Object.keys(addresses).length > 0 ? Number(addresses.page) : currentPage}
                    pages={addresses && Object.keys(addresses).length > 0 ? Number(addresses.totalPages) : 0}
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      let filters = {
                        page: 1,
                        offset: value,
                        search: search,
                      };
                      if (client.current) {
                        filters = {
                          ...filters,
                          senderIds: client.current,
                        };
                      }
                      if (operator2.current) {
                        filters = {
                          ...filters,
                          operatorIds: operator2.current,
                        };
                      }
                      if (state.current) {
                        filters = {
                          ...filters,
                          state: state.current,
                        };
                      }
                      if (novelty.current) {
                        filters = {
                          ...filters,
                          novelty: novelty.current,
                        };
                      }
                      if (branch.current) {
                        filters = {
                          ...filters,
                          idBranch: branch.current,
                        };
                      }
                      if (startDateFrom && startDateTo) {
                        filters = {
                          ...filters,
                          from: formatDateWithCustomFormat(startDateFrom, {
                            format: FormatsDate.FULL24,
                          }),
                          to: formatDateWithCustomFormat(startDateTo, {
                            format: FormatsDate.FULL24,
                          }),
                        };
                      }
                      reduxGetAddresses(filters);
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      let filters = {
                        page: 1,
                        offset: offset,
                        search: value,
                      };
                      if (client.current) {
                        filters = {
                          ...filters,
                          senderIds: client.current,
                        };
                      }
                      if (operator2.current) {
                        filters = {
                          ...filters,
                          operatorIds: operator2.current,
                        };
                      }
                      if (state.current) {
                        filters = {
                          ...filters,
                          state: state.current,
                        };
                      }
                      if (novelty.current) {
                        filters = {
                          ...filters,
                          novelty: novelty.current,
                        };
                      }
                      if (branch.current) {
                        filters = {
                          ...filters,
                          idBranch: branch.current,
                        };
                      }
                      if (startDateFrom && startDateTo) {
                        filters = {
                          ...filters,
                          from: formatDateWithCustomFormat(startDateFrom, {
                            format: FormatsDate.FULL24,
                          }),
                          to: formatDateWithCustomFormat(startDateTo, {
                            format: FormatsDate.FULL24,
                          }),
                        };
                      }
                      reduxGetAddresses(filters);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          size='xl'
          show={showTimeline}
          onHide={handleCloseTimeline}
          onEscapeKeyDown={handleCloseTimeline}
          backdrop='static'
          keyboard={true}>
          <Modal.Header closeButton>
            <Modal.Title>Timeline</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Timeline idAddress={idAddressSelected}></Timeline>
          </Modal.Body>
        </Modal>
      </div>
      <div>
        <Modal
          show={showUpload}
          onHide={handleCloseUpload}
          onEscapeKeyDown={handleCloseUpload}
          backdrop='static'
          keyboard={true}>
          <form onSubmit={handleSubmitUpload(onSubmitUpload)}>
            <Modal.Header closeButton>
              <Modal.Title>Cargar archivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {session && session.role.idCompany == null && (
                <div className='row'>
                  <div className='form-group col-md-12'>
                    <label
                      htmlFor='idCompanyModal'
                      className='form-label'>
                      Remitente
                    </label>
                    <Select
                        {...registerUpload('idCompanyModal', { required: true })}
                        isClearable={true}
                        id="idCompanyModal"
                        onChange={(value) => setValueUpload('idCompanyModal', value?.value || '')}
                        className={`${errorsUpload.idCompanyModal && 'is-invalid'}`}
                        options={
                          clients && Object.keys(clients).length > 0
                            ? clients.items
                              .filter((f) => f.isActive && f.createOrderEnabled)
                              .map((e, key) => ({
                                value: e.idCompany,
                                label: e.description,
                              }))
                            : []
                        }
                        styles={{
                          menu: (baseStyles) => ({...baseStyles, zIndex: 9999})
                        }}
                      />
                    {errorsUpload.idCompanyModal && <span className='invalid-feedback'>El remitente es requerido</span>}
                  </div>
                </div>
              )}
              {!(hasPermission('feature:isOperator') || hasPermission('feature:isClient')) && (
                <div className='row'>
                  <div className='form-group col-md-12'>
                    <label
                      htmlFor='idOperator'
                      className='form-label'>
                      Operador
                    </label>
                    <Select
                      {...registerUpload('idOperator', { required: true })}
                      isClearable={true}
                      className={`${errorsUpload.idOperator && 'is-invalid'}`}
                      id="idOperator"
                      onChange={(value) => setValueUpload('idOperator', value?.value || '')}
                      options={
                        logisticsOperators &&
                        Object.keys(logisticsOperators).length > 0 &&
                        logisticsOperators.items
                          .filter((f) => f.isActive === true)
                          .map((ele, key) => ({
                            value: ele.idCompany,
                            label: ele.description,
                          }))
                      }
                      styles={{
                        menu: (baseStyles) => ({ ...baseStyles, zIndex: 9999 })
                      }}
                    />
                    {errorsUpload.idOperator && <span className='invalid-feedback'>El operador es requerido</span>}
                  </div>
                </div>
              )}
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="file" className="form-label">
                    Archivo
                  </label>
                  <div className="custom-file">
                    <input
                      type='file'
                      id='file'
                      name='file'
                      {...registerUpload('file', { required: true })}
                      className={`custom-file-input ${errorsUpload.file && 'is-invalid'}`}
                    />{' '}
                    <label
                      className='custom-file-label'
                      htmlFor='validatedCustomFile'>
                      {fileError ? fileError : fileName ? fileName : 'Seleccionar archivo...'}
                    </label>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={handleCloseUpload}>
                Cancelar
              </Button>
              <Button
                variant='primary'
                type='submit'>
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
      <div>
        <Modal
          show={showUpdateAddress}
          onHide={handleCloseUpdateAddress}
          onEscapeKeyDown={handleCloseUpdateAddress}
          backdrop='static'
          keyboard={true}>
          <form onSubmit={handleSubmitUpdateAddress(onSubmitUploadUpdateInvalidAddress)}>
            <Modal.Header closeButton>
              <Modal.Title>Actualizar direcciones erroneas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="file" className="form-label">
                    Archivo
                  </label>
                  <div className="custom-file">
                    <input
                      type='file'
                      id='file'
                      name='file'
                      {...registerUpdateAddress('file', { required: true })}
                      className={`custom-file-input ${errorsUploadUpdateAddress.file && 'is-invalid'}`}
                    />{' '}
                    <label
                      className='custom-file-label'
                      htmlFor='validatedCustomFile'>
                      {fileErrorUpdateAddress ? fileErrorUpdateAddress : fileNameUpdateAddress ? fileNameUpdateAddress : 'Seleccionar archivo...'}
                    </label>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={handleCloseUpdateAddress}>
                Cancelar
              </Button>
              <Button
                variant='primary'
                type='submit'>
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
      <div>
        <Modal
          size='lg'
          show={showModalDates}
          onHide={() => {
            setShowModalDates(false);
          }}
          onEscapeKeyDown={() => setShowModalDates(false)}
          backdrop='static'
          keyboard={true}>
          <Modal.Header closeButton>
            <Modal.Title>
              {buttonType === 1
                ? 'Descargar direcciones erróneas'
                : buttonType === 2
                  ? 'Descargar direcciones sin guía'
                  : buttonType === 3
                    ? 'Descargar histórico'
                    : 'Descargar direcciones invalidas'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormProvider>
              <Form onSubmit={handleSubmitDownload(handleDownload)}>
                <Row>
                  <Form.Group as={Col}>
                    <div>Desde</div>
                    <Controller
                      control={downloadFormControl}
                      name='startDate'
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          required
                          selected={value}
                          onChange={onChange}
                          showTimeSelect={buttonType === 3}
                          customInput={<CustomInput className={`${downloadFormErrors.startDate && 'is-invalid'}`} />}
                          peekNextMonth
                          dateFormat={buttonType === 3 ? 'dd/MM/yyyy hh:mm aa' : 'dd/MM/yyyy'}
                          maxDate={new Date()}
                          timeIntervals={10}
                        />
                      )}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <div>Hasta</div>
                    <Controller
                      control={downloadFormControl}
                      name='endDate'
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          required
                          selected={value}
                          onChange={onChange}
                          showTimeSelect={buttonType === 3}
                          customInput={<CustomInput className={`${downloadFormErrors.endDate && 'is-invalid'}`} />}
                          peekNextMonth
                          dateFormat={buttonType === 3 ? 'dd/MM/yyyy hh:mm aa' : 'dd/MM/yyyy'}
                          maxDate={new Date()}
                          minDate={downloadFormWatchValue('startDate')}
                          timeIntervals={10}
                          filterTime={(time) => {
                            const dateStart = downloadFormWatchValue('startDate');
                            if (!dateStart) {
                              return true;
                            }
                            const selectedTime = dateStart.getTime();
                            const currentTime = time.getTime();
                            return currentTime >= selectedTime;
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Row>
                {
                  buttonType === 3 &&
                  <Show whenNot={['feature:isClient', 'feature:isOperator']}>
                    <Row>
                      <Form.Group as={Col}>
                        <div>Remitente</div>
                        <Controller
                          name='senders'
                          control={downloadFormControl}
                          render={({ field }) => (
                            <Select
                              {...field}
                              isDisabled={!downloadFormWatchValue('startDate') || !downloadFormWatchValue('endDate')}
                              isMulti
                              styles={customStyles}
                              options={
                                clients && Object.keys(clients).length > 0
                                  ? clients.items
                                    .filter((f) => f.isActive)
                                    .map((e, key) => ({
                                      value: e.idCompany,
                                      label: e.description,
                                    }))
                                  : []
                              }
                            />
                          )}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col}>
                        <div>País</div>
                        <Controller
                          name='country'
                          control={downloadFormControl}
                          render={({ field }) => (
                            <Select
                              {...field}
                              isDisabled={!downloadFormWatchValue('startDate') || !downloadFormWatchValue('endDate')}
                              isClearable={true}
                              styles={customStyles}
                              options={
                                countries && Object.keys(countries).length > 0
                                  ? countries.items
                                    .filter((f) => f.isActive)
                                    .map((ele, key) => ({
                                      value: ele.idCountry,
                                      label: ele.description,
                                    }))
                                  : []
                              }
                            />
                          )}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col}>
                        <div>Operador</div>
                        <Controller
                          name='operator'
                          control={downloadFormControl}
                          render={({ field }) => (
                            <Select
                              {...field}
                              isDisabled={!downloadFormWatchValue('startDate') || !downloadFormWatchValue('endDate')}
                              styles={customStyles}
                              isClearable={true}
                              options={
                                logisticsOperators && Object.keys(logisticsOperators).length > 0
                                  ? logisticsOperators.items
                                    .filter((f) => f.isActive)
                                    .map((ele, key) => ({
                                      value: ele.idCompany,
                                      label: ele.description,
                                    }))
                                  : []
                              }
                            />
                          )}
                        />
                      </Form.Group>
                    </Row>
                  </Show>
                }
                <Modal.Footer>
                  <button
                    dis
                    className='btn  btn-primary float-right'
                    type='submit'>
                    Descargar <i className='fas fa-solid fa-download'></i>
                  </button>
                </Modal.Footer>
              </Form>
            </FormProvider>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    clients: state.companyState.clients,
    logisticsOperators: state.companyState.logisticsOperators,
    statuses: state.statusesState.statuses,
    addresses: state.addressState.addresses,
    address: state.addressState.address,
    fileUploaded: state.addressState.fileUploaded,
    fileUploadedTrackingId: state.addressState.fileUploadedTrackingId,
    successfulCreateBulkTrackingIdAddress: state.addressState.successfulCreateBulkTrackingIdAddress,
    bulkTrackingIdOperatorAddress: state.addressState.bulkTrackingIdOperatorAddress,
    branches: state.branchState.branches,
    successfulUploadInvalidAddresses: state.addressState.successfulUploadInvalidAddresses,
    fetchInvalidAddressesData: state.addressState.fetchInvalidAddressesData,
    novelties: state.noveltyState.novelties,
    countries: state.countryState.countries,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetStatuses: (payload) =>
      dispatch({
        type: "FETCH_NEW_STATUS_REQUEST",
        value: payload,
      }),
    reduxGetClients: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
        value: payload,
      }),
    reduxGetLogisticsOperators: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST',
        value: payload,
      }),
    reduxGetAddresses: (payload) =>
      dispatch({
        type: 'FETCH_ADDRESSES_REQUEST',
        value: payload,
      }),
    reduxResetAddressForm: () =>
      dispatch({
        type: 'RESET_ADDRESS_FORM',
      }),
    reduxUploadFile: (payload) =>
      dispatch({
        type: 'UPLOAD_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxUpdateAddressFile: (payload) =>
      dispatch({
        type: 'UPDATE_UPLOAD_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxDownloadInvalidAddresses: (payload) =>
      dispatch({
        type: 'FETCH_INVALID_ADDRESSES',
        value: payload,
      }),
    reduxResetAddressFormTrackingId: () =>
      dispatch({
        type: 'RESET_TRACKING_ID_FORM',
      }),
    reduxResetAddressesEvents: () =>
      dispatch({
        type: 'RESET_READ_BY_IDADDRESS_ADDRESSES_EVENT',
      }),
    reduxGetBranches: (payload) =>
      dispatch({
        type: 'FETCH_BRANCHES_REQUEST',
        value: payload,
      }),
    reduxGetNovelties: (payload) =>
      dispatch({
        type: "FETCH_NOVELTIES_REQUEST",
        value: payload,
      }),
    reduxGetCountries: (payload) =>
      dispatch({
        type: 'FETCH_COUNTRIES_REQUEST',
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
