import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../api/request";
import requestAxios from "../../api/requestAxios";

function* fetchAll(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "FETCH_ALERT_TYPES_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alertTypes?page=${payload.value.page}&offset=${payload.value.offset}&search=${payload.value.search}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_ALERT_TYPES_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Load failed",
        message: "Loading Alert Types",
      },
    });
    yield put({
      type: "FETCH_ALERT_TYPES_ERROR"
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* fetchOne(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "FETCH_ONE_ALERT_TYPE_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alertTypes/${payload.value.idAlertType}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_ONE_ALERT_TYPE_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Load failed",
        message: "Loading Alert Type",
      },
    });
    yield put({
      type: "FETCH_ONE_ALERT_TYPE_ERROR"
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* create(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "CREATE_ALERT_TYPE_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alertTypes`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };
    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "CREATE_ALERT_TYPE_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Create failed",
        message: "Alert type was not created",
      },
    });
    yield put({
      type: "CREATE_ALERT_TYPE_ERROR",
      value: error.response,
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* update(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "UPDATE_ALERT_TYPE_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alertTypes`;

    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };
    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "UPDATE_ALERT_TYPE_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Update failed",
        message: "Alerts Type was not updated",
      },
    });
    yield put({
      type: "UPDATE_ALERT_TYPE_ERROR",
      value: error.response,
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

export function* watchAlertTypes() {
  yield takeLatest("FETCH_ALERT_TYPES_REQUEST", fetchAll);
  yield takeLatest("FETCH_ONE_ALERT_TYPE_REQUEST", fetchOne);
  yield takeLatest("CREATE_ALERT_TYPE_REQUEST", create);
  yield takeLatest("UPDATE_ALERT_TYPE_REQUEST", update);
}
