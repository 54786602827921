import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/css/sb-admin-2.css';
import './assets/css/style.css';
import { Provider } from 'react-redux';
import { PermissionProvider } from './hooks/PermissionContext';
import { store } from './redux/store';
import { AuthProvider } from './hooks/AuthContext';
import { ErrorHandler } from './components/ErrorHandler'

ReactDOM.render(
    <Provider store={store} key="provider">
        <AuthProvider store={store}>
            <PermissionProvider store={store}>
                <ErrorHandler>
                    <React.StrictMode>
                        <App />
                    </React.StrictMode>
                </ErrorHandler>
            </PermissionProvider>
        </AuthProvider>
    </Provider>, document.getElementById('root'));

reportWebVitals();
