import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../api/request";

function* getAllOrdersToAssignCourier(payload) {
  try {
    yield put({
      type: "FETCH_DSP_ORDERS_ASSIGN_COURIER_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${
      process.env.REACT_APP_API_URL
    }/api/v1/dsp/order/assignToCourier?page=${payload.value.page}&search=${
      payload.value.search
    }&offset=${payload.value.offset}${
      payload.value.isActive ? "&isActive=" + payload.value.isActive : ""
    }`;
    if (payload.value.state && payload.value.state != "") {
      requestURL = requestURL + `&state=${payload.value.state}`;
    }
    if (payload.value.from && payload.value.from != "") {
      requestURL = requestURL + `&from=${payload.value.from}`;
    }
    if (payload.value.to && payload.value.to != "") {
      requestURL = requestURL + `&to=${payload.value.to}`;
    }
    if (payload.value.senders) {
      requestURL = requestURL + `&senders=${payload.value.senders}`;
    }
    if (payload.value.deliveryDate) {
      requestURL = requestURL + `&deliveryDate=${payload.value.deliveryDate}`;
    }
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_DSP_ORDER_ASSIGN_COURIER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Addresses Available",
      },
    });
    yield put({
      type: "FETCH_DSP_ORDER_ASSIGN_COURIER_ERROR",
    });
  }
}

export function* watchDspAssignCourierOrder() {
  yield takeLatest("FETCH_DSP_ORDERS_ASSIGN_COURIER_REQUEST", getAllOrdersToAssignCourier);
}
