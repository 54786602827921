import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../api/request";

function* getAllCategories(payload) {
  try {
    yield put({
      type: "FETCH_CATEGORIES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/categories?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_CATEGORY_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load categories",
      },
    });
    yield put({
      type: "FETCH_CATEGORY_ERROR",
    });
  }
}

function* postCategory(payload) {
  try {
    yield put({ type: "CREATE_CATEGORY_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/categories`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save category",
      },
    });

    yield put({
      type: "CREATE_CATEGORY_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save category",
      },
    });
    yield put({
      type: "CREATE_CATEGORY_ERROR",
    });
  }
}

function* getCategoryById(payload) {
  try {
    yield put({
      type: "READ_CATEGORY_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/categories/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READ_CATEGORY_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_CATEGORY_ERROR",
    });
  }
}

function* deleteCategoryById(payload) {
  try {
    yield put({
      type: "DELETE_CATEGORY_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/categories/changeState/${payload.value.idCategory}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable category",
      },
    });

    yield put({
      type: "DELETE_CATEGORY_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable category",
      },
    });
    yield put({
      type: "DELETE_CATEGORY_ERROR",
    });
  }
}

function* updateCategory(payload) {
  try {
    yield put({
      type: "UPDATE_CATEGORY_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/categories/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update category",
      },
    });

    yield put({
      type: "UPDATE_CATEGORY_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update category",
      },
    });
    yield put({
      type: "UPDATE_CATEGORY_ERROR",
    });
  }
}

function* deleteSoftCategoryById(payload) {
  try {
    yield put({
      type: "DELETE_SOFT_CATEGORY_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/categories/deleteSoft/${payload.value.idCategory}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DELETE_SOFT_CATEGORY_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });

    if (response.message) {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed delete soft",
          message: response.message,
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful delete soft",
          message: "Successful delete soft category",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed delete soft",
        message: "Failed delete soft category",
      },
    });
    yield put({
      type: "DELETE_SOFT_CATEGORY_ERROR",
    });
  }
}

export function* watchCategory() {
  yield takeLatest("FETCH_CATEGORIES_REQUEST", getAllCategories);
  yield takeLatest("CREATE_CATEGORY_REQUEST", postCategory);
  yield takeLatest("READ_CATEGORY_REQUEST", getCategoryById);
  yield takeLatest("DELETE_CATEGORY_REQUEST", deleteCategoryById);
  yield takeLatest("UPDATE_CATEGORY_REQUEST", updateCategory);
  yield takeLatest("DELETE_SOFT_CATEGORY_REQUEST", deleteSoftCategoryById);
}
