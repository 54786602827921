import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../api/request";
import {addressNomenclatureActions} from "../reducers/addressNomenclatureReducer";

function* getAllAddressNomenclature(payload) {
    try {
        yield put({
            type: addressNomenclatureActions.FECTCHIN_ALL_ADDRESSNOMENCLATURE,
        });

        yield put({
            type: "SHOW_LOADING",
        });

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressNomenclatures?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }`;
        const headers = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
        };
        const response = yield call(request, requestURL, headers);

        yield put({
            type: addressNomenclatureActions.FETCH_ADDRESSNOMENCLATURE_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
          });
        console.log(error);

    }
}

function* postAddressNomenclature(payload) {
    try {
        yield put({type: addressNomenclatureActions.CREATE_ADDRESSNOMENCLATURE_REQUESTING});

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressNomenclatures`;

        const headers = {
            method: "POST",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(payload.value),
        };

        const response = yield call(request, requestURL, headers);

        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "success",
                title: "Successful save",
                message: "Successful save Address Cardinal Point ",
            },
        });
        yield put({
            type: addressNomenclatureActions.CREATE_ADDRESSNOMENCLATURE_SUCCESS,
            value: response,
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed save",
                message: "Failed save City",
            },
        });
        yield put({
            type: addressNomenclatureActions.CREATE_ADDRESSNOMENCLATURE_ERROR,
        });
    }
}

function* getAddressNomenclatureById(payload) {
    try {
        yield put({
            type: addressNomenclatureActions.READ_ADDRESSNOMENCLATURE_REQUESTING,
        });

        yield put({
            type: "SHOW_LOADING",
        });

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressNomenclatures/${payload.value.id}`;

        const headers = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
        };
        const response = yield call(request, requestURL, headers);

        yield put({
            type: addressNomenclatureActions.READ_ADDRESSNOMENCLATURE_SUCCESS,
            value: response,
        });

        yield put({
            type: addressNomenclatureActions.READ_ADDRESSNOMENCLATURE_SUCCESS,
            value: response,
        });

        yield put({
            type: "HIDE_LOADING",
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: addressNomenclatureActions.READ_ADDRESSNOMENCLATURE_ERROR,
        });
    }
}

function* deleteAddressNomenclatureById(payload) {
    try {
        yield put({
            type: addressNomenclatureActions.DELETE_ADDRESSNOMENCLATURE_REQUESTING,
        });

        yield put({
            type: "SHOW_LOADING",
        });

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressNomenclatures/changeState/${payload.value.idAddressNomenclature}`;

        const headers = {
            method: "PATCH",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(payload.value),
        };

        const response = yield call(request, requestURL, headers);

        yield put({
            type: addressNomenclatureActions.DELETE_ADDRESSNOMENCLATURE_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "success",
                title: "Successful disable",
                message: "Successful disable addressNomenclature",
            },
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed disable",
                message: "Failed disable addressNomenclature",
            },
        });
        yield put({
            type: addressNomenclatureActions.DELETE_ADDRESSNOMENCLATURE_ERROR,
        });
    }
}

function* updateAddressNomenclatureById(payload) {
    try {
        yield put({
            type: addressNomenclatureActions.UPDATE_ADDRESSNOMENCLATURE_REQUESTING,
        });

        yield put({
            type: "SHOW_LOADING",
        });

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressNomenclatures/${payload.value.idAddressNomenclature}`;

        const headers = {
            method: "PATCH",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(payload.value),
        };

        const response = yield call(request, requestURL, headers);

        yield put({
            type: addressNomenclatureActions.UPDATE_ADDRESSNOMENCLATURE_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "success",
                title: "Successful update",
                message: "Successful update Country",
            },
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed update",
                message: "Failed update Country",
            },
        });
        yield put({
            type: addressNomenclatureActions.UPDATE_UPDATE_ADDRESSNOMENCLATURE_ERROR,
        });
    }
}


export function* watchAllAddressNomenclature() {
    yield takeLatest(addressNomenclatureActions.FETCH_ADDRESSNOMENCLATURE_REQUEST, getAllAddressNomenclature);
    yield takeLatest(addressNomenclatureActions.CREATE_ADDRESSNOMENCLATURE_REQUEST, postAddressNomenclature);
    yield takeLatest(addressNomenclatureActions.READ_ADDRESSNOMENCLATURE_REQUEST, getAddressNomenclatureById);
    yield takeLatest(addressNomenclatureActions.DELETE_ADDRESSNOMENCLATURE_REQUEST, deleteAddressNomenclatureById);
    yield takeLatest(addressNomenclatureActions.UPDATE_ADDRESSNOMENCLATURE_REQUEST, updateAddressNomenclatureById);
}
