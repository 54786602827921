import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../api/request";

function* getAllStatuses(payload) {
  try {
    yield put({
      type: "FETCH_NEW_STATUS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/status?page=${
      payload.value.page
    }&search=${payload.value.search}&offset=${payload.value.offset}${
      payload.value.isActive ? "&isActive=" + payload.value.isActive : ""
    }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_NEW_STATUS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load statuses",
      },
    });
    yield put({
      type: "FETCH_NEW_STATUS_ERROR",
    });
  }
}

function* postStatus(payload) {
  try {
    yield put({ type: "CREATE_STATUSES_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/status`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save status",
      },
    });

    yield put({
      type: "CREATE_STATUSES_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save status",
      },
    });
    yield put({
      type: "CREATE_STATUSES_ERROR",
    });
  }
}

function* patchStatus(payload) {
  try {
    yield put({ type: "UPDATE_STATUSES_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/status`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update status",
      },
    });

    yield put({
      type: "UPDATE_STATUSES_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update status",
      },
    });
    yield put({
      type: "UPDATE_STATUSES_ERROR",
    });
  }
}

export function* watchStatuses() {
  yield takeLatest("FETCH_NEW_STATUS_REQUEST", getAllStatuses);
  yield takeLatest("CREATE_NEW_STATUS_REQUEST", postStatus);
  yield takeLatest("UPDATE_NEW_STATUS_REQUEST", patchStatus);
}
