import {call, put, takeLatest} from "redux-saga/effects";
import {auditoriaNotificationsActions} from "../reducers/auditoriaNotificationsReducer";
import request from "../../api/request";


function* getReportNotifications(payload){
    try {
        yield put({
            type: "SHOW_LOADING",
        });
        const query = `page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}&startDate=${payload.value.startDate}&endDate=${payload.value.endDate}`;
        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/notificationController?${query}`;
        const headers = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
        };
        const response = yield call(request, requestURL, headers);
        yield put({
            type: auditoriaNotificationsActions.FETCH_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
    }catch (e) {
        yield put({
            type: "HIDE_LOADING",
        });
    }
}

export function* watchAuditoria() {
    yield takeLatest(auditoriaNotificationsActions.FECTCH_ALL, getReportNotifications)
}