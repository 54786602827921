import {call, put, takeLatest, select} from "redux-saga/effects";
import request from "../../api/request";
import { ordersLockedActions } from "../reducers/OrdersLockedReducer";

function* getPendingOrders(payload) {
    try {
        yield put({
            type: ordersLockedActions.FECTCHING_ALL_PENDING_ORDERS,
        });

        yield put({
            type: "SHOW_LOADING",
        });
        let query = `page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}`;
        if(payload.value.startDate && payload.value.endDate){
            query += `&startDate=${payload.value.startDate}&endDate=${payload.value.endDate}`;
        }
        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/ordersLocked/pendingOrders?${query}`;
        const headers = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
        };
        let response = yield call(request, requestURL, headers);
        yield put({
            type: ordersLockedActions.FECTCHING_ALL_PENDING_ORDERS_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed Load Data",
                message: "Failed Load Data",
            },
        });
        yield put({
            type: ordersLockedActions.FECTCHING_ALL_PENDING_ORDERS_ERROR,
        });
        console.log(error);
    }
}

function* getLockedOrders(payload) {
    try {
        yield put({
            type: ordersLockedActions.FECTCHING_ALL_LOCKED_ORDERS,
        });

        yield put({
            type: "SHOW_LOADING",
        });
        let query = `page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}`;
        if(payload.value.startDate && payload.value.endDate){
            query += `&startDate=${payload.value.startDate}&endDate=${payload.value.endDate}`;
        }
        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/ordersLocked/lockedOrders?${query}`;
        const headers = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
        };
        let response = yield call(request, requestURL, headers);
        yield put({
            type: ordersLockedActions.FECTCHING_ALL_LOCKED_ORDERS_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed Load Data",
                message: "Failed Load Data",
            },
        });
        yield put({
            type: ordersLockedActions.FECTCHING_ALL_LOCKED_ORDERS_ERROR,
        });
        console.log(error);
    }
}

function* countPendingOrders(payload) {
    try {
        yield put({
            type: ordersLockedActions.FECTCHING_COUNT_ALL_PENDING_ORDERS,
        });

        yield put({
            type: "SHOW_LOADING",
        });
        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/ordersLocked/countPendingOrders`;
        const headers = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
        };
        let response = yield call(request, requestURL, headers);
        yield put({
            type: ordersLockedActions.FECTCHING_COUNT_ALL_PENDING_ORDERS_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed Load Data",
                message: "Failed Load Data",
            },
        });
        yield put({
            type: ordersLockedActions.FECTCHING_COUNT_ALL_PENDING_ORDERS_ERROR,
        });
        console.log(error);
    }
}

function* postConfirmOrderRequest(payload) {
    try {
        yield put({
            type: ordersLockedActions.FECTCHING_CONFIRM_ORDER,
        });

        yield put({
            type: "SHOW_LOADING",
        });
        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/ordersLocked`;
        const headers = {
            method: "POST",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(payload.value),
        };
        let response = yield call(request, requestURL, headers);
        yield put({
            type: ordersLockedActions.FECTCHING_CONFIRM_ORDER_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
              type: "success",
              title: "Successful order locked",
              message: "Successful order locked",
            },
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed Load Data",
                message: "Failed Load Data",
            },
        });
        yield put({
            type: ordersLockedActions.FECTCHING_CONFIRM_ORDER_ERROR,
        });
        console.log(error);
    }
}

function* postConfirmAllOrderRequest(payload) {
    try {
        yield put({
            type: ordersLockedActions.FECTCHING_CONFIRM_ALL_ORDERS_PENDING,
        });

        yield put({
            type: "SHOW_LOADING",
        });
        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/ordersLocked/confirmAllOrders`;
        const headers = {
            method: "POST",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(payload.value.idsToConfirm),
        };
        let response = yield call(request, requestURL, headers);
        yield put({
            type: ordersLockedActions.FECTCHING_CONFIRM_ALL_ORDERS_PENDING_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
              type: "success",
              title: "Successful orders locked",
              message: "Successful orders locked",
            },
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed Load Data",
                message: "Failed Load Data",
            },
        });
        yield put({
            type: ordersLockedActions.FECTCHING_CONFIRM_ALL_ORDERS_PENDING_ERROR,
        });
        console.log(error);
    }
}

function* uploadOrdersToConfirm(payload) {
    try{

        yield put({
            type: ordersLockedActions.UPLOAD_ORDERS_TO_CONFIRM
        });
        yield put({
            type: 'SHOW_LOADING'
        });
        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/ordersLocked/uploadOrdersToConfirm`;

        const formData = new FormData();
        formData.append("file", payload.value.file);
        formData.append("guideOrExternalId", payload.value.guideOrExternalId);

        const headers = {
            method: "POST",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`
            }),
            body: formData,
        };
        let response = yield call(request, requestURL, headers);
    
        yield put({
            type: ordersLockedActions.UPLOAD_ORDERS_TO_CONFIRM_SUCCESS,
            value: response,
        });
    
        yield put({
            type: "HIDE_LOADING",
        });
    
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "success",
                title: "Successful upload",
                message: "Successful upload file",
            },
        });

        yield put({
            type: ordersLockedActions.UPLOAD_ORDERS_TO_CONFIRM_DONE,
            value: response,
        });
    } catch(error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed load",
                message: "Failed upload file "+error,
            },
        });
        yield put({
            type: ordersLockedActions.UPLOAD_ORDERS_TO_CONFIRM_ERROR,
        });
    }
}

function* postDeleteOrderPendingRequest(payload) {
    try {
        yield put({
            type: ordersLockedActions.FECTCHING_DELETE_ORDER,
        });

        yield put({
            type: "SHOW_LOADING",
        });
        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/ordersLocked/deletePending`;
        const headers = {
            method: "POST",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(payload.value),
        };
        let response = yield call(request, requestURL, headers);
        yield put({
            type: ordersLockedActions.FECTCHING_DELETE_ORDER_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
              type: "success",
              title: "Successful order lock deleted",
              message: "Successful order lock deleted",
            },
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed Load Data",
                message: "Failed Load Data",
            },
        });
        yield put({
            type: ordersLockedActions.FECTCHING_DELETE_ORDER_ERROR,
        });
        console.log(error);
    } finally {
        yield put({
            type: ordersLockedActions.FECTCHING_DELETE_ORDER_ERROR,
        });
    }
}

function* confirmOrderLocked(payload) {
    try{
        const state = yield select((state) => state.ordersLocked);
        if(state.startDate){
            payload.value.startDate = state.startDate;
            payload.value.endDate = state.endDate;
        }
        yield call(postConfirmOrderRequest, payload)
        yield call(getPendingOrders, payload)
        yield call(countPendingOrders)
    } catch(error) {
        console.error(error);
    }
}

function* confirmAllOrdersLocked(payload) {
    try{
        const state = yield select((state) => state.ordersLocked);
        if(state.startDate){
            payload.value.startDate = state.startDate;
            payload.value.endDate = state.endDate;
        }
        yield call(postConfirmAllOrderRequest, payload)
        yield call(getPendingOrders, payload)
        yield call(countPendingOrders)
    } catch(error) {
        console.error(error);
    }
}

function* deleteOrdersPending(payload) {
    try{
        const state = yield select((state) => state.ordersLocked);
        if(state.startDate){
            payload.value.startDate = state.startDate;
            payload.value.endDate = state.endDate;
        }
        yield call(postDeleteOrderPendingRequest, payload)
        yield call(getPendingOrders, payload)
        yield call(countPendingOrders)
    } catch(error) {
        console.error(error);
    }
}

function* ordersToConfirm(payload) {
    try {
        yield put({
            type: ordersLockedActions.UPLOAD_ORDERS_TO_CONFIRM
        });
        yield put({
            type: 'SHOW_LOADING'
        });

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/ordersLocked/ordersToConfirm`;

        const headers = {
            method: "POST",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(payload.value),
        };

        let response = yield call(request, requestURL, headers);

        yield put({
            type: ordersLockedActions.ORDERS_TO_CONFIRM_SUCCESS,
            value: response,
        });

        yield put({
            type: "HIDE_LOADING",
        });

        // Verificar si hay items inválidos en la respuesta
        if (response.invalidItems && response.invalidItems.length > 0) {
            let errorMessage = "Errores encontrados: ";
            
            response.invalidItems.forEach(err => {
                if (err.id) {
                    errorMessage += `ID ${err.id}: `;
                }
                if (err.error === 'Dirección no encontrada') {
                    errorMessage += "Dirección no encontrada. ";
                } else {
                    errorMessage += err.error + " ";
                }
            });

            yield put({
                type: "SHOW_ALERT",
                value: {
                    type: "warning",
                    title: "Advertencia",
                    message: errorMessage,
                },
            });
        } else {
            // Mostrar mensaje de éxito si no hay errores
            yield put({
                type: "SHOW_ALERT",
                value: {
                    type: "success",
                    title: "¡Orden bloqueada!",
                    message: response.message || "Operación completada con éxito",
                },
            });
        }

        yield put({
            type: ordersLockedActions.ORDERS_TO_CONFIRM_DONE,
            value: response,
        });

    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });

        let errorMessage = "Error en la actualización: ";
        
        if (error.response && error.response.invalidItems) {
            error.response.invalidItems.forEach(err => {
                if (err.id) {
                    errorMessage += `ID ${err.id}: `;
                }
                if (err.error === 'Dirección no encontrada') {
                    errorMessage += "Dirección no encontrada. ";
                } else {
                    errorMessage += err.error + " ";
                }
            });
        } else {
            errorMessage += error.message;
        }

        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Error en la actualización",
                message: errorMessage,
            },
        });

        yield put({
            type: ordersLockedActions.ORDERS_TO_CONFIRM_ERROR,
        });
    }
}


export function* watchOrdersLocked() {
    yield takeLatest('FETCH_ALL_PENDING_ORDERS', getPendingOrders);
    yield takeLatest('FETCH_COUNT_ALL_PENDING_ORDERS', countPendingOrders);
    yield takeLatest('FETCH_CONFIRM_ORDER_REQUEST', confirmOrderLocked);
    yield takeLatest('FETCH_CONFIRM_ALL_ORDER_REQUEST', confirmAllOrdersLocked);
    yield takeLatest('FETCH_UPLOAD_ORDERS_TO_CONFIRM', uploadOrdersToConfirm);
    yield takeLatest('FETCH_ALL_LOCKED_ORDERS', getLockedOrders);
    yield takeLatest('FETCH_DELETE_ORDER_PENDING_REQUEST', deleteOrdersPending);
    yield takeLatest('FETCH_ORDERS_TO_CONFIRM', ordersToConfirm);
}
