import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../api/request";

function* getAllCoverage(payload) {
  try {
    yield put({
      type: "FETCH_COVERAGE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });
    let requestURL = `${
      process.env.REACT_APP_API_URL
      }/api/v1/coverage/getAll?page=${payload.value.page}&search=${
      payload.value.search
      }&offset=${payload.value.offset}${
      payload.value.isActive ? "&isActive=" + payload.value.isActive : ""
      }`;
    if(payload.value.idCountry)
      requestURL += `&idCountry=${payload.value.idCountry}`;
    if(payload.value.idDepartment)
      requestURL += `&idDepartment=${payload.value.idDepartment}`;
    if(payload.value.idCity)
      requestURL += `&idCity=${payload.value.idCity}`;
    if(payload.value.idOrigin)
      requestURL += `&idOrigin=${payload.value.idOrigin}`;
    if(payload.value.idCoverageType)
      requestURL += `&idCoverageType=${payload.value.idCoverageType}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_COVERAGE_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Coverages",
      },
    });
    yield put({
      type: "FETCH_COVERAGE_ERROR",
    });
  }
}

function* postCoverage(payload) {
  try {
    yield put({ type: "CREATE_COVERAGE_REQUESTING" });

    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/coverage`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "CREATE_COVERAGE_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save Coverage",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    const responseBody = yield error.response.json();
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: `Failed save Coverage: ${responseBody.message}`,
      },
    });
    yield put({
      type: "CREATE_COVERAGE_ERROR",
    });
  }
}

function* getCoverageById(payload) {
  try {
    yield put({
      type: "FETCH_ONECOVERAGE_REQUESTING",
    });
    
    yield put({
      type: "SHOW_LOADING",
    });
    const coverageId = JSON.stringify(payload.value.coverageId);
    const branchId = JSON.stringify(payload.value.branchId);
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/coverage/getCoverageById/${coverageId}/${branchId}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);
    
    yield put({
      type: "FETCH_ONECOVERAGE_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_ONECOVERAGE_ERROR",
    });
  }
}

function* updateStatusCoverage(payload) {
  try {
    yield put({
      type: "UPDATE_STATUS_COVERAGE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    })

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/coverage/updateStatus`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPDATE_STATUS_COVERAGE_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful Update Status",
        message: "Successful Update Status Coverage",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed Update Status",
        message: "Failed Update Status Coverage",
      },
    });
    yield put({
      type: "UPDATE_STATUS_COVERAGEE_ERROR",
    });
  }
}

function* updateCoverage(payload) {
  try {
    yield put({
      type: "UPDATE_COVERAGE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/coverage`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPDATE_COVERAGE_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update Coverage",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    const responseBody = yield error.response.json();
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: `Failed update Coverage: ${responseBody.message}`,
      },
    });
    yield put({
      type: "UPDATE_COVERAGE_ERROR",
    });
  }
}

function* getCoverageType(payload) {
  try {
    yield put({
      type: "FETCH_COVERAGETYPE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/coverageType/getAll`;
    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_COVERAGETYPE_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Coverage Types",
      },
    });
    yield put({
      type: "FETCH_COVERAGETYPE__ERROR",
    });
  }
}

function* updateOperatorStatusCoverage(payload) {
  try {
    yield put({
      type: "UPDATE_COVERAGE_OPERATOR_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/assignOperatorCoverage/status`;
    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPDATE_COVERAGE_OPERATOR_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Coverage Types",
      },
    });
    yield put({
      type: "UPDATE_COVERAGE_OPERATOR_ERROR",
    });
  }
  finally{
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* postOperatorCoverage(payload) {
  try {
    yield put({
      type: "ASSIGN_COVERAGE_OPERATOR_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/assignOperatorCoverage/${payload.value.coverageId}`;
    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "ASSIGN_COVERAGE_OPERATOR_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save Coverage Operator",
      },
    });
    yield put({
      type: "ASSIGN_COVERAGE_OPERATOR_ERROR",
    });
  }
  finally{
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

export function* watchCoverage() {
  yield takeLatest("FETCH_COVERAGE_REQUEST", getAllCoverage);
  yield takeLatest("CREATE_COVERAGE_REQUEST", postCoverage);
  yield takeLatest("UPDATE_STATUS_COVERAGE_REQUEST", updateStatusCoverage);
  yield takeLatest("UPDATE_COVERAGE_REQUEST", updateCoverage);
  yield takeLatest("FETCH_ONECOVERAGE_REQUEST", getCoverageById);
  yield takeLatest("FETCH_COVERAGETYPE_REQUEST", getCoverageType);
  yield takeLatest("UPDATE_COVERAGE_OPERATOR_REQUEST", updateOperatorStatusCoverage);
  yield takeLatest("ASSIGN_COVERAGE_OPERATOR_REQUEST", postOperatorCoverage);
}
