import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../api/request";
import { onRefreshed, setIsRefreshing } from "../../api/tokenRefreshManager";

function* checkSessionStatus() {
  try {
    yield put({
      type: 'CHECK_SESSION_STATUS_REQUESTING',
    });

    yield put({
      type: 'SHOW_LOADING',
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/auth/validate-token`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    const response = yield call(request, requestURL, headers);

    const { role } = response.user;

    const permissions = role.permissionsRoles.map((elem) => {
      return elem.permission.name;
    });

    yield put({
      type: "DEFINE_PERMISSIONS",
      payload: permissions,
    });

    yield put({
      type: 'CHECK_SESSION_STATUS_SUCCESS',
      payload: response
    });

  } catch (error) {
    yield put({
      type: "CHECK_SESSION_STATUS_ERROR",
    })
  }
  finally {
    yield put({
      type: 'HIDE_LOADING',
    });
  }
}

function* login(payload) {
  try {
    yield put({
      type: 'LOGIN_REQUESTING',
    });

    yield put({
      type: 'SHOW_LOADING',
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/auth`;

    const headers = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value)
    };

    const response = yield call(request, requestURL, headers);

    const { role: { permissionsRoles } } = response.user;

    const permissions = permissionsRoles.map((elem) => {
      return elem.permission.name;
    });

    if (permissions.length === 0) throw new Error('Invalid permissions');

    yield put({
      type: "DEFINE_PERMISSIONS",
      payload: permissions,
    });

    yield put({
      type: 'LOGIN_SUCCESS',
      payload: response,
    });

  } catch (error) {
    yield put({
      type: 'SHOW_ALERT',
      value: {
        type: 'danger',
        title: 'Failed login',
        message: 'Invalid username or password'
      }
    });
    yield put({
      type: 'LOGIN_ERROR',
    });
  }
  finally {
    yield put({
      type: 'HIDE_LOADING',
    });
  }
}

function* logout() {
  try {
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/auth/logout`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    yield call(request, requestURL, headers);

    yield put({
      type: 'LOGOUT_SUCCESS',
    });
  } catch {}
}

function* refresh() {
  try {
    yield put({
      type: 'SHOW_LOADING',
    });

    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) throw new Error('No refresh token provided');

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/auth/refresh`;

    const headers = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: refreshToken }),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "REFRESH_TOKEN_SUCCESS",
      payload: response,
    });
    onRefreshed(response.accessToken);
    setIsRefreshing(false);
  } catch (error) {    
    yield put({
      type: "REFRESH_TOKEN_ERROR",
    });
    onRefreshed(null);
    setIsRefreshing(false);
    window.location.href = "/";
  }
  finally {
    yield put({
      type: 'HIDE_LOADING',
    });
  }
}

function* resetPassword(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "RESET_PASSWORD_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/auth/resetPassword/${payload.value.idUser}`;

    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    yield call(request, requestURL, headers);

    yield put({
      type: "RESET_PASSWORD_SUCCESS",
    });
  } catch (error) {
    yield put({
      type: "RESET_PASSWORD_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* resetMassivePassword() {
  try {
    yield put({
      type: "MASSIVE_RESET_PASSWORD_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/auth/resetMassivePasswords`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    yield call(request, requestURL, headers);

    yield put({
      type: "MASSIVE_RESET_PASSWORD_SUCCESS",
    });
  } catch (error) {
    yield put({
      type: "MASSIVE_RESET_PASSWORD_ERROR",
    });
  }
}

export function* watchAuth() {
  yield takeLatest('LOGIN_REQUEST', login);
  yield takeLatest('LOGOUT_REQUEST', logout);
  yield takeLatest('REFRESH_TOKEN_REQUEST', refresh);
  yield takeLatest('CHECK_SESSION_STATUS_REQUEST', checkSessionStatus);
  yield takeLatest("RESET_PASSWORD_REQUEST", resetPassword);
  yield takeLatest("MASSIVE_RESET_PASSWORD_REQUEST", resetMassivePassword);
}