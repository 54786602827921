import axios from 'axios';
import { store } from '../redux/store';
import { addRefreshSubscriber, getIsRefreshing, setIsRefreshing } from './tokenRefreshManager';

const axiosInstanceDownload = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstanceDownload.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (!getIsRefreshing()) {
        setIsRefreshing(true);
        store.dispatch({ type: 'REFRESH_TOKEN_REQUEST' });
      }
      return new Promise((resolve, reject) => {
        addRefreshSubscriber((newToken) => {
          if (newToken) {
            originalRequest._retry = true;
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            resolve(axiosInstanceDownload(originalRequest));
          } else {
            reject(error);
          }
        });
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstanceDownload;
