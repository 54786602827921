import { connect } from "react-redux";
import { Nav, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";

import { Show } from "../../hooks/Show";
import ReschedulingByIds from "./ReschedulingByIds";
import Topbar from '../../components/Navigation/Topbar';
import Sidebar from '../../components/Navigation/Sidebar';
import UploadMasivReschedule from "./UploadMasivReschedule";
import ReschedulingTableComponent from "./ReschedulingTableComponent";
import { noveltyManagementList } from "../../utils/enumsUtil";

const Rescheduling = ({
  novelties,
  reduxGetCountByNovelty,
  reduxResetCountByNovelty,
  reduxGetNovelties,
  reduxResetAddressRescheduling,
}) => {
  const [title, setTitle] = useState();
  const [subTitle, setSubTitle] = useState();
  const [iconUploadMasiv, setIconUploadMasiv] = useState();
  const [defaultActiveTab, setDefaultActiveTab] = useState("managedByKiki");

  useEffect(() => {
    reduxGetNovelties({
      page: 1,
      search: "",
      offset: 1000,
      status: 11,
    });

    setTitle("Reprogramación");
    setSubTitle("Módulo de Reprogramaciones");

    return () => {
      reduxResetCountByNovelty();
    };
  }, [])

  useEffect(() => {
    if (novelties && Object.keys(novelties).length > 0 && defaultActiveTab != 'masiv') {
      reduxGetCountByNovelty({ listNovelties: ListReschedulingsByTypeNovelty(defaultActiveTab) });
    }
  }, [defaultActiveTab, novelties])

  const handleSelect = (tabSelected) => {
    if (tabSelected !== defaultActiveTab) {
      reduxResetAddressRescheduling();
      reduxResetCountByNovelty();
      setDefaultActiveTab(tabSelected);
    }

    if (tabSelected === 'single') {
      setTitle("Reprogramación");
      setSubTitle("Módulo de Reprogramaciones");
      setIconUploadMasiv(false)
    } else if (tabSelected === 'masiv') {
      setTitle("Reprogramaciones masivas");
      setSubTitle("Módulo de Reprogramaciones masivas");
      setIconUploadMasiv(true)
    }
  }

  const ListReschedulingsByTypeNovelty = (currentTab) => {
    const noveltyList = novelties && Object.keys(novelties).length > 0 &&
      novelties
        .filter((f) => f.isActive === true && (currentTab === "single" || noveltyManagementList[currentTab].some(n => n.code === f.code)))
        .map((ele, _key) => ({
          value: ele.code,
          label: ele.description,
          id: ele.idNovelty,
        }))

    const idNoveltyList = noveltyList.map(n => n.id);

    return idNoveltyList.join(",");
  }

  return (
    <div>
      <div id='wrapper'>
        <Sidebar />
        <div
          id='content-wrapper'
          className='d-flex flex-column'>
          <div id='content'>
            <Topbar />
            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center mb-1'>
                <h1 className='h3 mb-0 text-gray-800 mr-3'>{title}</h1>
                {(iconUploadMasiv && <UploadMasivReschedule />)}
              </div>
              <p className='mb-4'>{subTitle}</p>
              <Tab.Container defaultActiveKey={defaultActiveTab} mountOnEnter={true} onSelect={(k) => handleSelect(k)}>
                <Nav variant="tabs" className=" d-flex flex-nowrap flex-row mt-2" >
                  <Nav.Item className='d-flex flex-column'>
                    <Nav.Link eventKey="managedByKiki">
                      Gestionadas por Kiki
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className='d-flex flex-column'>
                    <Nav.Link eventKey="managedByCustomer">
                      Gestionadas por el cliente
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className='d-flex flex-column'>
                    <Nav.Link eventKey="automaticManagement">
                      Automaticas
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className='d-flex flex-column'>
                    <Nav.Link eventKey="incidents">
                      Incidencias
                    </Nav.Link>
                  </Nav.Item>
                  <Show when='feature:tab-rescheduling-masiv'>
                    <Nav.Item className='d-flex flex-column'>
                      <Nav.Link eventKey="masiv">
                        Masivas
                      </Nav.Link>
                    </Nav.Item>
                  </Show>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="managedByKiki">
                    <ReschedulingTableComponent currentTab="managedByKiki" />
                  </Tab.Pane>
                  <Tab.Pane eventKey="managedByCustomer">
                    <ReschedulingTableComponent currentTab="managedByCustomer" />
                  </Tab.Pane>
                  <Tab.Pane eventKey="automaticManagement">
                    <ReschedulingTableComponent currentTab="automaticManagement" />
                  </Tab.Pane>
                  <Tab.Pane eventKey="incidents">
                    <ReschedulingTableComponent currentTab="incidents" />
                  </Tab.Pane>
                  <Tab.Pane eventKey="single">
                    <ReschedulingTableComponent currentTab="single" />
                  </Tab.Pane>
                  <Show when='feature:tab-rescheduling-masiv'>
                    <Tab.Pane eventKey="masiv">
                      <ReschedulingByIds />
                    </Tab.Pane>
                  </Show>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
const mapStateToProps = (state) => {
  return {
    novelties: state.noveltyState.novelties,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetCountByNovelty: (payload) =>
      dispatch({
        type: 'FETCH_COUNT_BY_NOVELTY_RESCHEDULING',
        value: payload,
      }),
    reduxResetCountByNovelty: () =>
      dispatch({
        type: 'RESET_TOTAL_BY_NOVELTY_RESCHEDULING',
      }),
    reduxGetNovelties: (payload) =>
      dispatch({
        type: 'FETCH_NOVELTIES_FOR_RESCHEDULE_REQUEST',
        value: payload,
      }),
    reduxResetAddressRescheduling: () =>
      dispatch({
        type: 'RESET_ADDRESSES_RESCHEDULING',
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rescheduling);