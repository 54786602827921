import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../api/request";

function* getOrigins(payload) {
  try {
    yield put({
      type: "FETCH_ORIGINS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/origins/getAll`;
    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_ORIGINS_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Origins",
      },
    });
    yield put({
      type: "FETCH_ORIGINS__ERROR",
    });
  }
}

function* saveOrigins(payload) {
  try {
    yield put({
      type: "FETCH_SAVE_ORIGINS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/origins`;
    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_SAVE_ORIGINS_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Origins",
      },
    });
    yield put({
      type: "FETCH_SAVE_ORIGINS_ERROR",
      value: error,
    });
  } finally {
    yield put({
      type: "FETCH_FINISH_SAVE_ORIGINS"
    });
  }
}

export function* watchOrigin() {
  yield takeLatest("FETCH_ORIGINS_REQUEST", getOrigins);
  yield takeLatest("FETCH_SAVE_ORIGINS_REQUEST", saveOrigins);
}
