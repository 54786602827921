const initialState = {
  isAuthenticated: false,
  session: {},
  permissions: [],
  requestingLogIn: false,
  successfulLogIn: false,
  errorLogIn: false,
  errorsLogOut: false,
  requestingLogOut: false,
  successfulLogOut: false,
  requestingResetPassword: false,
  successfulResetPassword: false,
  errorsResetPassword: false,
  requestingMassiveResetPassword: false,
  successfulMassiveResetPassword: false,
  errorsMassiveResetPassword: false,
}

export const getInitialState = () => JSON.parse(JSON.stringify(initialState));

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_REQUESTING':
      return {
        ...state,
        requestingLogIn: true,
        successfulLogIn: false,
        errorLogIn: false,
      };
    case 'LOGIN_SUCCESS':
      localStorage.setItem('token', action.payload.accessToken);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
      localStorage.setItem('userData', JSON.stringify(action.payload.user));
      return {
        ...state,
        requestingLogIn: false,
        successfulLogIn: true,
        errorLogIn: false,
        isAuthenticated: true,
        session: action.payload.user,
      };
    case 'LOGIN_ERROR':
      return {
        ...state,
        requestingLogIn: false,
        successfulLogIn: false,
        errorLogIn: true,
        isAuthenticated: false,
        session: {},
      };
    case 'LOGIN_RESET':
      return {
        ...state,
        requestingLogIn: false,
        successfulLogIn: false,
        errorLogIn: false,
      };
    case 'CHECK_SESSION_STATUS_SUCCESS':
      localStorage.setItem('userData', JSON.stringify(action.payload.user));
      return {
        ...state,
        isAuthenticated: true,
        session: action.payload.user,
      };
    case 'REFRESH_TOKEN_SUCCESS':
      localStorage.setItem('token', action.payload.accessToken);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
      return {
        ...state,
      };
    case 'REFRESH_TOKEN_ERROR':
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userData');
      return {
        ...state,
        isAuthenticated: false,
        session: {},
        permissions: [],
      };
    case "SET_TOKEN_VALIDATE":
      return {
        ...state,
        validateRefreshToken: action.value
      }
    case "RESET_PASSWORD_REQUESTING":
      return {
        ...state,
        requestingResetPassword: true,
        successfulResetPassword: false,
        errorsResetPassword: false,
      };
    case "RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        requestingResetPassword: false,
        successfulResetPassword: true,
        errorResetPassword: false,
      };
    case "RESET_PASSWORD_ERROR":
      return {
        ...state,
        requestingResetPassword: false,
        successfulResetPassword: false,
        errorResetPassword: true,
      };
    case "MASSIVE_RESET_PASSWORD_REQUESTING":
      return {
        ...state,
        requestingMassiveResetPassword: true,
        successfulMassiveResetPassword: false,
        errorsMassiveResetPassword: false,
      };
    case "MASSIVE_RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        requestingMassiveResetPassword: false,
        successfulMassiveResetPassword: true,
        errorMassiveResetPassword: false,
      };
    case "MASSIVE_RESET_PASSWORD_ERROR":
      return {
        ...state,
        requestingMassiveResetPassword: false,
        successfulMassiveResetPassword: false,
        errorMassiveResetPassword: true,
      };
    case "FLUSH_PASSWORD_RESET_REQUESTS":
      return {
        ...state,
        requestingResetPassword: false,
        successfulResetPassword: false,
        errorsResetPassword: false,
        requestingMassiveResetPassword: false,
        successfulMassiveResetPassword: false,
        errorsMassiveResetPassword: false,
      }
    case "LOGOUT_SUCCESS":
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userData');
      return {
        ...state,
        isAuthenticated: false,
        session: {},
        permissions: [],
      };
    case "DEFINE_PERMISSIONS":
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      return state;
  }
}

export default authReducer;
