let isRefreshing = false;
let refreshSubscribers = [];

export function onRefreshed(token) {
  refreshSubscribers.forEach(callback => callback(token));
  refreshSubscribers = [];
}

export function addRefreshSubscriber(callback) {
  refreshSubscribers.push(callback);
}

export function setIsRefreshing(value) {
  isRefreshing = value;
}

export function getIsRefreshing() {
  return isRefreshing;
}
