import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PermissionContext } from './PermissionContext';
import { isNullOrUndefined } from '../utils/functionsUtils';

const PrivateRoute = ({
  component: Component,
  when,
  whenMultiple = [],
  ...props
}) => {
  const { hasPermission } = useContext(PermissionContext);

  let isAllowed = true;

  const userLogged = !!localStorage.getItem("token");

  if (!isNullOrUndefined(when)) isAllowed = hasPermission(when);

  if (whenMultiple.length > 0) {
    whenMultiple.forEach(element => {
      if (!hasPermission(element)) {
        isAllowed = false;
        return;
      }
    });
  }

  if (!isAllowed)
    return (<Redirect to="/welcome" />)

  return (
    <Route {...props}
      render={(props) =>
        userLogged ? <Component {...props} /> : <Redirect to="/"
        />
      }
    />)

};

export default PrivateRoute;
