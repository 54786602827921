import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../api/request";

function* getAllProducts(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "FETCH_PRODUCTS_REQUESTING",
    });
   
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/products?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }${payload.value.idCompany ? '&idCompany=' + payload.value.idCompany : ''}`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_PRODUCT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load products",
      },
    });
    yield put({
      type: "FETCH_PRODUCT_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* postProduct(payload) {
  try {
    yield put({ type: "CREATE_PRODUCT_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/products`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);
    
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save product",
      },
    });

    yield put({
      type: "CREATE_PRODUCT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "CREATE_PRODUCT_ERROR",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save product",
      },
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getProductById(payload) {
  try {
    yield put({
      type: "READ_PRODUCT_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/products/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READ_PRODUCT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_PRODUCT_ERROR",
    });
  }
}

function* deleteProductById(payload) {
  try {
    yield put({
      type: "DELETE_PRODUCT_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/products/changeState/${payload.value.idProduct}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable product",
      },
    });

    yield put({
      type: "DELETE_PRODUCT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable product",
      },
    });
    yield put({
      type: "DELETE_PRODUCT_ERROR",
    });
  }
}

function* updateProduct(payload) {
  try {
    yield put({
      type: "UPDATE_PRODUCT_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/products/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update product",
      },
    });

    yield put({
      type: "UPDATE_PRODUCT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update product",
      },
    });
    yield put({
      type: "UPDATE_PRODUCT_ERROR",
    });
  }
}

function* deleteSoftProductById(payload) {
  try {
    yield put({
      type: "DELETE_SOFT_PRODUCT_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/products/deleteSoft/${payload.value.idProduct}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DELETE_SOFT_PRODUCT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });

    if (response.message) {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed delete soft",
          message: response.message,
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful delete soft",
          message: "Successful delete soft product",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed delete soft",
        message: "Failed delete soft product",
      },
    });
    yield put({
      type: "DELETE_SOFT_PRODUCT_ERROR",
    });
  }
}

function* downloadAllProducts(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "FETCH_DOWNLOAD_PRODUCTS_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/products/download`;
    const token = window.localStorage.getItem("token");
    const requestData = {
      search: decodeURIComponent(payload.value.search),
      isActive: payload.value.isActive,
      idCompany: payload.value.idCompany,
    };

    const response = yield call(fetch, requestURL, {
      method: "POST",
      body: JSON.stringify(requestData),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    });

    if (response.ok) {
      const blob = yield response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${new Date().toISOString().split('T')[0]}-products.csv`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      throw new Error();
    }

    yield put({
      type: "FETCH_DOWNLOAD_PRODUCT_SUCCESS",
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed download",
        message: "Failed download products",
      },
    });
    yield put({
      type: "FETCH_DOWNLOAD_PRODUCT_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

export function* watchProduct() {
  yield takeLatest("FETCH_PRODUCTS_REQUEST", getAllProducts);
  yield takeLatest("DOWNLOAD_PRODUCTS_REQUEST", downloadAllProducts);
  yield takeLatest("CREATE_PRODUCT_REQUEST", postProduct);
  yield takeLatest("READ_PRODUCT_REQUEST", getProductById);
  yield takeLatest("DELETE_PRODUCT_REQUEST", deleteProductById);
  yield takeLatest("UPDATE_PRODUCT_REQUEST", updateProduct);
  yield takeLatest("DELETE_SOFT_PRODUCT_REQUEST", deleteSoftProductById);
}
