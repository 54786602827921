import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../api/request";
import moment from "moment-timezone";

function* getAllRecordsReports(payload) {
  try {
    yield put({
      type: "FETCH_RECORDSREPORTS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_REPORT_URL}/api/v1/reports/records?`;
    if (payload.value.from) {
      requestURL += `from=${payload.value.from}`;
    }
    if (payload.value.to) {
      requestURL += requestURL.includes("=")
        ? `&to=${payload.value.to}`
        : `to=${payload.value.to}`;
    }
    if (payload.value.idCity) {
      requestURL += requestURL.includes("=")
        ? `&idCity=${payload.value.idCity}`
        : `idCity=${payload.value.idCity}`;
    }
    if (payload.value.state) {
      requestURL += requestURL.includes("=")
        ? `&state=${payload.value.state}`
        : `state=${payload.value.state}`;
    }
    if (payload.value.idRecord) {
      requestURL += requestURL.includes("=")
        ? `&idRecord=${payload.value.idRecord}`
        : `idRecord=${payload.value.idRecord}`;
    }
    if (payload.value.idUser) {
      requestURL += requestURL.includes("=")
        ? `&idUser=${payload.value.idUser}`
        : `idUser=${payload.value.idUser}`;
    }
    if (payload.value.idClient) {
      requestURL += requestURL.includes("=")
        ? `&idClient=${payload.value.idClient}`
        : `idClient=${payload.value.idClient}`;
    }
    if (payload.value.idOperator) {
      requestURL += requestURL.includes("=")
        ? `&idOperator=${payload.value.idOperator}`
        : `idOperator=${payload.value.idOperator}`;
    }
    if (payload.value.page) {
      requestURL += requestURL.includes("=")
        ? `&page=${payload.value.page}`
        : `page=${payload.value.page}`;
    }
    if (payload.value.offset) {
      requestURL += requestURL.includes("=")
        ? `&offset=${payload.value.offset}`
        : `offset=${payload.value.offset}`;
    }
    if (payload.value.search) {
      requestURL += requestURL.includes("=")
        ? `&search=${payload.value.search}`
        : `search=${payload.value.search}`;
    }
    if (payload.value.highPriority) {
      requestURL += requestURL.includes("=")
        ? `&highPriority=${payload.value.highPriority}`
        : `highPriority=${payload.value.highPriority}`;
    }

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_RECORDSREPORTS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load recordsreports",
      },
    });
    yield put({
      type: "FETCH_RECORDSREPORTS_ERROR",
    });
  }
}

function* getAllCollectsReports(payload) {
  try {
    yield put({
      type: "FETCH_COLLECTSREPORTS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/reports/collects?`;
    if (payload.value.idCustomer) {
      requestURL += `idCustomer=${payload.value.idCustomer}`;
    }
    if (payload.value.idOperator) {
      requestURL += requestURL.includes("=")
        ? `&idOperator=${payload.value.idOperator}`
        : `idOperator=${payload.value.idOperator}`;
    }
    if (payload.value.idCity) {
      requestURL += requestURL.includes("=")
        ? `&idCity=${payload.value.idCity}`
        : `idCity=${payload.value.idCity}`;
    }

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_RECORDSREPORTS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load recordsreports",
      },
    });
    yield put({
      type: "FETCH_RECORDSREPORTS_ERROR",
    });
  }
}

function* getAllSessionsReports(payload) {
  try {
    yield put({
      type: "FETCH_SESSIONSREPORTS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/reports/sessions?`;
    if (payload.value.date) {
      requestURL += `date=${payload.value.date}`;
    }
    if (payload.value.idCity) {
      requestURL += requestURL.includes("=")
        ? `&idCity=${payload.value.idCity}`
        : `idCity=${payload.value.idCity}`;
    }
    if (payload.value.page) {
      requestURL += requestURL.includes("=")
        ? `&page=${payload.value.page}`
        : `page=${payload.value.page}`;
    }
    if (payload.value.offset) {
      requestURL += requestURL.includes("=")
        ? `&offset=${payload.value.offset}`
        : `offset=${payload.value.offset}`;
    }
    if (payload.value.search) {
      requestURL += requestURL.includes("=")
        ? `&search=${payload.value.search}`
        : `search=${payload.value.search}`;
    }

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_SESSIONSREPORTS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load sessionsreports",
      },
    });
    yield put({
      type: "FETCH_SESSIONSREPORTS_ERROR",
    });
  }
}

function* getConfirmReport(payload) {
  try {
    yield put({
      type: "FETCH_CONFIRMREPORT_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/reports/confirm?page=${payload.value.page}&offset=${payload.value.offset}&search=${payload.value.search}`;
    if (payload.value.from && payload.value.from !== "") { requestURL += `&from=${payload.value.from}`; }
    if (payload.value.to && payload.value.to !== "") { requestURL += `&to=${payload.value.to}`; }
    if (payload.value.senderIds) { requestURL += `&senderIds=${payload.value.senderIds}`; }
    if (payload.value.operatorIds) { requestURL += `&operatorIds=${payload.value.operatorIds}`; }
    if (payload.value.confirmType) { requestURL += `&confirmType=${payload.value.confirmType}`; }


    const response = yield call(request, requestURL.trim(), headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_CONFIRMREPORT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load confirm report",
      },
    });
    yield put({
      type: "FETCH_CONFIRMREPORT_ERROR",
    });
  }
}

function* getShipperConfirmReport(payload) {
  try {
    yield put({
      type: "FETCH_SHIPPERCONFIRMREPORT_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/shippers/getConfirmReport?page=${payload.value.page}&offset=${payload.value.offset}&search=${payload.value.search}`;
    if (payload.value.from && payload.value.from !== "") { requestURL += `&from=${payload.value.from}`; }
    if (payload.value.to && payload.value.to !== "") { requestURL += `&to=${payload.value.to}`; }

    const response = yield call(request, requestURL.trim(), headers);

    yield put({
      type: "FETCH_SHIPPERCONFIRMREPORT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load confirm report",
      },
    });
    yield put({
      type: "FETCH_SHIPPERCONFIRMREPORT_ERROR",
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* downloadShipperConfirmReport(payload) {
  try {
    yield put({
      type: "DOWNLOAD_SHIPPERCONFIRMREPORT_REQUESTING",
    });

    const fileName = payload.value.fileName;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/shippers/downloadConfirmReport`;

    const response = yield call(fetch, requestURL.trim(), headers);
    const blob = yield call([response, 'blob']);

    const url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();

    yield put({
      type: "DOWNLOAD_SHIPPERCONFIRMREPORT_SUCCESS",
      payload: { url, fileName }
    });
  } catch (error) {
    console.log(error)
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed download",
        message: "Failed download confirm report",
      },
    });
    yield put({
      type: "DOWNLOAD_SHIPPERCONFIRMREPORT_ERROR",
    });
  }
}

function* getRecordsDetails(payload) {
  try {
    yield put({
      type: "FETCH_RECORDSDETAILS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/reports/detailAddressRecordById/${payload.value}`;

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_RECORDSDETAILS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "FETCH_RECORDSDETAILS_ERROR",
    });
  }
}

export function* watchReport() {
  yield takeLatest("FETCH_RECORDSREPORTS_REQUEST", getAllRecordsReports);
  yield takeLatest("FETCH_COLLECTSREPORTS_REQUEST", getAllCollectsReports);
  yield takeLatest("FETCH_SESSIONSREPORTS_REQUEST", getAllSessionsReports);
  yield takeLatest("FETCH_CONFIRMREPORT_REQUEST", getConfirmReport);
  yield takeLatest("FETCH_SHIPPERCONFIRMREPORT_REQUEST", getShipperConfirmReport);
  yield takeLatest("DOWNLOAD_SHIPPERCONFIRMREPORT_REQUEST", downloadShipperConfirmReport);
  yield takeLatest("FETCH_RECORDSDETAILS_REQUEST", getRecordsDetails);
}
