import React, { useEffect, useState } from "react";
import Routes from "./routes";
import { connect } from "react-redux";
import { AlertList } from "react-bs-notifier";
import Loader from "./components/Loader";

const App = ({
  alert,
  resetAlert,
  checkSessionStatus,
  showLoading
}) => {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      checkSessionStatus();
    }

    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
          console.log("Cache deleted:", name);
        });
      });
    }
  }, []);


  const onDismissed = React.useCallback((alert) => {
    setAlerts((alerts) => {
      const idx = alerts.indexOf(alert);
      if (idx < 0) return alerts;
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)];
    });
  }, []);

  const showAlert = (type, title, message) => {
    setAlerts((alerts) => [
      ...alerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: title,
        message: message,
      },
    ]);
  };

  useEffect(() => {
    if (alert.showAlert) {
      showAlert(alert.type, alert.title, alert.message);
      resetAlert();
    }
  }, [alert]);
  
  return (
    <>
      {showLoading && <Loader show={showLoading} />}
      <AlertList
        position="bottom-right"
        alerts={alerts}
        timeout={3000}
        dismissTitle="Cerrar"
        onDismiss={onDismissed}
      />
      <Routes />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    alert: state.alertState,
    showLoading: state.loadingState.show,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetAlert: () =>
      dispatch({
        type: "RESET_ALERT",
      }),
    checkSessionStatus: () =>
      dispatch({
        type: "CHECK_SESSION_STATUS_REQUEST",
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
