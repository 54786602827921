import { store } from '../redux/store';
import { addRefreshSubscriber, getIsRefreshing, setIsRefreshing } from './tokenRefreshManager';

const authExcludedRegex = /\/auth$/i;

export default async function request(url, options) {
  let response = await fetch(url, options);

  if (response.status === 401 && !authExcludedRegex.test(url)) {
    if (!getIsRefreshing()) {
      setIsRefreshing(true);
      store.dispatch({ type: 'REFRESH_TOKEN_REQUEST' });
    }

    return new Promise((resolve, reject) => {
      addRefreshSubscriber(async (newToken) => {
        if (newToken) {
          const retryOptions = {
            ...options,
            headers: {
              ...options.headers,
              Authorization: `Bearer ${newToken}`,
            },
          };
          const retryResponse = await fetch(url, retryOptions);
          resolve(retryResponse.json());
        } else {
          reject(new Error('No refresh token received'));
        }
      });
    });
  }
  return checkStatus(response, url);
}

async function checkStatus(response, requestUrl) {
  if (response.status >= 200 && response.status < 300) {
    // Si es 204 No Content
    if (response.status === 204 || response.status === 205) {
      return null;
    }
    return response.json();
  }

  if (response.status === 401 && requestUrl.includes('/auth/login')) {
    // Si es login, mostrar mensaje específico
    const errorData = await response.json();
    const error = new Error(errorData?.message || 'Invalid username or password');
    throw error;
  }

  if (response.status === 422) {
    const data = await response.json();
    const error = new Error('Validation error');
    error.data = data;
    throw error;
  }

  if (response.status === 404) {
    throw new Error('Resource not found (404)');
  }

  const errorData = await response.json().catch(() => ({}));
  const error = new Error(errorData?.message || `Unexpected error (HTTP ${response.status})`);
  error.data = errorData;
  throw error;
}