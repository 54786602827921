import React, { useState, useRef, useEffect, useContext } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { DateTime, IANAZone } from 'luxon';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import formatDateWithCustomFormat, { formatDateWithoutTimeZone } from '../../../utils/formatDate';

import GoogleMapsAutocomplete from '../../GoogleMapsAutocomplete';
import { getBusinessDate, getDaysDiff, isBusinessDay, isNullOrUndefined } from '../../../utils/functionsUtils';

const ReschedulingSolutionModal = ({
    show,
    onHide,
    onEscapeKeyDown,
    rowEdited,
    reduxPatchReschedule,
    countries,
    departmentsByCountry,
    citiesByDepartment,
    neighborhoodsByCity,
    valueManualAddressProp,
    currentTab,
    reduxGetDepartmentsByCountry,
    reduxGetCitiesByDepartment,
    reduxGetNeighborhoodsByCity,
}) => {

    const modalForm = useForm({
        defaultValues: {
            rescheduleResolution: { value: null },
            dateReschedule: null,
            postalCode: null,
            streetType: null,
            internalNumber: null,
            externalNumber: null,
            manualAddress: null,
            phone: null,
            country: null,
            countryISO: null,
            department: null,
            city2: null,
            neighborhood: null,
            observations: null,
            collection: null,
        }
    });

    const countryISOCode = modalForm.watch('countryISO', null);
    const country = modalForm.watch('country', '');
    const rescheduleResolution = modalForm.watch('rescheduleResolution', { value: null });

    const [valueManualAddress, setValueManualAddress] = useState(valueManualAddressProp);

    const accessToDateRescheduleField = ['CORRECT_ADDRESS', 'OFFER_AGAIN', 'CEDIS_PICKUP', 'ALREADY_DELIVERED'].includes(rescheduleResolution.code);
    const accessToPostalCodeField = ['CORRECT_ADDRESS'].includes(rescheduleResolution.code);
    const accessToStreetTypeField = ['CORRECT_ADDRESS'].includes(rescheduleResolution.code);
    const accessToInternalNumberField = ['CORRECT_ADDRESS'].includes(rescheduleResolution.code);
    const accessToExternalNumberField = ['CORRECT_ADDRESS'].includes(rescheduleResolution.code);
    const accessToNeighborhoodField = ['CORRECT_ADDRESS'].includes(rescheduleResolution.code);
    const accessToPhoneField = ['CORRECT_ADDRESS', 'OFFER_AGAIN', 'CEDIS_PICKUP', 'ALREADY_DELIVERED'].includes(rescheduleResolution.code);
    const accessToObservationsField = ['TOTAL_RETURN', 'CORRECT_ADDRESS', 'OFFER_AGAIN', 'CEDIS_PICKUP', 'ALREADY_DELIVERED'].includes(rescheduleResolution.code);

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto" }),
        singleValue: (baseStyles) => ({ ...baseStyles, width: "100%" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" })
    };

    const handleClose = () => {
        modalForm.reset();
        onHide();
    };

    useEffect(() => {
        if (isNullOrUndefined(rowEdited))
            return;

        modalForm.setValue('collection', rowEdited.collection);

        const country = countries && countries.items?.find(c => c.description.toUpperCase() === rowEdited.countryNameReceiver.toUpperCase());
        if (!country) return;

        modalForm.setValue('country', { value: country.idCountry, label: country.description })
        modalForm.setValue('countryISO', country.isoCode);

        reduxGetDepartmentsByCountry({
            idCountry: Number(country.idCountry),
        });
    }, [rowEdited])

    useEffect(() => {
        if (!departmentsByCountry?.length > 0)
            return;

        const department = departmentsByCountry && departmentsByCountry.find(d => d.description.toUpperCase() === rowEdited.stateNameReceiver.toUpperCase());
        if (!department) return;

        modalForm.setValue('department', { value: department.idDepartment, label: department.description })
        reduxGetCitiesByDepartment({
            idDepartment: Number(department.idDepartment),
        });
    }, [departmentsByCountry]);

    useEffect(() => {
        if (!citiesByDepartment?.length > 0)
            return;

        const city = citiesByDepartment && citiesByDepartment.find(e => e.description.toUpperCase() === rowEdited.cityNameReceiver.toUpperCase());
        if (!city) return;

        modalForm.setValue('city2', { value: city.idCity, label: city.description });
        reduxGetNeighborhoodsByCity({
            idCity: Number(city.idCity),
        });
    }, [citiesByDepartment]);

    useEffect(() => {
        if (!neighborhoodsByCity?.length > 0)
            return;

        const neighborhood = neighborhoodsByCity && neighborhoodsByCity.find(e => e.description.toUpperCase() === rowEdited.neighborhoodNameReceiver.toUpperCase());
        if (!neighborhood) return;

        modalForm.setValue('neighborhood', {
            value: neighborhood.idNeighborhood,
            label: `${neighborhood.postalCode ? neighborhood.postalCode + ' - ' : ''}${neighborhood.description}`
        });
    }, [neighborhoodsByCity]);


    const rescheduleResolutionEnabled = () => {
        switch (currentTab) {
            case "managedByKiki":
                return [
                    { value: 1, label: 'Devolución Total', code: 'TOTAL_RETURN' },
                    { value: 2, label: 'Corregir Dirección', code: 'CORRECT_ADDRESS' },
                    { value: 4, label: 'Validación Recogida en CEDIS', code: 'CEDIS_PICKUP' },
                    { value: 5, label: 'Ya Entregado', code: 'ALREADY_DELIVERED' },
                ]
            case "managedByCustomer":
                return [
                    { value: 1, label: 'Devolución Total', code: 'TOTAL_RETURN' },
                    { value: 2, label: 'Corregir Dirección', code: 'CORRECT_ADDRESS' },
                    { value: 3, label: 'Volver a Ofrecer', code: 'OFFER_AGAIN' },
                    { value: 4, label: 'Validación Recogida en CEDIS', code: 'CEDIS_PICKUP' },
                    { value: 5, label: 'Ya Entregado', code: 'ALREADY_DELIVERED' },
                ]
            case "automaticManagement":
                return [
                    { value: 1, label: 'Devolución Total', code: 'TOTAL_RETURN' },
                    { value: 2, label: 'Corregir Dirección', code: 'CORRECT_ADDRESS' },
                    { value: 4, label: 'Validación Recogida en CEDIS', code: 'CEDIS_PICKUP' },
                    { value: 5, label: 'Ya Entregado', code: 'ALREADY_DELIVERED' },
                ]
            case "incidents":
                return [
                    { value: 5, label: 'Ya Entregado', code: 'ALREADY_DELIVERED' },
                ]

            default:
                return []
        }
    }

    const onSubmitReschedule = (data) => {
        const obj = {
            id: rowEdited.idAddress,
            address: !isNullOrUndefined(data.manualAddress?.trim()) ? data.manualAddress : rowEdited.address,
            countryNameReceiver: data.country ? data.country.label : rowEdited.countryNameReceiver,
            stateNameReceiver: data.department ? data.department.label : rowEdited.stateNameReceiver,
            cityNameReceiver: data.city2 ? data.city2.label : rowEdited.cityNameReceiver,
            neighborhoodNameReceiver: data.neighborhood ? data.neighborhood.label : rowEdited.neighborhoodNameReceiver,
            idCity: data.city2 ? Number(data.city2.value) : rowEdited.idCity,
            idNeighborhood: data.neighborhood ? Number(data.neighborhood.value) : rowEdited.idNeighborhood,
            date: formatDateWithCustomFormat(DateTime.now().setZone(new IANAZone('America/Bogota')), { format: 'YYYY-MM-DD' }) + ' 00:00:01',
            dateReschedule: data.dateReschedule ? formatDateWithoutTimeZone(data.dateReschedule) : null,
            observations: data.observations ?? null,
            reference1: data.phone ? String(data.phone) : rowEdited.reference1,
            collection: data.collection ? data.collection : rowEdited.collection,
            rescheduleResolution: data.rescheduleResolution.label && data.rescheduleResolution.label,
        };

        reduxPatchReschedule(obj);
    };

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
                size='lg'>
                <FormProvider {...modalForm}>
                    <form onSubmit={modalForm.handleSubmit(onSubmitReschedule)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Actualización y reprogramación</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='row'>
                                <div className={`form-group col-md-6`}>
                                    <label
                                        htmlFor='rescheduleResolution'
                                        className='form-label'>
                                        Solución*
                                    </label>
                                    <Controller
                                        control={modalForm.control}
                                        name='rescheduleResolution'
                                        rules={{ required: 'El campo solución es obligatorio' }}
                                        render={({ field }) => (
                                            <>
                                                <Select
                                                    {...field}
                                                    styles={customStyles}
                                                    options={rescheduleResolutionEnabled()}
                                                />
                                                {modalForm.formState.errors.rescheduleResolution &&
                                                    <span className='error-message'>{modalForm.formState.errors.rescheduleResolution.message}</span>
                                                }
                                            </>
                                        )}
                                    />
                                </div>
                                {accessToDateRescheduleField && (
                                    <div className='form-group col-md-6'>
                                        <label
                                            htmlFor='dateReschedule'
                                            className='form-label'>
                                            Lapso de Tiempo
                                        </label>
                                        <Controller
                                            control={modalForm.control}
                                            name='dateReschedule'
                                            rules={{ required: accessToDateRescheduleField ? 'El campo de lapso de tiempo es obligatorio' : false }}
                                            render={({ field: { onChange, value } }) => (
                                                <>
                                                    <DatePicker
                                                        selected={value}
                                                        onChange={onChange}
                                                        dateFormat={'yyyy/MM/dd'}
                                                        className={`form-control`}
                                                        filterDate={(date) => isBusinessDay(date, countryISOCode)}
                                                        maxDate={getBusinessDate(new Date(), 7, countryISOCode)}
                                                        minDate={getBusinessDate(new Date(), 2, countryISOCode)}
                                                    />
                                                    {modalForm.formState.errors.dateReschedule && (
                                                        <span className='error-message'>{modalForm.formState.errors.dateReschedule.message}</span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                )}
                                {accessToPostalCodeField && (
                                    <div className={`form-group col-md-6`}>
                                        <label
                                            htmlFor='postalCode'
                                            className='form-label'>
                                            Código Postal*
                                        </label>
                                        <Controller
                                            control={modalForm.control}
                                            name='postalCode'
                                            rules={{ required: accessToPostalCodeField ? 'El campo código postal es obligatorio' : false }}
                                            render={({ field }) => (
                                                <>
                                                    <input
                                                        {...field}
                                                        type='text'
                                                        className={`form-control form-control-user`}
                                                    />
                                                    {modalForm.formState.errors.postalCode && (
                                                        <span className='error-message'>{modalForm.formState.errors.postalCode.message}</span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                )}
                                {accessToStreetTypeField && (
                                    <div className={`form-group col-md-6`}>
                                        <label
                                            htmlFor='streetType'
                                            className='form-label'>
                                            Tipo de Vía*
                                        </label>
                                        <Controller
                                            control={modalForm.control}
                                            name='streetType'
                                            rules={{ required: accessToStreetTypeField ? 'El campo tipo de vía es obligatorio' : false }}
                                            render={({ field }) => (
                                                <>
                                                    <input
                                                        {...field}
                                                        type='text'
                                                        className={`form-control form-control-user`}
                                                    />
                                                    {modalForm.formState.errors.streetType && (
                                                        <span className='error-message'>{modalForm.formState.errors.streetType.message}</span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                )}
                                {accessToInternalNumberField && (
                                    <div className={`form-group col-md-6 `}>
                                        <label
                                            htmlFor='internalNumber'
                                            className='form-label'>
                                            Número Interno*
                                        </label>
                                        <Controller
                                            control={modalForm.control}
                                            name='internalNumber'
                                            rules={{ required: accessToInternalNumberField ? 'El campo número interno es obligatorio' : false }}
                                            render={({ field }) => (
                                                <>
                                                    <input
                                                        {...field}
                                                        type='text'
                                                        className={`form-control form-control-user`}
                                                    />
                                                    {modalForm.formState.errors.internalNumber && (
                                                        <span className='error-message'>{modalForm.formState.errors.internalNumber.message}</span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                )}
                                {accessToExternalNumberField && (
                                    <div className={`form-group col-md-6`}>
                                        <label
                                            htmlFor='externalNumber'
                                            className='form-label'>
                                            Número Externo*
                                        </label>
                                        <Controller
                                            control={modalForm.control}
                                            name='externalNumber'
                                            rules={{ required: accessToExternalNumberField ? 'El campo número externo es obligatorio' : false }}
                                            render={({ field }) => (
                                                <>
                                                    <input
                                                        {...field}
                                                        type='text'
                                                        className={`form-control form-control-user`}
                                                    />
                                                    {modalForm.formState.errors.externalNumber && (
                                                        <span className='error-message'>{modalForm.formState.errors.externalNumber.message}</span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                )}
                                {false && (
                                    <>
                                        <div className='form-group col-md-12'>
                                            <GoogleMapsAutocomplete
                                                id={'manualAddress'}
                                                required={(valueManualAddress && valueManualAddress !== '' && rescheduleResolution.value === 2)}
                                                value={valueManualAddress}
                                                onChange={(data) => {
                                                    setValueManualAddress(
                                                        data.address_components
                                                            .map((ad) => {
                                                                return ad.long_name;
                                                            })
                                                            .join(', ')
                                                    );
                                                    data.address_components ? modalForm.setValue('manualAddress', data.address_components) : modalForm.setValue('manualAddress', data);
                                                }}
                                            />
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label
                                                htmlFor='country'
                                                className='form-label'>
                                                Pais
                                            </label>
                                            <Controller
                                                control={modalForm.control}
                                                name='country'
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        isClearable
                                                        styles={customStyles}
                                                        options={countries &&
                                                            Object.keys(countries).length > 0 ?
                                                            countries.items.map((ele, key) => ({
                                                                value: ele.idCountry,
                                                                label: ele.description,
                                                            }
                                                            ))
                                                            : []
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className='form-group col-md-6'>
                                            <label
                                                htmlFor='department'
                                                className='form-label'>
                                                Departamento/Estado{!isNullOrUndefined(country) && rescheduleResolution.value === 2 && '*'}
                                            </label>
                                            <Controller
                                                control={modalForm.control}
                                                name='department'
                                                rules={{
                                                    required: false
                                                }}
                                                render={({ field }) => (
                                                    <>
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            styles={customStyles}
                                                            options={departmentsByCountry &&
                                                                Object.keys(departmentsByCountry).length > 0 ?
                                                                departmentsByCountry.map((ele, key) => ({
                                                                    value: ele.idDepartment,
                                                                    label: ele.description,
                                                                }))
                                                                : []
                                                            }
                                                        />
                                                        {modalForm.formState.errors.department &&
                                                            <span className='error-message'>{modalForm.formState.errors.department.message}</span>
                                                        }
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label
                                                htmlFor='city2'
                                                className='form-label'>
                                                Ciudad/Municipio/Provincia{!isNullOrUndefined(country) && rescheduleResolution.value === 2 && '*'}
                                            </label>
                                            <Controller
                                                control={modalForm.control}
                                                name='city2'
                                                rules={{
                                                    required: false
                                                }}
                                                render={({ field }) => (
                                                    <>
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            styles={customStyles}
                                                            options={citiesByDepartment &&
                                                                Object.keys(citiesByDepartment).length > 0 ?
                                                                citiesByDepartment.map((ele, key) => ({
                                                                    value: ele.idCity,
                                                                    label: ele.description,
                                                                }))
                                                                : []
                                                            }
                                                        />
                                                        {modalForm.formState.errors.city2 &&
                                                            <span className='error-message'>{modalForm.formState.errors.city2.message}</span>
                                                        }
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </>
                                )}

                                {accessToNeighborhoodField && (
                                    <div className={`form-group col-md-6`}>
                                        <label
                                            htmlFor='neighborhood'
                                            className='form-label'>
                                            Barrio/Colonia/Distrito*
                                        </label>
                                        <Controller
                                            control={modalForm.control}
                                            name='neighborhood'
                                            rules={{ required: accessToNeighborhoodField ? 'El campo barrio/colonia/distrito es obligatorio' : false }}
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        isClearable
                                                        styles={customStyles}
                                                        getOptionValue={(option) => option["label"]}
                                                        options={neighborhoodsByCity &&
                                                            Object.keys(neighborhoodsByCity).length > 0 ?
                                                            neighborhoodsByCity.map((ele, key) => ({
                                                                value: ele.idNeighborhood,
                                                                label: `${ele.postalCode ? ele.postalCode + ' - ' : ''}${ele.description}`,
                                                            }))
                                                            : []
                                                        }
                                                    />
                                                    {modalForm.formState.errors.neighborhood && (
                                                        <span className='error-message'>{modalForm.formState.errors.neighborhood.message}</span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                )}
                                {accessToPhoneField && (
                                    <div className='form-group col-md-6'>
                                        <label
                                            htmlFor='phone'
                                            className='form-label'>
                                            Teléfono*
                                        </label>
                                        <Controller
                                            control={modalForm.control}
                                            name='phone'
                                            rules={{ required: accessToPhoneField ? 'El campo teléfono es obligatorio' : false }}
                                            render={({ field }) => (
                                                <>
                                                    <input
                                                        {...field}
                                                        type='number'
                                                        className={`form-control`}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    {modalForm.formState.errors.phone && (
                                                        <span className='error-message'>{modalForm.formState.errors.phone.message}</span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className='row'>
                                {accessToObservationsField && (
                                    <div className='form-group col-md-12'>
                                        <label
                                            htmlFor='observations'
                                            className='form-label'>
                                            Observación*
                                        </label>
                                        <Controller
                                            control={modalForm.control}
                                            name='observations'
                                            rules={{
                                                required: accessToObservationsField ? 'El campo observaciones es obligatorio' : false,
                                                minLength: accessToObservationsField
                                                    ? { value: 35, message: 'El campo observaciones debe tener al menos 35 caracteres' }
                                                    : undefined
                                            }}

                                            render={({ field }) => (
                                                <>
                                                    <input
                                                        {...field}
                                                        type='text'
                                                        className={`form-control form-control-user`}
                                                    />
                                                    {modalForm.formState.errors.observations && (
                                                        <span className='error-message'>{modalForm.formState.errors.observations.message}</span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant='secondary'
                                onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button
                                variant='primary'
                                type='submit'>
                                Guardar
                            </Button>
                        </Modal.Footer>
                    </form>
                </FormProvider>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        addresses: state.addressState.reschedulings,
        clients: state.companyState.clients,
        logisticsOperators: state.companyState.logisticsOperators,
        rowEdited: state.addressState.rowEdited,
        reschedule: state.addressState.reschedule,
        cities: state.cityState.cities,
        neighborhoodsByCity: state.neighborhoodState.neighborhoodsByCity,
        countries: state.countryState.countries,
        departmentsByCountry: state.departmentState.departmentsByCountry,
        citiesByDepartment: state.cityState.citiesByDepartment,
        novelties: state.noveltyState.novelties,
        reschedulingsByNovelty: state.addressState.reschedulingsByNovelty,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetClients: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
                value: payload,
            }),
        reduxGetLogisticsOperators: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST',
                value: payload,
            }),
        reduxGetAddresses: (payload) =>
            dispatch({
                type: 'FETCH_ADDRESSES_RESCHEDULING_REQUEST',
                value: payload,
            }),
        reduxGetCountByNovelty: (payload) =>
            dispatch({
                type: 'FETCH_COUNT_BY_NOVELTY_RESCHEDULING',
                value: payload,
            }),
        reduxUploadReschedulesToDrive: (payload) =>
            dispatch({
                type: 'FETCH_RESCHEDULING_UPLOAD_DRIVE_REQUEST',
                value: payload,
            }),
        reduxResetAddressForm: () =>
            dispatch({
                type: 'RESET_ADDRESS_FORM',
            }),
        reduxPatchReschedule: (payload) =>
            dispatch({
                type: 'FETCH_ADDRESS_RESCHEDULE_REQUEST',
                value: payload,
            }),
        reduxGetAddress: (payload) =>
            dispatch({
                type: 'READ_ADDRESS_REQUEST',
                value: payload,
            }),
        reduxGetNovelties: (payload) =>
            dispatch({
                type: 'FETCH_NOVELTIES_FOR_RESCHEDULE_REQUEST',
                value: payload,
            }),
        reduxGetCities: (payload) =>
            dispatch({
                type: 'FETCH_CITIES_REQUEST',
                value: payload,
            }),
        reduxGetCountries: (payload) =>
            dispatch({
                type: 'FETCH_COUNTRIES_REQUEST',
                value: payload,
            }),
        reduxGetDepartmentsByCountry: (payload) =>
            dispatch({
                type: 'READBYCOUNTRY_DEPARTMENT_REQUEST',
                value: payload,
            }),
        reduxGetCitiesByDepartment: (payload) =>
            dispatch({
                type: 'READBYDEPARTMENT_CITY_REQUEST',
                value: payload,
            }),
        reduxGetNeighborhoodsByCity: (payload) =>
            dispatch({
                type: 'READBYCITY_NEIGHBORHOOD_REQUEST',
                value: payload,
            }),
        reduxResetDepartment: (payload) =>
            dispatch({
                type: 'RESET_BYCOUNTRY_DEPARTMENT',
                value: payload,
            }),
        reduxResetCity: (payload) =>
            dispatch({
                type: 'RESET_BYDEPARTMENT_CITY',
                value: payload,
            }),
        reduxResetNeighborhood: (payload) =>
            dispatch({
                type: 'RESET_BYCITY_NEIGHBORHOOD',
                value: payload,
            }),
        reduxResetAddressReschedulingForm: (payload) =>
            dispatch({
                type: 'RESET_ADDRESS_RESCHEDULING_FORM',
                value: payload,
            }),
        reduxResetCountByNovelty: () =>
            dispatch({
                type: 'RESET_TOTAL_BY_NOVELTY_RESCHEDULING',
            }),
        reduxGetDepartmentsByCountry: (payload) =>
            dispatch({
                type: 'READBYCOUNTRY_DEPARTMENT_REQUEST',
                value: payload,
            }),
        reduxGetCitiesByDepartment: (payload) =>
            dispatch({
                type: 'READBYDEPARTMENT_CITY_REQUEST',
                value: payload,
            }),
        reduxGetNeighborhoodsByCity: (payload) =>
            dispatch({
                type: 'READBYCITY_NEIGHBORHOOD_REQUEST',
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReschedulingSolutionModal);
