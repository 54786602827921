import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../api/request";

function* getAllOrderDetails(payload) {
  try {
    yield put({
      type: "FETCH_ORDERDETAILS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/orderDetails?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_ORDERDETAIL_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load order detail",
      },
    });
    yield put({
      type: "FETCH_ORDERDETAIL_ERROR",
    });
  }
}

function* postOrderDetail(payload) {
  try {
    yield put({ type: "CREATE_ORDERDETAIL_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/orderDetails`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save order detail",
      },
    });

    yield put({
      type: "CREATE_ORDERDETAIL_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save order detail",
      },
    });
    yield put({
      type: "CREATE_ORDERDETAIL_ERROR",
    });
  }
}

function* getOrderDetailById(payload) {
  try {
    yield put({
      type: "READ_ORDERDETAIL_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/orderDetails/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READ_ORDERDETAIL_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_ORDERDETAIL_ERROR",
    });
  }
}

function* deleteOrderDetailById(payload) {
  try {
    yield put({
      type: "DELETE_ORDERDETAIL_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/orderDetails/changeState/${payload.value.idHeadquarters}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable order detail",
      },
    });

    yield put({
      type: "DELETE_ORDERDETAIL_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable order detail",
      },
    });
    yield put({
      type: "DELETE_ORDERDETAIL_ERROR",
    });
  }
}

function* updateOrderDetail(payload) {
  try {
    yield put({
      type: "UPDATE_ORDERDETAIL_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/orderDetails/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update order detail",
      },
    });

    yield put({
      type: "UPDATE_ORDERDETAIL_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update order detail",
      },
    });
    yield put({
      type: "UPDATE_ORDERDETAIL_ERROR",
    });
  }
}

function* getOrderDetailByIdOrder(payload) {
  try {
    yield put({
      type: "READBYORDER_ORDERDETAIL_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/orderDetails/getAllByOrder/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READBYORDER_ORDERDETAIL_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READBYORDER_ORDERDETAIL_ERROR",
    });
  }
}

export function* watchOrderDetail() {
  yield takeLatest("FETCH_ORDERDETAILS_REQUEST", getAllOrderDetails);
  yield takeLatest("CREATE_ORDERDETAIL_REQUEST", postOrderDetail);
  yield takeLatest("READ_ORDERDETAIL_REQUEST", getOrderDetailById);
  yield takeLatest("DELETE_ORDERDETAIL_REQUEST", deleteOrderDetailById);
  yield takeLatest("UPDATE_ORDERDETAIL_REQUEST", updateOrderDetail);
  yield takeLatest("READBYORDER_ORDERDETAIL_REQUEST", getOrderDetailByIdOrder);
}
