import React, { useState, useEffect, createContext } from "react";

export const AuthContext = createContext();

const sessionSelector = (state) => state.authState?.session ?? null;

export const AuthProvider = ({ store, children }) => {
    const [session, setSession] = useState(() => sessionSelector(store.getState()));

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            const newSession = sessionSelector(store.getState());
            setSession((prevSession) => (prevSession !== newSession ? newSession : prevSession));
        });

        return unsubscribe;
    }, [store]);

    return (
        <AuthContext.Provider value={{ session }}>
            {children}
        </AuthContext.Provider>
    );
};