import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../api/request";

function* getAllCustomers(payload) {
  try {
    yield put({
      type: "FETCH_CUSTOMERS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/customers?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_CUSTOMER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load customers",
      },
    });
    yield put({
      type: "FETCH_CUSTOMER_ERROR",
    });
  }
}

function* postCustomer(payload) {
  try {
    yield put({ type: "CREATE_CUSTOMER_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/customers`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save customer",
      },
    });

    yield put({
      type: "CREATE_CUSTOMER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save customer",
      },
    });
    yield put({
      type: "CREATE_CUSTOMER_ERROR",
    });
  }
}

function* getCustomerById(payload) {
  try {
    yield put({
      type: "READ_CUSTOMER_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/customers/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READ_CUSTOMER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_CUSTOMER_ERROR",
    });
  }
}

function* deleteCustomerById(payload) {
  try {
    yield put({
      type: "DELETE_CUSTOMER_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/customers/changeState/${payload.value.idCustomer}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable customer",
      },
    });

    yield put({
      type: "DELETE_CUSTOMER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable customer",
      },
    });
    yield put({
      type: "DELETE_CUSTOMER_ERROR",
    });
  }
}

function* updateCustomer(payload) {
  try {
    yield put({
      type: "UPDATE_CUSTOMER_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/customers/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update customer",
      },
    });

    yield put({
      type: "UPDATE_CUSTOMER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update customer",
      },
    });
    yield put({
      type: "UPDATE_CUSTOMER_ERROR",
    });
  }
}

function* deleteSoftCustomerById(payload) {
  try {
    yield put({
      type: "DELETE_SOFT_CUSTOMER_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/customers/deleteSoft/${payload.value.idCustomer}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DELETE_SOFT_CUSTOMER_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });

    if (response.message) {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed delete soft",
          message: response.message,
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful delete soft",
          message: "Successful delete soft customer",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed delete soft",
        message: "Failed delete soft customer",
      },
    });
    yield put({
      type: "DELETE_SOFT_CUSTOMER_ERROR",
    });
  }
}

export function* watchCustomer() {
  yield takeLatest("FETCH_CUSTOMERS_REQUEST", getAllCustomers);
  yield takeLatest("CREATE_CUSTOMER_REQUEST", postCustomer);
  yield takeLatest("READ_CUSTOMER_REQUEST", getCustomerById);
  yield takeLatest("DELETE_CUSTOMER_REQUEST", deleteCustomerById);
  yield takeLatest("UPDATE_CUSTOMER_REQUEST", updateCustomer);
  yield takeLatest("DELETE_SOFT_CUSTOMER_REQUEST", deleteSoftCustomerById);
}
