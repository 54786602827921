import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../api/request";

function* postRouteRecord(payload) {
  try {
    yield put({ type: "CREATE_ROUTE_RECORD_REQUESTING" });

    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/routeRecords`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "CREATE_ROUTE_RECORD_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save Route Record",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save Route Record",
      },
    });
    yield put({
      type: "CREATE_ROUTE_RECORD_ERROR",
    });
  }
}

export function* watchRouteRecord() {
  yield takeLatest("CREATE_ROUTE_RECORD_REQUEST", postRouteRecord);
}
