import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Progreso from "./Progreso";
import { ProgressDetail } from "./ProgressDetail";
import { CardWithContentPlaceholder } from "../../components/placeholders/CardWithContent";
import { FaInfoCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import io from "socket.io-client";
import Grid from "../../components/Grid"; // Asegúrate de importar tu componente Grid

const SOCKET_SERVER_URL = process.env.REACT_APP_ASSIGN_URL;

export const RecentsAssignaments = ({ activeTab, setRenderParentListProcessFlow, renderParentListProcessFlow }) => {
    const [statusProcess, setStatusProcess] = useState({
        total: 0,
        processed: 0,
        totalAssigned: 0,
        failuredAssignments: 0
    });
    const [detailView, setDetailView] = useState(false);
    const [detail, setDetail] = useState(null);
    const [data, setData] = useState([]);
    const [loadding, setLoadding] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const getData = useCallback(async () => {
        const token = window.localStorage.getItem("token");
        try {
            const response = await fetch(`${process.env.REACT_APP_ASSIGN_URL}/api/v1/assignOperator/getAllAssignmentParents`, {
                method: 'GET',
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            const sortedData = result.items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setData(sortedData);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        getData();
        return () => {
            setRenderParentListProcessFlow(false);
        };
    }, [renderParentListProcessFlow]);

    const handleDetail = (parent) => {
        setDetail(parent);
        setDetailView(!detailView);
    };

    useEffect(() => {
        const socket = io(SOCKET_SERVER_URL);

        socket.on('assignmentStatus', (data) => {
            setStatusProcess(data);
        });

        socket.on('notifiyWhenCanotReintent', () => {
            Swal.fire('No se permiten más intentos');
        });

        socket.on('assignmentStatus.finished', () => {
            getData();
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        const dataAdatpted = data.map((item) => {
            if (item.id === statusProcess.parentId) {
                item.assignedCount = statusProcess.totalAssigned;
                item.failureCount = statusProcess.failuredAssignments;
                item.processedCount = statusProcess.processed;
                item.total_data = statusProcess.total;
            }
            return item;
        });
        setData(dataAdatpted);
    }, [statusProcess]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(data.length / itemsPerPage);
    const paginatedData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const renderParents = () => {
        if (data.length > 0) {
            return paginatedData.map(parent => {
                if (loadding) {
                    return (
                        <CardWithContentPlaceholder key={parent.id} />
                    );
                }

                return (
                    <Progreso
                        key={parent.id}
                        fallidas={parent.failureCount}
                        total={parent.total_data}
                        procesadas={parent.processedCount}
                        asignadas={parent.assignedCount}
                        parent={parent}
                        nIntents={parent.n_intents}
                        operatorName={parent.operator_name}
                        handleDetail={() => handleDetail(parent)}
                    />
                );
            });
        }
        return null;
    };

    if (!detailView) {
        return (
            <>
                <Row>
                    <Col sm={4}>
                        <h4>Progreso <FaInfoCircle title="Información importante" /></h4>
                    </Col>
                    <Col sm={8} className="text-right">
                        <div>
                            <h6>Estados</h6>
                            <span className="badge badge-primary mr-2">En Proceso</span>
                            <span className="badge badge-success mr-2">Asignadas</span>
                            <span className="badge badge-danger mr-2">Fallidas</span>
                        </div>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={12}>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="dataTables_length">
                                Mostrar{" "}
                                <Form.Control
                                    as="select"
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                    className="custom-select custom-select-sm form-control form-control-sm d-inline w-auto"
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">100</option>
                                    <option value="100">500</option>
                                    <option value="120">1000</option>
                                </Form.Control>{" "}
                                Registros
                            </div>
                        </div>
                    </Col>
                </Row>
                <small>Listado de Procesos</small>
                <hr className="dropdown-divider" />
                {renderParents()}
                <Grid
                    cols={[]}  
                    data={paginatedData}
                    page={currentPage}
                    pages={totalPages}
                    onChangePage={handlePageChange}
                    total={data.length}
                    showRange={false} // Asegúrate de no mostrar el selector de rango
                    showFilters={false} // Asegúrate de no mostrar el campo de búsqueda
                />
            </>
        );
    }

    return (<ProgressDetail parent={detail} activeTab={activeTab} handleDetail={handleDetail} />);
};