import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../api/request";

function* getAllExternalMappings(payload) {
  try {
    yield put({
      type: "FETCH_EXTERNALMAPPINGS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/externalMappings?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }`;
    if (payload.value.mappingType) {
      requestURL += requestURL.includes("=")
        ? `&mappingType=${payload.value.mappingType}`
        : `mappingType=${payload.value.mappingType}`;
    }
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_EXTERNALMAPPING_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load external Mappings",
      },
    });
    yield put({
      type: "FETCH_EXTERNALMAPPING_ERROR",
    });
  }
}

function* postExternalMapping(payload) {
  try {
    yield put({ type: "CREATE_EXTERNALMAPPING_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/externalMappings`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });
    if (response.message) {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed save",
          message: response.message,
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful save",
          message: "Successful save external mapping",
        },
      });
    }
    yield put({
      type: "CREATE_EXTERNALMAPPING_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save external mapping",
      },
    });
    yield put({
      type: "CREATE_EXTERNALMAPPING_ERROR",
    });
  }
}

function* getExternalMappingById(payload) {
  try {
    yield put({
      type: "READ_EXTERNALMAPPING_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/externalMappings/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READ_EXTERNALMAPPING_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_EXTERNALMAPPING_ERROR",
    });
  }
}

function* deleteExternalMappingById(payload) {
  try {
    yield put({
      type: "DELETE_EXTERNALMAPPING_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/externalMappings/changeState/${payload.value.idExternalMapping}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable external mapping",
      },
    });

    yield put({
      type: "DELETE_EXTERNALMAPPING_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable external mapping",
      },
    });
    yield put({
      type: "DELETE_EXTERNALMAPPING_ERROR",
    });
  }
}

function* updateExternalMapping(payload) {
  try {
    yield put({
      type: "UPDATE_EXTERNALMAPPING_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/externalMappings/${payload.value.idExternalMapping}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });
    if (response.message) {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed update",
          message: response.message,
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful update",
          message: "Successful update External mapping",
        },
      });
    }
    yield put({
      type: "UPDATE_EXTERNALMAPPING_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update external mapping",
      },
    });
    yield put({
      type: "UPDATE_EXTERNALMAPPING_ERROR",
    });
  }
}

export function* watchExternalMapping() {
  yield takeLatest("FETCH_EXTERNALMAPPINGS_REQUEST", getAllExternalMappings);
  yield takeLatest("CREATE_EXTERNALMAPPING_REQUEST", postExternalMapping);
  yield takeLatest("READ_EXTERNALMAPPING_REQUEST", getExternalMappingById);
  yield takeLatest("DELETE_EXTERNALMAPPING_REQUEST", deleteExternalMappingById);
  yield takeLatest("UPDATE_EXTERNALMAPPING_REQUEST", updateExternalMapping);
}
