import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../api/request";
import { isNullOrUndefined } from "../../utils/functionsUtils";

function* getAllCompanies(payload) {
  try {
    const includeAllValue = isNullOrUndefined(payload.value?.includeAll) ? '' : payload.value.includeAll;

    yield put({
      type: "FETCH_COMPANIES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${
      process.env.REACT_APP_API_URL
      }/api/v1/companies?page=${payload.value.page}&search=${
      payload.value.search
      }&includeAll=${includeAllValue
      }&offset=${payload.value.offset}${
      payload.value.isActive ? "&isActive=" + payload.value.isActive : ""
      }`;
    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_COMPANY_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Companies",
      },
    });
    yield put({
      type: "FETCH_COMPANY_ERROR",
    });
  }
}

function* postCompany(payload) {
  try {
    yield put({ type: "CREATE_COMPANY_REQUESTING" });

    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/companies`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "CREATE_COMPANY_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save Company",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    const responseBody = yield error.response.json();
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: `Failed save Company: ${responseBody.message}`,
      },
    });
    yield put({
      type: "CREATE_COMPANY_ERROR",
    });
  }
}

function* getCompanyById(payload) {
  try {
    yield put({
      type: "READ_COMPANY_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/companies/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_COMPANY_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READ_COMPANY_ERROR",
    });
  }
}

function* deleteCompanyById(payload) {
  try {
    yield put({
      type: "DELETE_COMPANY_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/companies/changeState/${payload.value.idCompany}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DELETE_COMPANY_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable Company",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable Company",
      },
    });
    yield put({
      type: "DELETE_COMPANY_ERROR",
    });
  }
}

function* updateCompany(payload) {
  try {
    yield put({
      type: "UPDATE_COMPANY_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/companies/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPDATE_COMPANY_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update Company",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    const responseBody = yield error.response.json();
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: `Failed update Company: ${responseBody.message}`,
      },
    });
    yield put({
      type: "UPDATE_COMPANY_ERROR",
    });
  }
}

function* deleteOperatorBranch(payload) {
  try {
    yield put({
      type: "DELETE_OPERATOR_BRANCH_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/companies/deleteOperatorBranch`;
    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DELETE_OPERATOR_BRANCH_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful",
        message: "Successful branch Deleted",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    const responseBody = yield error.response.json();
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: `Failed Delete branch: ${responseBody.message}`,
      },
    });
    yield put({
      type: "DELETE_OPERATOR_BRANCH_ERROR",
    });
  } finally {
    yield put({
      type: "DELETE_OPERATOR_BRANCH_END",
    });
  }
}

function* getCompanyByUser(payload) {
  try {
    yield put({
      type: "READBYUSER_COMPANY_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/companies/getByUser`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READBYUSER_COMPANY_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READBYUSER_COMPANY_ERROR",
    });
  }
}

function* getCompanies(payload) {
  try {
    yield put({
      type: "FETCH_ALLCOMPANIES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/companies?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}`;
    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_ALLCOMPANY_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Companies",
      },
    });
    yield put({
      type: "FETCH_ALLCOMPANY_ERROR",
    });
  }
}

function* getCompaniesByShipper(payload) {
  try {
    yield put({
      type: "FETCH_COMPANIESBYTYPE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/companies/companiesByShipper?includeAll=${payload.value?.includeAll || false}`;
    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_COMPANYBYSHIPPER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Companies by shipper",
      },
    });
    yield put({
      type: "FETCH_COMPANYBYTYPE_ERROR",
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getCompaniesByDSP(payload) {
  try {
    yield put({
      type: "FETCH_COMPANYBYTYPE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/companies/companiesDSP?includeAll=${payload.value?.includeAll || false}`;
    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_COMPANIESBYDSP_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load DSP Operators",
      },
    });
    yield put({
      type: "FETCH_COMPANYBYTYPE_ERROR",
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getCompaniesByWarehouse(payload) {
  try {
    yield put({
      type: "FETCH_COMPANYBYWAREHOUSE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/companies/findByWarehouse?warehouseId=${payload.value}`;
    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_COMPANIESBYWAREHOUSE_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load companies",
      },
    });
    yield put({
      type: "FETCH_COMPANYBYWAREHOUSE_ERROR",
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getCompaniesByLogisticsOperator(payload) {
  try {
    yield put({
      type: "FETCH_COMPANIESBYTYPE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/companies/companiesByLogisticsOperator?includeAll=${payload.value?.includeAll || false}`;
    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_COMPANYBYLOGISTICSOPERATOR_SUCCESS",
      value: response,
    });

    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Companies by operator",
      },
    });
    yield put({
      type: "FETCH_COMPANYBYTYPE_ERROR",
    });
  }
}

function* getCompaniesByInventoryOperator(payload) {
  try {
    yield put({
      type: "FETCH_COMPANIESBYTYPE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/companies/companiesByInventoryOperator`;
    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_COMPANYBYINVENTORYOPERATOR_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Companies by operator",
      },
    });
    yield put({
      type: "FETCH_COMPANYBYTYPE_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

export function* watchCompany() {
  yield takeLatest("FETCH_COMPANIES_REQUEST", getAllCompanies);
  yield takeLatest("CREATE_COMPANY_REQUEST", postCompany);
  yield takeLatest("READ_COMPANY_REQUEST", getCompanyById);
  yield takeLatest("DELETE_COMPANY_REQUEST", deleteCompanyById);
  yield takeLatest("UPDATE_COMPANY_REQUEST", updateCompany);
  yield takeLatest("DELETE_OPERATOR_BRANCH_REQUEST", deleteOperatorBranch);
  yield takeLatest("READBYUSER_COMPANY_REQUEST", getCompanyByUser);
  yield takeLatest("FETCH_ALLCOMPANIES_REQUEST", getCompanies);
  yield takeLatest("FETCH_COMPANIESBYSHIPPER_REQUEST", getCompaniesByShipper);
  yield takeLatest("FETCH_COMPANIESBYDSP_REQUEST", getCompaniesByDSP);
  yield takeLatest("FETCH_COMPANIES_BYWAREHOUSE_REQUEST", getCompaniesByWarehouse);
  yield takeLatest("FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST", getCompaniesByLogisticsOperator);
  yield takeLatest("FETCH_COMPANIESBYINVENTORYOPERATOR_REQUEST", getCompaniesByInventoryOperator);
}
