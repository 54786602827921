import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../api/request";
import {
    addressCloseConfirmationActions,
    addressCloseConfirmationReportActions
} from "../reducers/AddressCloseConfirmationReducer";
import {AddressStatusLabel} from "../../utils/enumsUtil";
import Swal from "sweetalert2";

function* getAllAddresses(payload) {
    try {
        yield put({
            type: addressCloseConfirmationActions.FECTCHIN_ALL_CLOSECONFIRMATION,
        });

        yield put({
            type: "SHOW_LOADING",
        });
        let query = `page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}&courier=${payload.value.courier}&startDate=${payload.value.startDate}&endDate=${payload.value.endDate}&city=${payload.value.city}`;
        if (payload.value.hasOwnProperty('state')){
            query=query+`&state=${payload.value.state}`
        }
        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressCloseConfirmation?${query}`;
        const headers = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
        };
        let response = yield call(request, requestURL, headers);

        response = {
            ...response,
            items : response.items.map(res => {
                return {
                    address: res.address,
                    collection: res.collection,
                    trackingId: res.trackingId,
                    idAddress: res.idAddress,
                    assignedDate: res.assignedDate,
                    state: res.state,
                    isChecked: false
                }
            })
        }
        yield put({
            type: addressCloseConfirmationActions.FETCH_ADDRESSES_CLOSECONFIRMATION_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed Load Data",
                message: "Failed Load Data",
            },
        });
        console.log(error);

    }
}

function* post(payload) {
    try {
        yield put({
            type: addressCloseConfirmationActions.FETCHING_POST_CLOSECONFIRMATION,
        });
        yield put({
            type: "SHOW_LOADING",
        });
        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressCloseConfirmation`;
        const headers = {
            method: "POST",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(payload.value)
        };
        const response = yield call(request, requestURL, headers);

        yield put({
            type: addressCloseConfirmationActions.POST_CLOSECONFIRMATION_SUCCESS,
            value: response.finished,
        });

        if ((response.finished && response.finished.length > 0) || (response.noFinished && response.noFinished.length > 0)) {

            Swal.fire({
                title: '<strong>Resultados</u></strong>',
                icon: 'info',
                html: `Direcciones cerradas: ${response.finished.length} <br/>
                        Direcciones no cerradas: ${response.noFinished.length} <br/>
                                <div style="display:flex; height: 100px; overflow-x: auto">
                                    <table className="table table-sm table-responsive-sm">
                                        <thead>
                                        <th>#</th>
                                        <th>Mensaje</th>
                                        </thead>
                                        <tbody>
                                        ${Boolean(response.noFinished.length) ? response.noFinished.map((el) =>
                                            `<tr><td><small>${el.idAddress}</td></small><td><small>${el.message && '-' + el.message}</small></td></tr>`
                                        ): ''}
                                        </tbody>
                                    </table>
                                </div>
                        `,
                showCloseButton: true
            })
        }


        yield put({
            type: "HIDE_LOADING",
        })
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "success",
                title: "Successful save",
                message: "",
            },
        });
    }catch (e) {
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed Save",
                message: "Failed Save Data",
            },
        });
        yield put({
            type: "HIDE_LOADING",
        })
    }
}

function* getReport(payload) {
    try {
        yield put({
            type: addressCloseConfirmationReportActions.FECTCHIN_CLOSECONFIRMATION_REPORT,
        });

        yield put({
            type: "SHOW_LOADING",
        });
        const query = `page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}&type=${payload.value.type}&operator=${payload.value.operator}&courier=${payload.value.courier}&startDate=${payload.value.startDate}&endDate=${payload.value.endDate}&city=${payload.value.city}`;

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressCloseConfirmation/report?${query}`;
        const headers = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
        };
        let response = yield call(request, requestURL, headers);
        if ( response.items.length > 0 ) {
            response = {
                ...response,
                items : response.items.map(res => {
                    return {
                        address: res.address.address,
                        collection: res.address.collection,
                        trackingId: res.address.trackingId,
                        idAddress: res.idAddress,
                        assignedDate: res.assignedDate,
                        state: res.address.newState?.name,
                        stateCode: res.address.newState?.code,
                        operator: res.address.operator.description,
                        courier: `${res.courier.firstName} ${res.courier.lastName}`,
                        finished: res.address.finished != null ? res.address.finished.type: '',
                        client:  res.address.company.description,
                        city: !!res.address.city ? res.address.city.description : ''
                    }
                })
            }
        }

        yield put({
            type: addressCloseConfirmationReportActions.FETCH_ADDRESSES_CLOSECONFIRMATION_REPORT_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed Load Data",
                message: "Failed Load Data",
            },
        });
        console.log(error);

    }
}

export function* watchAddresCloseConfirmation() {
    yield takeLatest(addressCloseConfirmationActions.FECTCH_ALL_CLOSECONFIRMATION, getAllAddresses);
    yield takeLatest(addressCloseConfirmationActions.POST_CLOSECONFIRMATION, post);
    yield takeLatest(addressCloseConfirmationReportActions.FECTCH_CLOSECONFIRMATION_REPORT, getReport);
}
