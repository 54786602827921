import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../api/request";

function* getUnconfirmed(payload) {
    try {
      yield put({
        type: "FETCH_UNCONFIRMED_REQUESTING",
      });
  
      yield put({
        type: "SHOW_LOADING",
      });
  
      const headers = {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        }),
      };
  
      let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/financial?page=${
        payload.value.page
      }&offset=${payload.value.offset}${
        payload.value.isActive ? "&isActive=" + payload.value.isActive : ""
      }&search=${payload.value.search}`;
      if (payload.value.from && payload.value.from != "") {
        requestURL = requestURL + `&from=${payload.value.from}`;
      }
      if (payload.value.to && payload.value.to != "") {
        requestURL = requestURL + `&to=${payload.value.to}`;
      }
      if (payload.value.senderIds) {
        requestURL = requestURL + `&senderIds=${payload.value.senderIds}`;
      }
      if (payload.value.operatorIds) {
        requestURL = requestURL + `&operatorIds=${payload.value.operatorIds}`;
      }
      const response = yield call(request, requestURL, headers);
  
      yield put({
        type: "HIDE_LOADING",
      });
  
      yield put({
        type: "FETCH_UNCONFIRMED_SUCCESS",
        value: response,
      });
    } catch (error) {
      yield put({
        type: "HIDE_LOADING",
      });
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed load",
          message: "Failed load Unconfirmed Addresses",
        },
      });
      yield put({
        type: "FETCH_UNCONFIRMED_ERROR",
      });
    }
}

function* uploadUnconfirmedFile(payload) {
  try {
    yield put({ type: "UPLOAD_UNCONFIRMED_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/financial/import`;

    const formData = new FormData();
    formData.append("file", payload.value.file);

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      }),
      body: formData,
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPLOAD_UNCONFIRMED_SUCCESS",
      value: response,
    });

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful upload",
        message: "Successful upload file",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed upload",
        message: "Failed upload file",
      },
    });
    yield put({
      type: "UPLOAD_UNCONFIRMED_ERROR",
    });
  }
}

function* confirmCollection(payload) {
  try {
    yield put({ type: "CONFIRM_COLLECTION_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/financial/confirmCollection`;
    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "CONFIRM_COLLECTION_SUCCESS",
      value: response,
    });

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful address confirm",
        message: "Successful address confirm",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed address confirm",
        message: "Failed address confirm",
      },
    });
    yield put({
      type: "CONFIRM_COLLECTION_ERROR",
    });
  }
}

export function* watchUnconfirmed() {
    yield takeLatest("FETCH_UNCONFIRMED_REQUEST", getUnconfirmed);
    yield takeLatest("UPLOAD_UNCONFIRMED_REQUEST", uploadUnconfirmedFile);
    yield takeLatest("CONFIRM_COLLECTION_REQUEST", confirmCollection);
}