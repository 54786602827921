import React from 'react';
import {Redirect, Route} from 'react-router-dom';

const PublicRoute = ({
  component: Component,
  restricted,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={(props) =>
      (
        <Component {...props} />
      )
      }
    />
  );
}

export default PublicRoute;
